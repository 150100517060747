import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PriceList } from 'app/Model/Catalog/PriceList';
import { Product } from 'app/Model/Catalog/Product';
import { ProductPriceDetail } from 'app/Model/Catalog/ProductPriceDetail';
import { CatalogService } from 'app/Services/CatalogManagement/catalog.service';
import { UserManagementService } from 'app/Services/userManagment.service';
import { ValidationService } from 'app/Services/Validation/validation.service';
import { ViewService } from 'app/Services/view.service';
import { calculateBasicPrice, calculateGrossPrice, calculateNetPrice, getDisplayPrice } from 'app/ui/utils/prices';
import { KeyPressEvent, PasteEvent } from 'devextreme/ui/autocomplete';
import { ItemClickEvent } from 'devextreme/ui/list';

@Component({
  selector: 'np-pricedetail',
  templateUrl: './pricedetail.component.html',
  styleUrl: './pricedetail.component.css'
})
export class PricedetailComponent {
  @Input() templateView = false;
  @Input() showLinkButtons = false;
  @Input() selectedElements: string[] = [];
  @Input() showDragHandle = false;
  @Input() dragFilter = 'noDrag';
  @Input() model: Product;
  @Input() priceList: PriceList;
  @Input() detail: ProductPriceDetail;

  @Output() onElementSelected = new EventEmitter<{ identifier: string; system: string; element: string }>();
  @Output() onExcelElementDropped = new EventEmitter<any>();
  @Output() onUpdate = new EventEmitter<any>();

  priceTypes = ViewService.dropDownPriceTypes;
  currencies = ViewService.dropDownCurrencies;
  taxs = ViewService.dropDownTaxs;
  priceFactors = ViewService.dropDownPriceFactors;
  lowerBounds = ViewService.dropDownLowerBounds;
  territories = ViewService.dropDownTerritories;
  units = ViewService.dropDownUnits;
  zero = ViewService.dropDownZero;
  one = ViewService.dropDownOne;

  get isNotTaxed() {
    return (
      this.detail.taxRate === 0 ||
      (!this.detail.priceType?.startsWith('gross_') && !this.detail.priceType?.startsWith('net_'))
    );
  }

  get netPrice() {
    const price = calculateNetPrice(this.detail.priceType, this.detail.priceAmountNumber, this.detail.taxRate);
    return getDisplayPrice(
      price * (this.detail.priceFactorNumber || 1),
      this.detail.priceCurrency,
      this.translate.currentLang
    );
  }

  get grossPrice() {
    const price = calculateGrossPrice(
      this.detail.priceType,
      this.detail.priceAmountNumber * (this.detail.priceFactorNumber || 1),
      this.detail.taxRate
    );
    return getDisplayPrice(price, this.detail.priceCurrency, this.translate.currentLang);
  }

  get price() {
    return getDisplayPrice(
      (this.detail.priceAmountNumber || 0) * (this.detail.priceFactorNumber || 1),
      this.detail.priceCurrency,
      this.translate.currentLang
    );
  }

  get basicNetPrice() {
    const netPrice = calculateNetPrice(this.detail.priceType, this.detail.priceAmountNumber, this.detail.taxRate);
    const price =
      calculateBasicPrice(
        netPrice,
        this.model.orderDetail.priceQuantityNumber,
        this.model.orderDetail.basicQuantityNumber,
        this.model.orderDetail.contentQuantityNumber
      ) * (this.detail.priceFactorNumber || 1);
    if (price && !isNaN(price)) return getDisplayPrice(price, this.detail.priceCurrency, this.translate.currentLang);
    return this.translate.instant('n.v.');
  }

  get discount() {
    const priceFactor =
      typeof this.detail.priceFactorNumber === 'string'
        ? parseFloat(this.detail.priceFactorNumber)
        : this.detail.priceFactorNumber;
    if (priceFactor && !isNaN(priceFactor))
      return (1 - priceFactor).toLocaleString(this.translate.currentLang, { style: 'percent' });
    return this.translate.instant('n.v.');
  }

  get tax() {
    const taxRate = typeof this.detail.taxRate === 'string' ? parseFloat(this.detail.taxRate) : this.detail.taxRate;
    if (taxRate && !isNaN(taxRate)) return taxRate.toLocaleString(this.translate.currentLang, { style: 'percent' });
    return this.translate.instant('n.v.');
  }

  constructor(
    public validationService: ValidationService,
    public translate: TranslateService,
    private catalogService: CatalogService,
    private userManagement: UserManagementService
  ) {}

  onItemClick(event: ItemClickEvent<{ value: string; displayValue: string }>, propertyName: string) {
    this.detail[propertyName] = event.itemData.value;
  }

  formatPriceFactor(value: string) {
    const number = Number(value);
    let result: string;
    if (Number.isNaN(number)) result = value;
    else
      result = this.translate.instant('Preisfaktor (Rabatt)', {
        priceFactor: value,
        discount: (1 - number).toLocaleString(this.translate.currentLang, { style: 'percent' })
      });
    return result;
  }

  templateSelect(identifier: string) {
    this.onElementSelected.emit({
      identifier,
      system: this.priceList.priceListOrder.toString(),
      element: this.detail.order.toString()
    });
  }

  excelElementDrop(event) {
    this.onExcelElementDropped.emit(event);
  }

  update(event = null, data?, productPriceDetails?: ProductPriceDetail[], field?: string) {
    const system = this.priceList.priceListOrder.toString();
    const element = this.detail.order.toString();
    productPriceDetails = productPriceDetails || this.priceList.productPriceDetails;
    this.onUpdate.emit({ event, data, productPriceDetails, field, system, element });
  }

  numericAutocompletePaste(e: PasteEvent) {
    if (isNaN(e.event.clipboardData.getData('text') as unknown as number)) {
      e.event.preventDefault();
    }
  }

  numericAutocompleteKeyPress(e: KeyPressEvent) {
    if (isNaN(+e.event.key) || e.event.key === ' ') {
      e.event.preventDefault();
    }
  }

  readOnlyForLinkedFields(field: string): boolean {
    if (field) {
      const system = this.priceList.priceListOrder.toString();
      const element = this.detail.order.toString();
      const isLinked = this.model.isFieldLinked(field, system, element);
      return isLinked && this.model.isChild != null && this.model.isChild;
    }
    return false;
  }

  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }
}
