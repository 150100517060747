
<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Sortimente auf Kategorieebene' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="model.osgSegmentMapping.categoriesAreSegments" [readOnly]="canChangeKeepRootCategory()" (onValueChanged)="catalogChanged($event)"></dx-switch>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Keine Kategorie mit Sortimentsnamen anlegen' | translate }}</div>
    <div class="dx-field-value">
      <dx-switch [(value)]="model.osgSegmentMapping.keepRootCategory" [readOnly]="canChangeKeepRootCategory()" (onValueChanged)="catalogChanged($event)"></dx-switch>
    </div>
  </div>
</div>

<dx-tab-panel [dataSource]="pages" [selectedIndex]="0">

  <div *dxTemplate="let company of 'liveTemplate'">
    <dx-toolbar>
      <dxi-item *ngIf="!readOnlyUser('osgCatalogSend')" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog senden' | translate, onClick: sendData2ShopZero }"></dxi-item>
      <dxi-item *ngIf="!readOnlyUser('osgCatalogReset')" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog zurücksetzen' | translate, onClick: resetCatalog }"></dxi-item>
    </dx-toolbar>

    <div class="dx-fieldset" *ngIf="model">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Gefahrstoffklassen an Beschreibung anhängen' | translate }}</div>
        <div class="dx-field-value">
          <dx-switch [(value)]="model.osgSegmentMapping.includeHazmatClassesInDescription" [readOnly]="readOnlyUser('osgCatalogGefahrstoffklasse')" (onValueChanged)="catalogChanged($event)"></dx-switch>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label" (mouseenter)="ttt('priceType')" (mouseleave)="ttt('priceType')">{{ 'Preistyp' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttPriceType'">
          <label class="placeLabel {{ model.osgSegmentMapping.priceType?.length>0 ? 'placeLabelSmall' : '' }}">PRICE_TYPE</label>
          <dx-autocomplete [minSearchLength]="0"
                           [dataSource]="priceTypes"
                           [showClearButton]="true"
                           [showDropDownButton]="readOnlyUser('osgPriceType')"
                           [(value)]="model.osgSegmentMapping.priceType"
                           valueChangeEvent="blur"
                           (onValueChanged)="catalogChanged($event)"
                           (onSelectionChanged)="catalogChanged($event)">
          </dx-autocomplete>

        </div>
        <dx-tooltip target="#ttPriceType" class="toolTip toolTipField" [(visible)]="v['productRange']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Der Pricetype, der für die Übertragung zum Shop ausgewählt werden soll' | translate }}.
          </div>
        </dx-tooltip>
      </div>

      <div class="dx-field" *ngIf="!model.osgSegmentMapping.categoriesAreSegments">
        <div class="dx-field-label" (mouseenter)="ttt('productRange')" (mouseleave)="ttt('productRange')">{{ 'Sortiment' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttProductRange">
          <label class="placeLabel {{ productRange.length>0 ? 'placeLabelSmall' : '' }}">PRODUCT_RANGE</label>
          <dx-text-box [(value)]="productRange" [disabled]="true" [showClearButton]="false" [readOnly]="readOnlyUser('osgCatalogProductRange')">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttProductRange" class="toolTip toolTipField" [(visible)]="v['productRange']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Das Sortiment des OSG Shops' | translate }}.
          </div>
        </dx-tooltip>
      </div>








      <div class="dx-field">
        <div class="dx-field-label" (mouseenter)="ttt('lastSend')" (mouseleave)="ttt('lastSend')">{{ 'Letzte Übertragung' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttlastSend">
          <label class="placeLabel {{ lastSend.length>0 ? 'placeLabelSmall' : '' }}">LAST_SEND</label>
          <dx-text-box [(value)]="lastSend" [disabled]="true" [showClearButton]="false" [readOnly]="readOnlyUser('osgCatalogLastSend')">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttlastSend" class="toolTip toolTipField" [(visible)]="v['productRange']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Datum der letzten Übertragung zu oder vom Shop' | translate }}.
          </div>
        </dx-tooltip>
      </div>


      <dx-popup title="{{ 'Katalog senden' | translate }}?"
                [(visible)]="isSendCatalogPopupVisible"
                [width]="350"
                [height]="250"
                [showTitle]="true">
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Daten senden' | translate, onClick: sendData2ShopOne }"></dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: sendData2ShopTwo }"></dxi-toolbar-item>
        <div *dxTemplate="let data of 'content'">
          <p>{{ 'Soll der Katalog übertragen werden' | translate }}?</p>
        </div>
      </dx-popup>
      <dx-popup title="{{ 'Katalog zurücksetzen' | translate }}?"
                [(visible)]="isResetCatalogVisible"
                [width]="550"
                [height]="250"
                [showTitle]="true">
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Katalog zurücksetzen' | translate, onClick: startResetCatalog }"></dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: closePopups }"></dxi-toolbar-item>
        <div *dxTemplate="let data of 'content'">
          <p>{{ 'Nach dem Zurücksetzen ist für diesen Katalog die Verbindung zum Multishop aufgehoben. Sie sollten die Funktion nur ausführen, wenn Sie im Multishop das Sortiment vollständig gelöscht haben' | translate }}!</p>
        </div>
      </dx-popup>
    </div>
  </div>


  <div *dxTemplate="let company of 'testTemplate'">
    <dx-toolbar>
      <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog senden' | translate, onClick: sendData2ShopTestZero }"></dxi-item>
      <dxi-item location="before" widget="dxButton" [options]="{ type: 'default', text: 'Katalog zurücksetzen' | translate, onClick: resetCatalogTest }"></dxi-item>
    </dx-toolbar>
    <div class="dx-fieldset" *ngIf="model">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Gefahrstoffklassen an Beschreibung anhängen' | translate }}</div>
        <div class="dx-field-value">
          <dx-switch [(value)]="model.osgSegmentMapping.includeHazmatClassesInDescriptionTest" (onValueChanged)="catalogChanged($event)"></dx-switch>
        </div>
      </div>



      <div class="dx-field" *ngIf="model">
        <div class="dx-field-label" (mouseenter)="ttt('priceTypeTest')" (mouseleave)="ttt('priceTypeTest')">{{ 'Preistyp' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttPriceTypeTest'">
          <label class="placeLabel {{ model.osgSegmentMapping.priceTypeTest?.length>0 ? 'placeLabelSmall' : '' }}">PRICE_TYPE</label>
          <dx-autocomplete [minSearchLength]="0"
                           [dataSource]="priceTypes"
                           [showClearButton]="true"
                           [showDropDownButton]="readOnlyUser('osgPriceType')"
                           [(value)]="model.osgSegmentMapping.priceTypeTest"
                           valueChangeEvent="blur"
                           (onValueChanged)="catalogChanged($event)"
                           (onSelectionChanged)="catalogChanged($event)">
          </dx-autocomplete>

        </div>
        <dx-tooltip target="#ttPriceTypeTest" class="toolTip toolTipField" [(visible)]="v['priceTypeTest']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Der Preistyp, der für die Übertragung zum Shop ausgewählt werden soll' | translate }}.
          </div>
        </dx-tooltip>
      </div>




      <div class="dx-field">
        <div class="dx-field-label" (mouseenter)="ttt('productRangeTest')" (mouseleave)="ttt('productRangeTest')">{{ 'Sortiment' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttProductRangeTest">
          <label class="placeLabel {{ productRangeTest.length>0 ? 'placeLabelSmall' : '' }}">PRODUCT_RANGE</label>
          <dx-text-box [(value)]="productRangeTest" [disabled]="true" [showClearButton]="false">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttProductRangeTest" class="toolTip toolTipField" [(visible)]="v['productRangeTest']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Das Sortiment des OSG Shops' | translate }}.
          </div>
        </dx-tooltip>
      </div>
      <div class="dx-field">
        <div class="dx-field-label" (mouseenter)="ttt('lastSendTest')" (mouseleave)="ttt('lastSendTest')">{{ 'Letzte Übertragung' | translate }}</div>
        <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="ttlastSendTest">
          <label class="placeLabel {{ lastSendTest.length>0 ? 'placeLabelSmall' : '' }}">LAST_SEND</label>
          <dx-text-box [(value)]="lastSendTest" [disabled]="true" [showClearButton]="false">
          </dx-text-box>

        </div>
        <dx-tooltip target="#ttlastSendTest" class="toolTip toolTipField" [(visible)]="v['ttlastSendTest']">
          <div *dxTemplate="let data = data of 'content'">
            {{ 'Datum der letzten Übertragung zu oder vom Shop' | translate }}.
          </div>
        </dx-tooltip>
      </div>


      <dx-popup title="{{ 'Katalog senden - TEST -' | translate }}?"
                [(visible)]="isSendCatalogTestPopupVisible"
                [width]="350"
                [height]="250"
                [showTitle]="true">
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Daten senden' | translate, onClick: sendData2ShopTestOne }"></dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: sendData2ShopTestTwo }"></dxi-toolbar-item>
        <div *dxTemplate="let data of 'content'">
          <p>{{ 'Soll der Katalog zum Testshop übertragen werden' | translate }}?</p>
        </div>
      </dx-popup>

      <dx-popup title="{{ 'Katalog zurücksetzen - TEST -' | translate }}?"
                [(visible)]="isResetCatalogTestVisible"
                [width]="550"
                [height]="250"
                [showTitle]="true">
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Katalog zurücksetzen senden' | translate, onClick: startResetCatalogTest }"></dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Abbrechen' | translate, onClick: closePopups }"></dxi-toolbar-item>
        <div *dxTemplate="let data of 'content'">
          <p>{{ 'Nach dem Zurücksetzen ist für diesen Katalog die Verbindung zum Multishop aufgehoben. Sie sollten die Funktion nur ausführen, wenn Sie im Multishop das Sortiment vollständig gelöscht haben' | translate }}!</p>
        </div>
      </dx-popup>




    </div>


  </div>
</dx-tab-panel>
