import {
  Component,
  Input,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  SimpleChanges,
  OnChanges,
  ContentChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'np-expandable-textview',
  templateUrl: './expandable-textview.component.html',
  styleUrls: ['./expandable-textview.component.css']
})
export class ExpandableTextviewComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() maxHeight: number = 100;
  @Input() textShowMore: string;
  @Input() textShowLess: string;

  @ViewChild('content', { static: false }) content!: ElementRef;
  @ContentChild('projectedContent', { static: false }) projectedContent!: ElementRef;

  isExpanded: boolean = false;
  isOverflowing: boolean = false;

  private mutationObserver: MutationObserver | null = null;

  constructor(private renderer: Renderer2, private translate: TranslateService) {}

  ngAfterViewInit(): void {
    this.textShowMore = this.textShowMore || this.translate.instant('Mehr');
    this.textShowLess = this.textShowLess || this.translate.instant('Weniger anzeigen');
    this.checkOverflow();
    this.setupMutationObserver();
  }

  ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['maxHeight'] && !changes['maxHeight'].firstChange) {
      this.checkOverflow();
    }
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded;
    this.updateMaxHeight();
  }

  private checkOverflow(): void {
    const container = this.content?.nativeElement || this.projectedContent?.nativeElement;
    if (container) {
      const currentHeight = container.scrollHeight;
      this.isOverflowing = currentHeight > this.maxHeight;

      if (!this.isExpanded && this.isOverflowing) {
        this.renderer.setStyle(container, 'max-height', `${this.maxHeight}px`);
        this.renderer.setStyle(container, 'overflow', 'hidden');
      } else {
        this.renderer.setStyle(container, 'max-height', 'none');
        this.renderer.setStyle(container, 'overflow', 'visible');
      }
    }
  }

  private updateMaxHeight(): void {
    const container = this.content?.nativeElement || this.projectedContent?.nativeElement;
    if (container) {
      if (this.isExpanded) {
        this.renderer.setStyle(container, 'max-height', 'none');
        this.renderer.setStyle(container, 'overflow', 'visible');
      } else {
        this.renderer.setStyle(container, 'max-height', `${this.maxHeight}px`);
        this.renderer.setStyle(container, 'overflow', 'hidden');
      }
    }
  }

  private setupMutationObserver(): void {
    const container = this.content?.nativeElement || this.projectedContent?.nativeElement;
    if (container) {
      this.mutationObserver = new MutationObserver(() => {
        this.checkOverflow();
      });

      this.mutationObserver.observe(container, {
        childList: true,
        subtree: true,
        characterData: true
      });
    }
  }
}
