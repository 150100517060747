import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Item as dxListItem, ItemClickEvent } from 'devextreme/ui/list';
import { ValueChangedEvent as NumberBoxValueChangeEvent } from 'devextreme/ui/number_box';

import { NextPimField } from 'app/Model/Catalog/NextPimField';
import { TemplateService } from 'app/Services/template.service';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { comparePimFields, fillLastKey, isNextPimField, parsePimField } from 'app/Services/templateItemHelpers';

@Component({
  selector: 'np-advanced-template-listfield',
  templateUrl: './advanced-template-listfield.component.html',
})
export class AdvancedTemplateListFieldComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() templateItem: TemplateItem;
  @Input() customerId: string;
  @Input() fieldList: dxListItem[] = [];
  @Output() onUpdate = new EventEmitter<void>();

  get pimField() {
    if (!this.templateItem.pimFields || this.templateItem.pimFields.length === 0) {
      this.templateItem.pimFields = [new NextPimField()];
    }
    return this.templateItem.pimFields[0];
  }

  constructor(private templateService: TemplateService) {}

  ngOnInit() {
    this.normalizeSystemKey(parseInt(this.pimField.systemKey));
    if (this.templateItem.fieldType == 'udx') {
      this.pimField.useSequence = true;
    }
    
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFieldDisplayExpr(item: dxListItem) {
    return item?.text === item?.key ? item?.key ?? '' : `${item.text} (${item.key})`;
  }

  onFieldItemClick(event: ItemClickEvent<dxListItem>) {
    this.pimField.field = event.itemData.key;
    this.normalizeSystemKey(parseInt(this.pimField.systemKey));
    this.update();
  }

  onOrderChanged(event: NumberBoxValueChangeEvent) {
    this.normalizeSystemKey(event.value);
    this.update();
  }

  update() {
    const { field, systemKey, elementKey } = this.pimField;
    const parsedKey = parsePimField(this.templateItem.key);
    if (parsedKey !== null && (comparePimFields(parsedKey, this.pimField) || !isNextPimField(parsedKey.field))) {
      this.onUpdate.emit();
      return;
    }
    this.templateService.getHeadline(field, elementKey, systemKey, this.customerId).subscribe((headline) => {
      fillLastKey(this.templateItem, headline.value);
      this.onUpdate.emit();
    });
  }

  private normalizeSystemKey(value: number) {
    if (isNaN(value) || value <= 0) {
      this.pimField.systemKey = '1';
    } else {
      this.pimField.systemKey = value.toString();
    }
    this.pimField.elementKey = '-1';
  }
}
