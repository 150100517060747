import { Component, OnInit } from '@angular/core';
import { Customer } from '../../Model/User/Customer';
import { OAuthService } from 'angular-oauth2-oidc';
import { ValidationMethod } from '../../Model/validation/ValidationMethod';
import { ValidationService } from '../../Services/Validation/validation.service';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { Router } from '@angular/router';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { LoginService } from '../../Services/login.service';
import { Catalog } from '../../Model/Catalog/Catalog';
import { SystemService } from '../../Services/system.service';
import { TranslateService } from '@ngx-translate/core';
import { DscContactService } from '../../Services/dscContactService.service';
import { DscContact } from '../../Model/User/DscContact';
import { ValidationGroup } from 'app/Model/validation/ValidationGroup';

@Component({
  selector: 'dscInfos',
  templateUrl: 'dscInfos.component.html'
})
export class DscInfosComponent implements OnInit {
  value: any[];
  uploadHeaders: any;
  msg: string = '';
  shareableValidationMethods: ValidationMethod[] = [];
  shareableValidationGroups: ValidationGroup[] = [];
  shareableCatalogs: Catalog[] = [];
  generatedRegistrationLink: string;
  contacts: DscContact[] = new Array<DscContact>();
  selectedIndex: number = 0;

  generateRegistrationLinkButtonOptions: DxButtonTypes.Properties = {
    icon: 'link',
    disabled: false,
    onClick: async () => {
      this.generateRegistrationLink();
      navigator.clipboard
        .writeText(this.generatedRegistrationLink)
        .then(() =>
          this.systemService.notifyInfo(this.translate.instant('Der Link wurde in die Zwischenablage kopiert'))
        );
    }
  };

  constructor(
    public loginService: LoginService,
    private oAuthService: OAuthService,
    private validationService: ValidationService,
    private router: Router,
    private catalogService: CatalogService,
    private systemService: SystemService,
    private translate: TranslateService,
    private dscContactSerice: DscContactService
  ) {
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);
    this.reset();
    this.addContact = this.addContact.bind(this);
  }

  async ngOnInit() {
    if (this.loginService.currentUser == null) {
      this.router.navigate(['/']);
      return;
    }

    this.getShareableValidationMethods();
    this.getShareableCatalogs();
    await this.getDscContacts();
  }

  reset() {
    this.uploadHeaders = {
      CustomerId: this.model.id
    };
    this.value = [];
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }, contactId: number) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
    e.request.setRequestHeader('DscContactId', contactId.toString());
  }

  async onUploaded(e) {
    this.reset();
    await this.getDscContacts();
  }

  getShareableValidationMethods() {
    this.validationService.getSharedValidationMethodsCreatedByCustomer().then((methods) => {
      this.shareableValidationMethods = methods;
      this.updateShareableValidationGroups();
    });
  }

  getShareableCatalogs() {
    this.catalogService.getCatalogs(this.loginService.currentCustomer.id).subscribe((x) => {
      var catalogWithCatalogId = x.filter((y) => y.catalogId);
      this.shareableCatalogs = catalogWithCatalogId.filter(
        (catalog, index, self) => index === self.findIndex((t) => t.catalogId === catalog.catalogId)
      );
    });
  }

  async getDscContacts() {
    this.contacts = await this.dscContactSerice.getAll(this.loginService.currentCustomer.id);
    if (this.contacts.length == 0) {
      let contact = new DscContact();
      contact.customerId = this.loginService.currentCustomer.id;
      contact = await this.dscContactSerice.create(contact);
      this.contacts.push(contact);
    }
  }
  async addContact() {
    let contact = new DscContact();
    contact.customerId = this.loginService.currentCustomer.id;
    contact = await this.dscContactSerice.create(contact);
    this.contacts.push(contact);
  }

  isManager(): boolean {
    if (this.loginService.currentUser.currentClaim == 'USER') return false;
    return true;
  }

  get model(): Customer {
    return this.loginService.currentCustomer;
  }

  set model(value: Customer) {
    this.loginService.currentCustomer = value;
  }

  private updateShareableValidationGroups() {
    if (
      this.shareableValidationMethods.length > 0 &&
      this.shareableValidationMethods.some((x) => x.id === this.model.sharedValidationMethodId)
    ) {
      const validationMethod = this.shareableValidationMethods.find(
        (x) => x.id === this.model.sharedValidationMethodId
      );
      this.shareableValidationGroups = validationMethod.groups;
    } else {
      this.shareableValidationGroups = [];
    }
  }

  update() {
    this.updateShareableValidationGroups();

    this.loginService.customerService.update(this.model).subscribe((result: Customer) => {
      this.model = result;
    });
  }

  async updateContact(contact: DscContact) {
    await this.dscContactSerice.update(contact);
  }

  generateRegistrationLink() {
    var params = this.router.createUrlTree(['/register'], {
      queryParams: {
        customerId: this.model.id,
        validationMethodId: this.model.sharedValidationMethodId,
        catalogId: this.model.sharedCatalogId
      }
    });
    this.generatedRegistrationLink = location.origin + params;

    if (this.model.isGastro) {
      this.generatedRegistrationLink = this.generatedRegistrationLink.replace('app', 'gastro');
    }
  }

  get showPlaceHolders() {
    return this.loginService.showPlaceHolders;
  }
}
