export function calculateNetPrice(priceType: string, priceAmount: number | string, taxRate: number | string) {
  if (!priceType?.startsWith('gross_') || !priceAmount) return parseFloat((priceAmount || '0').toString());
  const amount = (typeof priceAmount === 'string' ? parseFloat(priceAmount) : priceAmount) || 0;
  const tax = typeof taxRate === 'string' ? parseFloat(taxRate) : taxRate || 0;
  return amount / (1 + tax);
}

export function calculateGrossPrice(priceType: string, priceAmount: number | string, taxRate: number | string) {
  if (!priceType?.startsWith('net_') || !priceAmount) return parseFloat((priceAmount || '0').toString());
  const amount = (typeof priceAmount === 'string' ? parseFloat(priceAmount) : priceAmount) || 0;
  const tax = typeof taxRate === 'string' ? parseFloat(taxRate) : taxRate || 0;
  return amount * (1 + tax);
}

export function calculateMetricFactor(unitA: string, unitB: string) {
  const metricSystems: { [key: string]: string[] } = {
    liquids: ['LTR', 'CL', 'CLT', 'MLT', '_4G'], // Flüssigkeiten
    weight: ['GRM', 'KGM', 'MC', 'MGM', 'TNE'], // Gewicht
    length: ['KMT', 'MTR', 'CMT', 'MMT'], // Längenmaße
    area: ['MTK'], // Flächenmaße
    volume: ['CMQ', 'CMK'] // Volumen
  };

  const conversionFactors: { [key: string]: number } = {
    LTR: 1, // Liter
    CL: 0.01, // Zentiliter
    CLT: 0.01, // Zentiliter
    MLT: 0.001, // Milliliter
    _4G: 0.000001, // Mikroliter
    GRM: 1, // Gramm
    KGM: 1000, // Kilogramm
    KMT: 1000, // Kilometer
    MTR: 1, // Meter
    CMQ: 1000, // Kubikmeter
    MTQ: 1, // Quadratmeter
    MC: 0.000001, // Mikrogramm
    CMT: 0.01, // Zentimeter
    MMT: 0.001, // Millimeter
    MGM: 0.001, // Milligramm
    MTK: 1, // Quadratmeter
    CMK: 1000, // Kubikmeter
    TNE: 1000000, // Tonne (metrisch)
    NMO: 1e-9 // Nanomol
  };

  const categoryA = Object.keys(metricSystems).find((category) => metricSystems[category].includes(unitA));
  const categoryB = Object.keys(metricSystems).find((category) =>
    metricSystems[category].includes(unitB)
  );

  if (categoryA === categoryB) {
    const factorA = conversionFactors[unitA] ?? null;
    const factorB = conversionFactors[unitB] ?? null;
    if (factorA && factorB) {
      return factorA / factorB;
    }
  }

  return 1;
}

export function calculateBasicPrice(
  priceAmount: number,
  priceQuantity: number,
  basicQuantity: number,
  contentQuantity: number,
  metricFactor: number = 1
) {
  if (priceAmount === null) return 0;
  priceQuantity = priceQuantity || 1;
  basicQuantity = (basicQuantity || 0);
  contentQuantity = contentQuantity || 1;
  return ((priceAmount / priceQuantity) * (basicQuantity / contentQuantity)) / metricFactor;
}

export function getDisplayPrice(amount: number, currency: string, locale: string) {
  return amount.toLocaleString(locale, { style: 'currency', currency: currency || 'EUR' });
}
