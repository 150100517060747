import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EMPTY, Subject, catchError, takeUntil, tap } from 'rxjs';
import { Item as dxListItem } from 'devextreme/ui/list';

import { ValidFeature } from 'app/Model/ValidFeature';
import { ValidFeatureSystem } from 'app/Model/ValidFeatureSystem';
import { ItemClickEvent } from 'devextreme/ui/list';
import { NextPimField } from 'app/Model/Catalog/NextPimField';
import { ValidFeatureService } from 'app/Services/validFeature.service';
import { fieldNames } from 'app/Model/FieldNames';
import { ValueChangedEvent } from 'devextreme/ui/drop_down_box';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { TemplateService } from 'app/Services/template.service';
import { comparePimFields, fillLastKey, isNextPimField, parsePimField } from 'app/Services/templateItemHelpers';
import { ValueChangedEvent as NumberBoxValueChangeEvent } from 'devextreme/ui/number_box';

@Component({
  selector: 'np-advanced-template-feature',
  templateUrl: './advanced-template-feature.component.html',
})
export class AdvancedTemplateFeatureComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() templateItem: TemplateItem;
  @Input() customerId: string;
  @Input() validFeatureSystems: ValidFeatureSystem[] = [];
  @Input() fieldList: dxListItem[] = [];
  @Output() onUpdate = new EventEmitter<void>();

  get pimField() {
    if (!this.templateItem.pimFields || this.templateItem.pimFields.length === 0) {
      this.templateItem.pimFields = [new NextPimField()];
    }
    return this.templateItem.pimFields[0];
  }

  selectedValidFeatureSystem: ValidFeatureSystem = new ValidFeatureSystem();
  validFeatures: ValidFeature[] = [];
  selectedValidFeature: ValidFeature = new ValidFeature();

  get isFeatureSystemOnly() {
    const field = this.pimField.field;
    return (
      field === fieldNames.featureSystemName ||
      field === fieldNames.featureSystemGroupId ||
      field === fieldNames.featureSystemGroupId2 ||
      field === fieldNames.featureSystemGroupName ||
      field === fieldNames.featureSystemOrder
    );
  }

  constructor(private templateService: TemplateService, private validFeatureService: ValidFeatureService) {}

  ngOnInit() {
    console.log('feature.ngOnInit');
    this.fillSelectedValidFeatureSystem(this.validFeatureSystems);
    if (this.selectedValidFeatureSystem?.id) {
      this.loadValidFeatures(this.selectedValidFeatureSystem?.id, true);
    }
  }

  ngOnDestroy() {
    console.log('feature.ngOnDestroy');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFieldDisplayExpr(item: dxListItem) {
    return item?.text === item?.key ? item?.key ?? '' : `${item.text} (${item.key})`;
  }

  getValidFeatureDisplayExpr(item: ValidFeature) {
    return item ? item.name + (item.unit?.trim() ? ` (${item.unit})` : '') : '';
  }

  onFieldItemClick(event: ItemClickEvent<dxListItem>) {
    this.pimField.field = event.itemData.key;
  }

  onValidFeatureSystemClick(event: ItemClickEvent<ValidFeatureSystem>) {
    this.pimField.systemKey = event.itemData.id;
  }

  onValidFeatureSystemChanged(event: ValueChangedEvent) {
    this.pimField.systemKey = event.value ?? '-1';
    this.fillSelectedValidFeatureSystem(this.validFeatureSystems);
    if (event.previousValue !== event.value && !this.isFeatureSystemOnly) {
      this.pimField.elementKey = '-1';
      this.loadValidFeatures(this.pimField.systemKey).subscribe(() => this.update());
    } else {
      this.update();
    }
  }

  onValidFeatureClick(event: ItemClickEvent<ValidFeature>) {
    this.pimField.elementKey = event.itemData.id;
  }

  onValidFeatureChanged(event: ValueChangedEvent) {
    this.pimField.elementKey = event.value ?? '-1';
    this.fillSelectedValidFeature(this.validFeatures);
    queueMicrotask(() => this.update());
  }

  triggerKeyGeneration() {
    this.templateItem.key = '';
    this.fillSelectedValidFeature(this.validFeatures);
    queueMicrotask(() => this.update());
  }

  onOrderChanged(event: NumberBoxValueChangeEvent) {
    this.normalizeElementKey(event.value);
    this.update();
  }

  update(emit = true) {
    const { field, systemKey = '-1', elementKey = '-1' } = this.pimField;
    const parsedKey = parsePimField(this.templateItem.key, systemKey, elementKey);
    if (parsedKey !== null && (comparePimFields(parsedKey, this.pimField) || !isNextPimField(parsedKey.field))) {
      this.onUpdate.emit();
      return;
    }

    this.templateService
      .getHeadline(field, elementKey, systemKey, this.customerId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((headline) => {
        fillLastKey(this.templateItem, headline.value);
        this.onUpdate.emit();
      });
  }

  private loadValidFeatures(systemId: string, subscribe = false) {
    const observer = this.validFeatureService.getFeatures(systemId, this.customerId, false).pipe(
      takeUntil(this.unsubscribe$),
      tap((features) => {
        if (!features) return;
        this.validFeatures = features;
        this.fillSelectedValidFeature(features);
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      })
    );

    if (subscribe) {
      observer.subscribe();
    }
    return observer;
  }

  private fillSelectedValidFeatureSystem(validFeatureSystems: ValidFeatureSystem[]) {
    const validFeatureSystem = validFeatureSystems?.find((x) => x.id === this.pimField.systemKey);
    if (validFeatureSystem) {
      this.selectedValidFeatureSystem = new ValidFeatureSystem(validFeatureSystem.id, validFeatureSystem.name);
    } else {
      this.selectedValidFeatureSystem = new ValidFeatureSystem();
    }
  }

  private fillSelectedValidFeature(validFeatures: ValidFeature[]) {
    const validFeature = validFeatures?.find((x) => x.id === this.pimField.elementKey);
    if (validFeature) {
      this.selectedValidFeature = new ValidFeature(
        validFeature.id,
        validFeature.validFeatureSystemId,
        validFeature.name,
        validFeature.unit
      );
    } else {
      this.selectedValidFeature = new ValidFeature();
    }
  }

  private normalizeElementKey(value: number) {
    if (isNaN(value) || value <= 0) {
      this.pimField.elementKey = '1';
    } else {
      this.pimField.elementKey = value.toString();
    }
  }
}
