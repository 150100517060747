<br />
<dx-toolbar>
  <dxi-item location="before">
    <div *dxTemplate>
      <dx-button
        [visible]="canView('editFeatureNew')"
        stylingMode="text"
        type="default"
        text="{{ 'Neue Merkmalsgruppe' | translate }}"
        [disabled]="updateLock"
        (onClick)="addFeatureSystemButtonClicked()"
      ></dx-button>
    </div>
  </dxi-item>

  <dxi-item location="before">
    <div *dxTemplate>
      <dx-button
        [visible]="canView('editFeatureClassification')"
        stylingMode="text"
        type="default"
        text="{{ 'Klassifizieren' | translate }}"
        (onClick)="addClassificationButtonClicked()"
      ></dx-button>
    </div>
  </dxi-item>

  <dxi-item location="after">
    <div *dxTemplate class="d-flex flex-row">
      <div class="dx-field-label switch-left">
        {{ 'Einfach' | translate }}
      </div>
      <dx-switch
        [(value)]="modelService.loginService.currentUser.featureProfiMode"
        switchedOffText="{{ 'Einfach' | translate }}"
        switchedOnText="{{ 'Fortgeschritten' | translate }}"
        hint="{{ 'Selten genutzte Felder aktivieren' | translate }}"
      ></dx-switch>
      <div class="dx-field-label switch-right">
        {{ 'Fortgeschritten' | translate }}
      </div>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-accordion
  [dataSource]="featureSystems"
  itemTemplate="itemTemplate"
  [collapsible]="true"
  [multiple]="false"
  noDataText="{{ 'keine FeatureSysteme vorhanden' | translate }}"
  [selectedIndex]="-1"
  (onItemTitleClick)="onAccordionItemClick($event)"
  class="np-accordion"
>
  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureSystemsStateNewUI3" />
  <dxo-pager [visible]="true"></dxo-pager>

  <div *dxTemplate="let featureSystem of 'title'">
    <div class="dx-accordion-item-title-caption">
      @if (!featureSystem.editMode) {
      <div>
        {{ featureSystem.order }}. {{ featureSystem.referenceFeatureSystemName | translate }}

        <span *ngIf="modelService.loginService.currentUser.featureProfiMode && ( featureSystem.referenceFeatureGroupId || featureSystem.referenceFeatureGroupName )" class="position-relative">
          {{ ' | ' }}
          @if (featureSystem.referenceFeatureGroupId) {
            <span [ngStyle]="{ color: 'var(--dx-color-primary)' }">
              <b>{{ 'ID' | translate }}:</b>
              {{ featureSystem.referenceFeatureGroupId }}
            </span>
          }
          @if (featureSystem.referenceFeatureGroupName) {
            @if (featureSystem.referenceFeatureGroupId) {
              {{ ' | ' }}
            }
            <span [ngStyle]="{ color: 'var(--dx-color-primary)' }">
              <b>{{ 'Name' | translate }}:</b>
              {{ featureSystem.referenceFeatureGroupName }}
            </span>
          }
        </span>
        @if (featureSystem.features) {
          {{ ' (' + featureSystem.features.length + ')' }}
        }
      </div>
      } @else {

      <div class="np-field">
        <np-inputControl
          [(model)]="model"
          [showLinkButtons]="showLinkButtons"
          [showPlaceLabel]="featureSystem?.order != null"
          [suppressLabel]="true"
          [suppressTranslation]="true"
          [suppressToolTip]="true"
          [(selectedElements)]="selectedElements"
          (elementSelected)="
            templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(featureSystem)) : null
          "
          (onValueChanged)="update($event)"
          labelText="{{ 'Order' | translate }}"
          field="FS_ORDER"
          [system]="getRelevantSystemKey(featureSystem)"
          [showDragHandle]="showDragHandle && featureSystemIsValid(featureSystem)"
          [dragFilter]="dragFilter"
        >
          <div slot="content" class="contentSlot">
            <dx-number-box
              [(value)]="featureSystem.order"
              [min]="1"
              [showClearButton]="false"
              [showSpinButtons]="!readOnlyForLinkedFields('FS_ORDER', featureSystem.validFeatureSystemId)"
              [readOnly]="
                readOnlyForLinkedFields('FS_ORDER', featureSystem.validFeatureSystemId) ||
                readOnly('editfeatureOrder') ||
                readOnlyTemplateEditor(featureSystem)
              "
              (onValueChanged)="update($event, featureSystem, 'FS_ORDER', featureSystem.validFeatureSystemId)"
            ></dx-number-box>
          </div>
        </np-inputControl>
      </div>

      <div class="np-field">
        <np-inputControl
          [(model)]="model"
          [showLinkButtons]="showLinkButtons"
          [showPlaceLabel]="featureSystem?.referenceFeatureSystemName?.length > 0"
          [suppressLabel]="true"
          [(selectedElements)]="selectedElements"
          (elementSelected)="
            templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(featureSystem)) : null
          "
          (onValueChanged)="update($event)"
          field="REFERENCE_FEATURE_SYSTEM_NAME"
          [system]="getRelevantSystemKey(featureSystem)"
          [showDragHandle]="false"
          [dragFilter]="dragFilter"
          (excelElementDropped)="excelFeatureSystemNameDroppedError()"
        >
          <div slot="content" class="contentSlot">
            <dx-select-box
              [(value)]="featureSystem.referenceFeatureSystemName"
              [showClearButton]="
                showClearButton(
                  'REFERENCE_FEATURE_SYSTEM_NAME',
                  featureSystem.validFeatureSystemId,
                  'editfeatureSystemName'
                )
              "
              [readOnly]="
                hasFeatureSystemFieldLinked(featureSystem.validFeatureSystemId) || readOnly('editfeatureSystemName')
              "
              (onValueChanged)="updateFeatureSystemNameAndUpdate($event, featureSystem)"
              (validationMessageMode)="(always)"
              [minSearchLength]="0"
              [searchEnabled]="true"
              acceptCustomValue="true"
              placeholder=""
              [dataSource]="featureSystemNameSource"
            >
              <dx-validator
                *ngIf="!templateView"
                validationGroup="FEAT"
                (onInitialized)="validationService.startAutomaticValidation($event)"
              >
                <dxi-validation-rule
                  type="async"
                  [validationCallback]="
                    validationService.validateField(
                      'REFERENCE_FEATURE_SYSTEM_NAME',
                      getRelevantSystemKey(featureSystem)
                    )
                  "
                ></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </np-inputControl>
      </div>

      <div class="np-field" *ngIf="modelService.loginService.currentUser.featureProfiMode" style="max-width: 30%">
        <np-inputControl
          [(model)]="model"
          [showLinkButtons]="showLinkButtons"
          [showPlaceLabel]="featureSystem?.referenceFeatureGroupId?.length > 0"
          [suppressLabel]="true"
          [(selectedElements)]="selectedElements"
          (elementSelected)="
            templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(featureSystem)) : null
          "
          (onValueChanged)="update($event)"
          field="REFERENCE_FEATURE_GROUP_ID"
          [system]="getRelevantSystemKey(featureSystem)"
          [showDragHandle]="showDragHandle && featureSystemIsValid(featureSystem)"
          [dragFilter]="dragFilter"
          (excelElementDropped)="excelElementDrop($event)"
        >
          <div slot="content" class="contentSlot">
            <dx-text-box
              [(value)]="featureSystem.referenceFeatureGroupId"
              [showClearButton]="showClearButton('REFERENCE_FEATURE_GROUP_ID', featureSystem.validFeatureSystemId)"
              [readOnly]="
                readOnlyForLinkedFields('REFERENCE_FEATURE_GROUP_ID', featureSystem.validFeatureSystemId) ||
                readOnly('editfeatureGroupId') ||
                readOnlyTemplateEditor(featureSystem)
              "
              (onValueChanged)="
                update($event, featureSystem, 'REFERENCE_FEATURE_GROUP_ID', featureSystem.validFeatureSystemId)
              "
            >
              <dx-validator
                *ngIf="!templateView"
                validationGroup="FEAT"
                (onInitialized)="validationService.startAutomaticValidation($event)"
              >
                <dxi-validation-rule
                  type="async"
                  [validationCallback]="
                    validationService.validateField('REFERENCE_FEATURE_GROUP_ID', getRelevantSystemKey(featureSystem))
                  "
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </np-inputControl>
      </div>

      <div class="np-field" *ngIf="modelService.loginService.currentUser.featureProfiMode" style="max-width: 30%">
        <np-inputControl
          [(model)]="model"
          [showLinkButtons]="showLinkButtons"
          [showPlaceLabel]="featureSystem?.referenceFeatureGroupName?.length > 0"
          [suppressLabel]="true"
          [(selectedElements)]="selectedElements"
          (elementSelected)="
            templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(featureSystem)) : null
          "
          (onValueChanged)="update($event)"
          field="REFERENCE_FEATURE_GROUP_NAME"
          [system]="getRelevantSystemKey(featureSystem)"
          [showDragHandle]="showDragHandle && featureSystemIsValid(featureSystem)"
          [dragFilter]="dragFilter"
          (excelElementDropped)="excelElementDrop($event)"
        >
          <div slot="content" class="contentSlot">
            <dx-text-box
              [(value)]="featureSystem.referenceFeatureGroupName"
              [showClearButton]="showClearButton('REFERENCE_FEATURE_GROUP_NAME', featureSystem.validFeatureSystemId)"
              [readOnly]="
                readOnlyForLinkedFields('REFERENCE_FEATURE_GROUP_NAME', featureSystem.validFeatureSystemId) ||
                readOnly('editfeatureGroupName') ||
                readOnlyTemplateEditor(featureSystem)
              "
              (onValueChanged)="
                update($event, featureSystem, 'REFERENCE_FEATURE_GROUP_NAME', featureSystem.validFeatureSystemId)
              "
            >
              <dx-validator
                *ngIf="!templateView"
                validationGroup="FEAT"
                (onInitialized)="validationService.startAutomaticValidation($event)"
              >
                <dxi-validation-rule
                  type="async"
                  [validationCallback]="
                    validationService.validateField('REFERENCE_FEATURE_GROUP_NAME', getRelevantSystemKey(featureSystem))
                  "
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </np-inputControl>
      </div>
      }

      <div *ngIf="!featureSystem.editMode" class="d-flex flex-grow-1 justify-content-between gap-1 mx-3">
        <div>
          <dx-button
            class="btn-icon np-accordion-hoverable"
            stylingMode="text"
            type="default"
            icon="edit"
            (onClick)="toggleFeatureSystemEditMode($event, featureSystem)"
          />
        </div>
        <dx-button
          class="btn-icon"
          stylingMode="text"
          type="default"
          icon="trash"
          (onClick)="onDeleteClick($event, featureSystem)"
        />
      </div>

      <div *ngIf="featureSystem.editMode" class="mx-3">
        <dx-button
          class="btn-icon"
          stylingMode="text"
          type="default"
          icon="check"
          (onClick)="toggleFeatureSystemEditMode($event, featureSystem)"
        />
      </div>
    </div>
  </div>

  <div *dxTemplate="let featureSystem of 'itemTemplate'">
    <features
      [(modelService)]="modelService"
      [(featureSystem)]="featureSystem"
      [(model)]="model"
      [templateView]="templateView"
      [(displayMode)]="displayMode"
      [showPlaceHolders]="showPlaceHolders"
      [showLinkButtons]="showLinkButtons"
      [hasAddonCLASSIFICATION]="hasAddonCLASSIFICATION"
      (onValueChanged)="update($event)"
      [(selectedElements)]="selectedElements"
      (elementSelected)="templateChildSelected($event)"
      [showDragHandle]="showDragHandle"
      [dragFilter]="dragFilter"
      (excelElementDropped)="excelElementDrop($event)"
      (elementDeleted)="delete($event)"
    ></features>
  </div>
</dx-accordion>

<div class="color4Text">
  <div *ngIf="showValidationSummary">
    <table>
      <tr *ngFor="let msg of validationMessages">
        <td>
          {{ translateMessage(msg) }}
        </td>
      </tr>
    </table>
  </div>
</div>
<dx-popup
  [width]="850"
  [height]="750"
  [showTitle]="true"
  title="{{ 'Klassifizieren' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="classificationPopupVisible"
  contentTemplate="popupContent"
>
  <div *dxTemplate="let data of 'popupContent'">
    <productClassification
      (windowClose)="closeClassificationPopup()"
      (addEcommerceFeatures)="emitAddEcommerceFeatures($event)"
      (addClassificationFeatures)="emitAddClassificationFeatures($event)"
    ></productClassification>
  </div>
</dx-popup>
