<div class="d-flex flex-column w-100">
  <div class="header d-flex flex-row px-3 pt-1 w-100">
    <div class="p-2 flex-fill overflow-hidden">
      <h5>{{ 'DatenQuellen' | translate }}</h5>
      <span innerHTML="{{ 'DatenQuellen Beschreibung' | translate }}"></span>
    </div>
  </div>

  <dx-data-grid
    [dataSource]="productDataSources"
    (onSaved)="onSaved($event)"
    [allowColumnReordering]="true"
    [repaintChangesOnly]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [selection]="{ allowSelectAll: false }"
  >
    <dxo-column-chooser enabled="true"></dxo-column-chooser>
    <dxo-search enabled="true"></dxo-search>
    <dxo-editing
      mode="batch"
      [(changes)]="changes"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    ></dxo-editing>
    <dxo-paging enabled="true"></dxo-paging>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="productDataSources"></dxo-state-storing>
    <dxo-search-panel enabled="true"></dxo-search-panel>

    <dxi-column title="{{ 'Name' | translate }}" dataField="name"></dxi-column>
    <dxi-column title="{{ 'Beschreibung' | translate }}" dataField="description"></dxi-column>
    <dxi-column
      title="{{ 'Typ' | translate }}"
      dataField="type"
      editCellTemplate="typTemplate"
      showEditorAlways="true"
    ></dxi-column>
    <div *dxTemplate="let cellInfo of 'typTemplate'">
      <dx-lookup
        [items]="types"
        [value]="cellInfo.value"
        (onValueChanged)="cellInfo.setValue($event.value)"
      ></dx-lookup>
    </div>

    <dxi-column
      title="{{ 'Alternative Namen' | translate }}"
      dataField="alternativeNames"
      editCellTemplate="altNamesTemplate"
      showEditorAlways="true"
    ></dxi-column>
    <div *dxTemplate="let cellInfo of 'altNamesTemplate'">
      <dx-tag-box
        [value]="cellInfo.value"
        [acceptCustomValue]="true"
        (onValueChanged)="cellInfo.setValue($event.value)"
      ></dx-tag-box>
    </div>

    <dxi-column title="{{ 'KreditorNr' | translate }}" dataField="kreditorNr"></dxi-column>

    <dxi-column title="{{ 'Support Telefon' | translate }}" dataField="supportTel"></dxi-column>
    <dxi-column title="{{ 'Support E-Mail' | translate }}" dataField="supportMail"></dxi-column>
    <dxi-column title="{{ 'Website' | translate }}" dataField="website"></dxi-column>

    <dxi-column title="{{ 'Logo' | translate }}" dataField="logoFile"></dxi-column>
    <dxi-column title="{{ 'Garantieseiten' | translate }}" dataField="warrantyPages"></dxi-column>

    <dxi-column
      title="{{ 'Country Code' | translate }}"
      dataField="countryCode"
      editCellTemplate="countryCodeTemplate"
      showEditorAlways="true"
    ></dxi-column>
    <div *dxTemplate="let cellInfo of 'countryCodeTemplate'">
      <dx-lookup
        [items]="countryCodes"
        [value]="cellInfo.value"
        (onValueChanged)="cellInfo.setValue($event.value)"
      ></dx-lookup>
    </div>

    <dxi-column
      title="{{ 'Adresse' | translate }}"
      dataField="address"
      cellTemplate="addressTemplate"
      cssClass="p-0"
      [allowEditing]="false" 
    ></dxi-column>
    <div *dxTemplate="let cellInfo of 'addressTemplate'">
      <dx-text-area
        [items]="types"
        [(value)]="cellInfo.value"
        (onValueChanged)="onValueChanged($event, cellInfo)"
      ></dx-text-area>
    </div>

    <dxi-column
      title="{{ 'Zusatzinformationen' | translate }}"
      dataField="additionalInformation"
      cellTemplate="additionalInfoTemplate"
      cssClass="p-0"
      [allowEditing]="false"
    ></dxi-column>
    <div *dxTemplate="let cellInfo of 'additionalInfoTemplate'">
      <dx-text-area
        [items]="types"
        [(value)]="cellInfo.value"
        (onValueChanged)="onValueChanged($event, cellInfo)"
      ></dx-text-area>
    </div>
  </dx-data-grid>
</div>
