<dx-drop-down-box
  class="flex-grow-1 np-highlight"
  [showClearButton]="true"
  [(value)]="pimField.field"
  [dataSource]="fieldList"
  [displayExpr]="getFieldDisplayExpr"
  valueExpr="key"
  label="{{ 'Feld' | translate }}"
  placeholder="{{ 'Feld auswählen' | translate }}"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="fieldList"
      [searchExpr]="['text', 'key']"
      [displayExpr]="getFieldDisplayExpr"
      valueExpr="key"
      selectionMode="single"
      (onItemClick)="onFieldItemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>
