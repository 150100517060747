import { Component, OnInit } from '@angular/core';
import { Catalog } from '../../Model/Catalog/Catalog';
import { TreeItem } from '../../Model/Dto/TreeItem';
import { ModelService } from '../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { HazmatClass } from '../../Model/Catalog/HazmatClass';
import { WarrantyClass } from '../../Model/Catalog/WarrantyClass';
import { ViewService } from '../../Services/view.service';
import { Message } from '../../Model/System/Message';
import { UserManagementService } from '../../Services/userManagment.service';
import { Output, EventEmitter } from '@angular/core';
import { Product } from "../../Model/Catalog/Product";
import { MassDataChangeService } from "../../Services/CatalogManagement/massDataChange.service";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { ValidationRequestCatalog } from "../../Model/validation/ValidationRequestCatalog";
import ValidationEngine from "devextreme/ui/validation_engine";
import { ValidationService } from "../../Services/Validation/validation.service";
import { CatalogState } from "../../Model/Catalog/CatalogState";
import { CatalogDataQuality } from "../../Model/validation/CatalogDataQuality";
import { lastValueFrom } from "rxjs";
import { ValidationGroupToProductCount } from "../../Model/validation/ValidationGroupToProductCount";
import { StartAction } from "../../Model/Dto/StartAction";
import { CatalogDataQualityChartGroup } from "../../Model/validation/CatalogDataQualityChartGroup";
import { SystemService } from "../../Services/system.service";
import { CatalogDataQualityService } from "../../Services/Validation/catalogDataQuality.service";
import { ActivatedRoute, Router } from "@angular/router";
import { custom } from 'devextreme/ui/dialog';

@Component({
  selector: 'catalogDetail',
  templateUrl: 'catalogDetail.component.html',
  styleUrls: ['./catalogDetail.component.css', './../shared/detail.css']
})
export class CatalogDetailComponent implements OnInit {
  @Output() onMassDataEdit = new EventEmitter<Product>();
  validateCatalog = ValidationEngine.validateGroup;
  agreementDummy: string[] = [];
  emptyArray: string[] = []; // ==> https://supportcenter.devexpress.com/ticket/details/t1110923#f7c58b18-fd34-492b-b7ec-c354d1b53796
  catalogStates: string[] = [];
  catalogDataQuality: CatalogDataQuality;
  dataQualityPieChartDataSource: CatalogDataQualityChartGroup[];
  dataQualityPieChartColorPalette: string[];
  isDataQualityPieChartEmpty: boolean = false;
  isCatalogDataQualityUpToDate: boolean = false;
  currentTabIndex: number = 0;
  tree: TreeItem[];
  childs: TreeItem[];
  pages: { id: string; title: string; template: string; }[] = [];

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagment: UserManagementService,
    public massDataService: MassDataChangeService, public catalogService: CatalogService, public validationService: ValidationService,
    private systemService: SystemService, private catalogDataQualityService: CatalogDataQualityService,
    private router: Router, private route: ActivatedRoute) {
    this.showImportMedia = this.showImportMedia.bind(this);
    this.showImportCatalog = this.showImportCatalog.bind(this);
    this.showExport = this.showExport.bind(this);
    this.showTranslate = this.showTranslate.bind(this);
    this.showCategoryExport = this.showCategoryExport.bind(this);
    this.showCategoryImport = this.showCategoryImport.bind(this);
    this.showUserActions = this.showUserActions.bind(this);
    this.exportWindow = this.exportWindow.bind(this);
    this.exportFullScreen = this.exportFullScreen.bind(this);
    this.openMassDataChange = this.openMassDataChange.bind(this);
    this.validateCatalogId = this.validateCatalogId.bind(this);
    this.updateState = this.updateState.bind(this);
    this.customizeDataQualityPieTooltip = this.customizeDataQualityPieTooltip.bind(this);
    this.onGroupClicked = this.onGroupClicked.bind(this);
    this.getStatus();
  }

  async ngOnInit() {
    this.translateTexts();
    this.reset();

    this.agreementDummy.push("dummy");

    await this.refreshDataQuality();
    this.validationService.validationResultChanged.subscribe(async x => await this.refreshDataQuality());

    this.route.url.subscribe(url => {
      const activeRoute = this.router.url;
      this.currentTabIndex = this.pages.findIndex(x => activeRoute.includes(x.id));
    });
  }

  async refreshDataQuality() {
    this.catalogDataQuality = await this.catalogDataQualityService.getCatalogDataQuality(this.modelService.loginService.currentUser.customerId, this.catalogService.selectedCatalogId);

    this.dataQualityPieChartDataSource = [];
    this.dataQualityPieChartColorPalette = [];
    if (this.catalogDataQuality.productsCountInGroups != undefined && this.catalogDataQuality.productsCountInGroups.length > 0) {
      this.isCatalogDataQualityUpToDate = await this.catalogDataQualityService.getCatalogDataQualityUpToDateInfo(this.modelService.loginService.currentUser.customerId, this.catalogService.selectedCatalogId);

      this.catalogDataQuality.productsCountInGroups.forEach(x => {
        this.dataQualityPieChartDataSource.push(new CatalogDataQualityChartGroup(x.validationGroup.id, x.validationGroup.name, x.productCount, x.validationGroup.stateText));
        this.dataQualityPieChartColorPalette.push(x.validationGroup.backgroundColor);
      });
      this.isDataQualityPieChartEmpty = false;
    } else {
      this.isCatalogDataQualityUpToDate = false;
      this.dataQualityPieChartDataSource.push(new CatalogDataQualityChartGroup("placeholder1", "Name1", 1));
      this.dataQualityPieChartColorPalette.push('#424242');
      this.dataQualityPieChartDataSource.push(new CatalogDataQualityChartGroup("placeholder2", "Name2", 2));
      this.dataQualityPieChartColorPalette.push('#9E9E9E');
      this.dataQualityPieChartDataSource.push(new CatalogDataQualityChartGroup("placeholder3", "Name3", 7));
      this.dataQualityPieChartColorPalette.push('#E0E0E0');
      this.isDataQualityPieChartEmpty = true;
    }
  }

  jumpToCatalogDataQuality() {
    this.router.navigate(['catalog/' + this.modelService.loginService.currentUser.customerId + '/' + this.catalogService.selectedCatalogId + '/dataquality'])
  }

  onTabChange(event) {
    var tabId = event.addedItems[0].id;
    if (tabId) {
      this.router.navigate(['catalog/' + this.modelService.loginService.currentUser.customerId + '/' + this.catalogService.selectedCatalogId + '/' + tabId]);
    }
  }

  onGroupClicked(event) {
    var pieceClicked: CatalogDataQualityChartGroup = event.target.data;
    if (!pieceClicked)
      return;

    var indexOfNextGroup = this.dataQualityPieChartDataSource.findIndex(x => x.id == pieceClicked.id) + 1;
    if (indexOfNextGroup == 0)
      return;

    if (this.dataQualityPieChartDataSource.length <= indexOfNextGroup) {
      this.systemService.notifyInfo(this.translate.instant("Kein invalider Status-Text vorhanden, da diese Produkte schon alle Regeln erfüllen"))
      return;
    }

    var nextGroup = this.dataQualityPieChartDataSource[indexOfNextGroup];
    if (!nextGroup)
      return;

    this.catalogService.showFilter = true;
    var filter = nextGroup.filter;
    if (!filter) {
      this.systemService.notifyInfo(this.translate.instant("Kein invalider Status-Text für die nächsthöhere Gruppe gesetzt"))
      return;
    }

    if (this.catalogService.selectedStates.includes(filter)) {
      var filterIndex = this.catalogService.selectedStates.indexOf(filter, 0);
      if (filterIndex > -1) {
        this.catalogService.selectedStates.splice(filterIndex, 1);
      }
    } else {
      this.catalogService.selectedStates.push(filter);
    }
  }

  getStatus() {
    this.modelService.catalogStateService.getStatus().subscribe((result: CatalogState[]) => {
      this.catalogStates = [];
      result.forEach((x) => {
        this.catalogStates.push(x.name);
      });
    });
  }

  updateState(event) {
    for (const state of event.value) {
      if (this.catalogStates.every((item) => item !== state)) {
        let status = new CatalogState();
        status.customerId = this.modelService.loginService.currentUser.customerId;
        status.name = state;
        this.catalogStates.push(state);
        this.modelService.catalogStateService.addCatalogState(status).subscribe((result: CatalogState) => {
          status = result;
          this.modelService.catalogStateService.catalogState.push(status.name);
        });
      }
    }
    this.modelService.catalogService.updateCatalog(event);
  }

  showUserActionWindow() {
    this.modelService.catalogService.showUserAction = true;
  }

  showUserActions() {
    this.showUserAction = true;
  }

  calculateDataQuality() {
    let param = new StartAction();
    param.customerId = this.modelService.loginService.currentUser.customerId;
    param.catalogId = this.modelService.catalogService.selectedCatalogId;

    this.catalogDataQualityService.CalculateCatalogDataQuality(param).subscribe(
      () => {
        this.translate.get("Berechnung wurde als Job gestartet").subscribe((text: string) => {
          this.modelService.systemService.notify(new Message(text));
        });
      }
    );
  }

  validateCatalogId(params) {
    let request = new ValidationRequestCatalog();
    request.catalogId = this.model.id;
    request.value = this.model.catalogId;
    request.customerId = this.model.customerId;
    request.field = 'CATALOG_ID';
    request.isActivId = this.model.isActivId;

    this.validationService.validateCatalogId(params, request);
  }

  set showUserAction(value) {
    this.modelService.catalogService.showUserAction = value;
  }

  reload() {
    this.modelService.catalogService.treeRefresh();
    this.modelService.catalogService
      .getCatalog(this.modelService.catalogService.catalog.id, this.modelService.loginService.currentUser.customerId)
      .subscribe();
  }

  validateCatalogIdInit() { }

  updateCatalogGroup() {
    this.modelService.catalogService.setCatalogGroupe();
    this.modelService.catalogService.updateCatalog();
  }

  get showUserAction() {
    return this.modelService.catalogService.showUserAction;
  }

  get hasElectronicSalesAndEClass() {
    return this.modelService.loginService.hasAddonElectronicSales && this.modelService.loginService.hasAddonECLASS;
  }

  txtKatalog: string;
  txtMedien: string;
  txtLieferant: string;
  txtKaeufer: string;
  txtDatenqualitaet: string;
  txtSettings: string;
  txtAlleMedien: string;

  private translateTexts() {
    this.txtKatalog = this.translate.instant('Katalog');
    this.txtMedien = this.translate.instant('Medien');
    this.txtLieferant = this.translate.instant('Lieferant');
    this.txtKaeufer = this.translate.instant('Käufer');
    this.txtDatenqualitaet = this.translate.instant('Datenqualität');
    this.txtSettings = this.translate.instant('Einstellungen');
    this.txtAlleMedien = this.translate.instant('Alle Medien');
  }

  reset() {
    this.pages = [
      { id: "info", title: this.txtKatalog, template: "katalogTemplate" },
      { id: "media", title: this.txtMedien, template: "medienTemplate" }
    ];
    if (this.modelService.loginService.hasAddonCATALOG_DETAILS) {
      this.pages.push({ id: "supplier", title: this.txtLieferant, template: "lieferantTemplate" });
      this.pages.push({ id: "buyer", title: this.txtKaeufer, template: "kaeuferTemplate" });
    }
    if (this.modelService.loginService.hasAddonDATA_QUALITY) {
      this.pages.push({ id: "dataquality", title: this.txtDatenqualitaet, template: "dataQualityTemplate" });
    }
    if (this.canView('catalogSettings'))
      this.pages.push({ id: "settings", title: this.txtSettings, template: "settingsTemplate" });
    this.pages.push({ id: "mediapool", title: this.txtAlleMedien, template: "poolTemplate" });

    if (this.modelService.loginService.hasAddonCLASSIFICATION) {
      this.modelService.classificationService.getSystems();
    }
    if (this.modelService.loginService.hasAddonGevis) {
      this.pages.push({ id: "gevis", title: "Gevis", template: "gevisTemplate" });
    }
    if (this.modelService.loginService.hasAddonODOO) {
      this.pages.push({ id: "odoo", title: "Odoo", template: "odooTemplate" });
    }

    this.modelService.hazmatClassService.getClasses().subscribe((result: HazmatClass[]) => {
      this.modelService.hazmatClassService.hazmatClasses = result;
    });

    this.modelService.warrantyClassService.getClasses().subscribe((result: WarrantyClass[]) => {
      this.modelService.warrantyClassService.warrantyClasses = result;
    });

  }

  createChildren = async (parent) => {
    const parentId = parent ? parent.itemData.id : '';
    this.childs = await this.modelService.catalogService.getChilds(
      this.modelService.catalogService.selectedCatalogId,
      parentId,
      this.modelService.loginService.currentUser.customerId,
      null,
      this.modelService.catalogService.selectedStates,
      null
    );
    return this.childs;
  };

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    ValidationEngine.validateGroup('catalogValidationGroup');
    this.modelService.catalogService.updateCatalog(e);
  }

  catalogIdUpdate(e = null) {

    const { catalogService, loginService } = this.modelService;
    const catalogId = catalogService.catalog.catalogId;

    const validateAndUpdate = () => {
      ValidationEngine.validateGroup('catalogIdValidationGroup');
      catalogService.updateCatalog(e);
    };

    if (e?.component?.NAME === "dxSwitch" && e.value) {
      const catalogs = catalogService.catalogs.filter(catalog => catalog.catalogId === catalogId);

      if (catalogs.length <= 1) {
        validateAndUpdate();
        return;
      }

      const actualActivCatalog = catalogs.find(catalog => catalog.isActivId);

      if (actualActivCatalog) {
        catalogService.checkVirtualCatalogs(actualActivCatalog.id, loginService.currentCustomer.id)
          .then((result: Record<string, number>) => {
            if (Object.keys(result).length > 0) {
              this.showUpdateDialogWithVirtualCatalogInfo(result);
            } else {
              validateAndUpdate();
            }
          });
      }
    } else {
      validateAndUpdate();
    }

  }

  showUpdateDialogWithVirtualCatalogInfo(result: Record<string, number>) {
    var rootUrl = window.location.origin;
    var catalog: Catalog;
    const currentCustomerId = this.modelService.loginService.currentCustomer.id;
    let actualActivCatalog = this.modelService.catalogService.catalogs.find(catalog => catalog.catalogId == this.modelService.catalogService.catalog.catalogId && catalog.isActivId);
    let messageText = "<p>" +
      this.translate.instant("ChangeMasterNoteVirtualCatalogs", { value1: rootUrl, value2: this.modelService.loginService.currentCustomer.id, value3: actualActivCatalog.id }) +
      " <a href='https://knowledge.nextpim.de/wichtige-funktionen#virtuelle-kataloge' target='_blank'>nextPIM Wiki</a>." +
      "</p>" +
      "<p>Anzahl Verlinkungen: </p>" +
      "<table>" +
      " <th>Katalog</th>" +
      " <th>Anzahl</th>";
    for (const [key, value] of Object.entries(result)) {
      catalog = this.modelService.catalogService.catalogs.find(cat => cat.id == key);
      if (catalog) {
        messageText += `<tr>
          <td>
            <a href="${rootUrl}/catalog/${currentCustomerId}/${catalog.id}/info">${catalog.catalogName}</a>
          </td>
          <td style="text-align: center; vertical-align: middle;">
            ${value}
          </td>
        </tr>`;
      }
    }

    messageText += "</table>";

    let virtualCatalogDialog = custom({
      showTitle: true,
      title: this.translate.instant("Master ändern"),
      dragEnabled: false,
      messageHtml: messageText,

      buttons: [{
        text: this.translate.instant("Neuen Master bestätigen"),
        onClick: (e) => {
          this.modelService.catalogService.updateCatalog();
        }
      },
      {
        text: this.translate.instant("Abbrechen"),
        onClick: (e) => {
          this.reload();
        }
      },
      ]
    });
    virtualCatalogDialog.show();
  }

  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  get catalogMainImage(): string {
    if (this.modelService.catalogService.catalog != null && this.modelService.catalogService.catalog.mimes.length > 0) {
      const mime = this.modelService.catalogService.catalog.mimes[0];
      if (mime.normUri) {
        return mime.normUri;
      }
    }
    return null;
  }

  showImportCatalog() {
    this.importCatalogVisible = true;
  }

  hideImportCatalog() {
    this.importCatalogVisible = false;
  }

  showImportMedia() {
    this.importMediaVisible = true;
  }

  hideImportMedia() {
    this.importMediaVisible = false;
  }

  customizeDataQualityPieTooltip(event) {
    if (this.isDataQualityPieChartEmpty) {
      return { text: this.translate.instant("Datenqualität nicht berechnet oder <br>keine Produkte im Katalog vorhanden") };
    }

    var tooltip = event.argumentText;
    tooltip += "<br>";
    if (event.valueText == "1") {
      tooltip += "1 " + this.translate.instant("Produkt");
    } else {
      tooltip += event.valueText + " " + this.translate.instant("Produkte");;
    }
    return { text: tooltip };
  }

  //EXPORT WINDOW
  get fullScreen() {
    return this.modelService.catalogService.viewService.fullScreen;
  }

  set fullScreen(value: boolean) {
    this.modelService.catalogService.viewService.fullScreen = value;
  }

  exportFullScreen() {
    this.modelService.catalogService.viewService.fullScreen = true;
  }

  exportWindow() {
    this.modelService.catalogService.viewService.fullScreen = false;
  }

  showExport() {
    this.modelService.catalogService.exportVisible = true;
  }

  showTranslate() {
    if (this.modelService.loginService.languageFlags.length == 1) {
      this.translate
        .get('Es wurden noch keine Sprachen angelegt, in die eine Übersetzung vorgenommen werden kann.')
        .subscribe((text: string) => {
          this.modelService.systemService.notify(new Message(text), 2500);
        });
    } else {
      this.modelService.catalogService.translateVisible = true;
    }
  }

  get exportVisible() {
    return this.modelService.catalogService.exportVisible;
  }

  set exportVisible(value: boolean) {
    this.modelService.catalogService.exportVisible = value;
  }

  hideExport() {
    this.modelService.catalogService.exportVisible = false;
  }

  private _importMediaVisible: boolean = false;

  get importMediaVisible(): boolean {
    return this._importMediaVisible;
  }

  set importMediaVisible(value: boolean) {
    this._importMediaVisible = value;
  }

  private _importCatalogVisible: boolean = false;

  get importCatalogVisible(): boolean {
    return this._importCatalogVisible;
  }

  set importCatalogVisible(value: boolean) {
    this._importCatalogVisible = value;
  }

  //USABILITY

  get currencies() {
    return ViewService.dropDownCurrencies;
  }

  get idType() {
    return ViewService.idType;
  }

  get territories() {
    return ViewService.dropDownTerritories;
  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  aggrementVisible: boolean = false;

  getLanguageText(language): string {
    return this.modelService.loginService.getLanguageText(language);
  }

  toggleAggrement() {
    if (this.aggrementVisible) {
      this.aggrementVisible = false;
    } else {
      this.aggrementVisible = true;
    }
  }

  get bigHeader() {
    if (window) {
      return window.innerHeight > 1000;
    }
    return true;
  }

  showCategoryExport() {
    this.modelService.categoryService.categoryExportVisible = true;
  }

  hideCategoryExport() {
    this.modelService.categoryService.categoryExportVisible = false;
  }

  get categoryExportVisible() {
    return this.modelService.categoryService.categoryExportVisible;
  }

  set categoryExportVisible(value: boolean) {
    this.modelService.categoryService.categoryExportVisible = value;
  }

  exportCategoryFullScreen() {
    this.modelService.categoryService.viewService.fullScreen = true;
  }

  exportCategoryWindow() {
    this.modelService.categoryService.viewService.fullScreen = false;
  }

  showCategoryImport() {
    this.modelService.categoryService.categoryImportVisible = true;
  }

  hideCategoryImport() {
    this.modelService.categoryService.categoryImportVisible = false;
  }

  get categoryImportVisible(): boolean {
    return this.modelService.categoryService.categoryImportVisible;
  }

  set categoryImportVisible(value: boolean) {
    this.modelService.categoryService.categoryImportVisible = value;
  }

  canView(name: string): boolean {
    return this.userManagment.canView(name);
  }
  readOnly(name: string): boolean {
    return !this.userManagment.canEdit(name);
  }

  renderMakrosButton(): boolean {
    return !this.model?.isVirtual && this.modelService.loginService.hasAddonACTIONS && this.canView('Makros');
  }

  renderMediaImportButton(): boolean {
    return !this.model?.isVirtual && this.canView('mediaImport');
  }

  renderReimportButton(): boolean {
    return !this.model?.isVirtual && this.canView('reimport');
  }

  renderExportButton(): boolean {
    return this.canView('export');
  }

  renderTranslateButton(): boolean {
    return !this.model?.isVirtual && this.modelService.loginService.hasAddonTranslate && this.canView('translate');
  }

  renderCategoryExportButton(): boolean {
    return this.modelService.loginService.hasAddonCATEGORYEXPORT && this.canView('categoryExport');
  }

  renderCategoryImportButton(): boolean {
    return (
      !this.model?.isVirtual &&
      this.modelService.loginService.hasAddonCATEGORYEXPORT &&
      this.canView('categoryImport') &&
      !this.model?.isDemoCatalog
    );
  }

  renderMassDataChangeButton(): boolean {
    return !this.model?.isVirtual && this.canView('massDataChange');
  }

  openMassDataChange() {
    this.massDataService.getEmtpyProductByCatalog(this.catalogService.catalog.id).subscribe((result: Product) => {
      this.onMassDataEdit.emit(
        this.modelService.productService.getProductFromJson(result, this.modelService.loginService.wawiSettings)
      );
    });
  }
}
