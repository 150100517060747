import { Component } from "@angular/core";
import { Credentials } from "../../Model/User/Credentials";
import { Message } from "../../Model/System/Message";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { NewsService } from "../../Services/news.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.css"]
})
export class LoginComponent {
  returnUrl = "/";
  customerId = "";
  creds: Credentials;

  constructor(private route: ActivatedRoute, private router: Router, private modelService: ModelService, public translate: TranslateService, public newsService: NewsService) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.customerId = this.route.snapshot.paramMap.get('customerId')!;
    this.creds = new Credentials();

    if (!this.modelService.loginService.loginRequired) {
      this.modelService.loginService.logout();
      let translation = "";
      this.translate.get('Sie haben sich ausgeloggt').subscribe((text: string) => {
        translation = text;
        this.modelService.systemService.notify(new Message(translation));
      });
    } else {
      this.modelService.loginService.silentlogin(this.customerId ?? "").subscribe(success => {
        if (success) {
          this.translate.get("Sie wurden automatisch angemeldet").subscribe((text: string) => {
            this.modelService.systemService.notify(new Message(text));
            this.modelService.router.navigate([this.returnUrl]);
            this.newsService.checkNewNews();
          });
        } else {

        }
      });
    }

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Anmeldung");
  }


  onLogin() {
    this.modelService.loginService.login(this.creds, this.customerId ?? "").then(
      success => {
        if (success) {        

          this.modelService.router.navigate([this.returnUrl]);
          this.modelService.loginService.notifyTestAccount();
          this.newsService.checkNewNews();
        }
      },
      err => {
        let translation = "";
        this.translate.get('Fehler beim Login').subscribe((text: string) => {
          translation = text;
          this.modelService.systemService.notify(new Message(translation, "error"));
        });
      });
  }
}
