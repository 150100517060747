export class LegalCharacteristic {
  constructor(
    public isBiocidalProduct?: boolean,
    public isSelfServiceForbidden?: boolean,
    public isFertigpackv?: boolean,
    public isOekodesignEvpgEnvkg?: boolean,
    public isCommoditiesControll?: boolean,
    public isDetergenzienv?: boolean,
    public isKosmetikv?: boolean,
    public isCeGs?: boolean,
    public isWeeeRohsEar?: boolean,
    public isReach?: boolean,
    public isVerpackungsv?: boolean,
    public isSecurityDatasheetNeeded?: boolean,
    public isDualUse?: boolean,
    public isBatterieV?: boolean,
    public biocidalFeature?: string,
    public biocidalIngredient?: string,
    public weeeDeviceType?: string,
    public weeeNumber?: string,
    public manufacturer?: string,
    public contact?: string,
  ) {}
}
