import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { Contact } from '../../Model/Contact';
import { Message } from '../../Model/System/Message';
import { Credentials } from '../../Model/User/Credentials';
import { Customer } from '../../Model/User/Customer';
import { DscContact } from '../../Model/User/DscContact';
import { PimLicense } from '../../Model/User/PimLicense';
import { RegisterUser } from '../../Model/User/RegisterUser';
import { AddonService } from '../../Services/addon.service';
import { DscContactService } from '../../Services/dscContactService.service';
import { LoginService } from '../../Services/login.service';
import { SystemService } from '../../Services/system.service';

@Component({
  selector: 'np-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  loginUrl = "/login";
  contacts: DscContact[];
  licenses: string[] = Object.keys(PimLicense);
  possibleExportAddons: string[];
  possibleClassificationAddons: string[];
  registerUser: RegisterUser;
  logoUrl: string = "/images/gastropim.jpg";
  currentNavigationTitle = '';
  
  constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService,
    private systemService: SystemService, private loginService: LoginService, private addonService: AddonService,private dscContactService : DscContactService) {
    this.systemService.currentNavigationTitle = this.translate.instant("Anmeldung");
   
   
    this.initializeRegisterUser();
    this.initializeContact();
  }

  async ngOnInit() {
    //TODO might be added later
    /*this.possibleExportAddons = await lastValueFrom(this.addonService.getAllExportAddons());
    this.possibleClassificationAddons = await lastValueFrom(this.addonService.getAllClassificationAddons());*/
    const url = this.router.url.toLowerCase();
    if (url.includes('gastro')) {
      this.logoUrl = '/images/gastropim.jpg';
    }
  }

  initializeRegisterUser() {
    this.registerUser = new RegisterUser();
    this.registerUser.sharedValidationMethodId = this.route.snapshot.queryParamMap.get('validationMethodId');
    this.registerUser.linkedCustomerId = this.route.snapshot.queryParamMap.get('customerId');
    this.registerUser.sharedCatalogId = this.route.snapshot.queryParamMap.get('catalogId');

    //deny getting to the register page without an Invite
    if (!this.registerUser.linkedCustomerId) {
      this.systemService.notify(new Message(this.translate.instant('Registrierung nur mit einem Einladungslink möglich')));
      this.router.navigate([this.loginUrl]);
    }
  }

  async initializeContact() {
   this.contacts =  await this.dscContactService.getAll(this.route.snapshot.queryParamMap.get('customerId'));
  }

  passwordComparison = () => this.registerUser.password;

  onLicenseChanged() {
    this.registerUser.exportAddons = [];
    this.registerUser.classificationAddons = [];
  }

  onRegistration() {
    this.loginService.register(this.registerUser).then(x => {
      this.systemService.notify(new Message(this.translate.instant('Registrierung abgeschlossen')));
      this.router.navigate([this.loginUrl]);
    }).catch(x => {
      this.systemService.notify(new Message(this.translate.instant(x.error)))
    });
  }

  async onAddonChanged(event) {
    const errors = await this.addonService.checkCurrentAddons(this.registerUser.pimLicense, event.value);
    if (errors.length > 0) {
      this.systemService.notifyWarning(errors.join("\n"));
      event.component.option('value', event.previousValue);
    }
  }

  
}
