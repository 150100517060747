<div #content class="expand-content mb-2" [class.expanded]="isExpanded">
    <ng-content></ng-content>
</div>
<dx-button
  *ngIf="isOverflowing"
  stylingMode="text"
  type="default"
  text="{{ !isExpanded ? textShowMore : textShowLess }}"
  (click)="toggle()"
></dx-button>
