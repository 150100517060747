<div class="dx-fieldset">
  <div class="dx-field">
    <h4>{{ 'Gefahrenklasse' | translate }}</h4>
  </div>
  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Name' | translate }}</div>
    <div class="dx-field-value {{ loginService.showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label
        class="placeLabel {{ hazmatClassService.selectedClass.name?.length > 0 ? 'placeLabelSmall' : '' }}"
      >
        NAME
      </label>
      <dx-text-box
        [(value)]="hazmatClassService.selectedClass.name"
        [showClearButton]="true"
        (onValueChanged)="update($event)"
      ></dx-text-box>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label jobListSN">{{ 'Signalwort' | translate }}</div>
    <div class="dx-field-value {{ loginService.showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label
        [ngClass]="{
          placeLabel: true,
          placeLabelSmall: hazmatClassService.selectedClass.signalWord?.length > 0
        }"
      >
        SIGNALWORT
      </label>
      <np-dropdown-control
        [(model)]="hazmatClassService.selectedClass"
        [items]="hazmatClassService.signalWords"
        [value]="hazmatClassService.selectedClass.signalWord"
        translationLabelPrefix="SignalWord_"
        modelProp="signalWord"
        (onValueChanged)="update($event)"
      ></np-dropdown-control>
    </div>
  </div>
</div>

<dx-tab-panel [dataSource]="pages" [selectedIndex]="0" class="detailTabs">
  <div *dxTemplate="let piktgramm of 'piktoTemplate'">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-6">
          <div class="dx-fieldset">
            <div class="dx-field">
              <h4>{{ 'Mögliche Werte' | translate }}:</h4>
            </div>
            <div class="dx-field">
              <dx-list
                #list
                [dataSource]="hazmatClassService.hazardPictograms"
                [selectionMode]="multiple"
                pageLoadMode="scrollBottom"
                [searchEnabled]="true"
                [showSelectionControls]="true"
                (onSelectionChanged)="update($event)"
                [(selectedItems)]="hazmatClassService.selectedClass.hazardPictograms"
              >
                <dxo-search-editor-options placeholder="{{ 'Suchen' | translate }}"></dxo-search-editor-options>
                <div *dxTemplate="let item of 'item'">
                  {{ item }} &nbsp;
                  <img src="/images/ghs/{{ item }}.png" height="50px" />
                  &nbsp; {{ item | translate }}
                </div>
              </dx-list>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="dx-fieldset">
            <div class="dx-field">
              <h4>{{ 'Ausgewählt' | translate }}:</h4>
            </div>
            <div class="dx-field">
              <dx-list
                #list
                [dataSource]="hazmatClassService.selectedClass.hazardPictograms"
                [selectionMode]="multiple"
                pageLoadMode="scrollBottom"
                noDataText="{{ 'kein Element ausgewählt' | translate }}"
                [searchEnabled]="false"
                [showSelectionControls]="false"
              >
                <div *dxTemplate="let item of 'item'">
                  {{ item }} &nbsp;
                  <img src="/images/ghs/{{ item }}.png" height="50px" />
                  &nbsp; {{ item | translate }}
                </div>
              </dx-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *dxTemplate="let company of 'hTemplate'">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-6">
          <div class="dx-fieldset">
            <div class="dx-field">
              <h4>{{ 'Mögliche Werte' | translate }}:</h4>
            </div>
            <div class="dx-field">
              <dx-list
                #list
                [dataSource]="hazmatClassService.hStatements"
                [selectionMode]="multiple"
                pageLoadMode="scrollBottom"
                [searchEnabled]="true"
                [showSelectionControls]="true"
                (onSelectionChanged)="update($event)"
                [(selectedItems)]="hazmatClassService.selectedClass.hazardStatements"
              >
                <dxo-search-editor-options placeholder="{{ 'Suchen' | translate }}"></dxo-search-editor-options>
                <div *dxTemplate="let item of 'item'">{{ item }} {{ item | translate }}</div>
              </dx-list>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="dx-fieldset">
            <div class="dx-field">
              <h4>{{ 'Ausgewählt' | translate }}:</h4>
            </div>
            <div class="dx-field">
              <dx-list
                #list
                [dataSource]="hazmatClassService.selectedClass.hazardStatements"
                [selectionMode]="multiple"
                pageLoadMode="scrollBottom"
                noDataText="{{ 'kein Element ausgewählt' | translate }}"
                [searchEnabled]="false"
                [showSelectionControls]="false"
              >
                <div *dxTemplate="let item of 'item'">{{ item }} {{ item | translate }}</div>
              </dx-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *dxTemplate="let company of 'pTemplate'">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-6">
          <div class="dx-fieldset">
            <div class="dx-field">
              <h4>{{ 'Mögliche Werte' | translate }}:</h4>
            </div>
            <div class="dx-field">
              <dx-list
                #list
                [dataSource]="hazmatClassService.pStatements"
                [selectionMode]="multiple"
                pageLoadMode="scrollBottom"
                [searchEnabled]="true"
                [showSelectionControls]="true"
                (onSelectionChanged)="update($event)"
                [(selectedItems)]="hazmatClassService.selectedClass.precautionaryStatements"
              >
                <dxo-search-editor-options placeholder="{{ 'Suchen' | translate }}"></dxo-search-editor-options>
                <div *dxTemplate="let item of 'item'">{{ item }} {{ item | translate }}</div>
              </dx-list>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="dx-fieldset">
            <div class="dx-field">
              <h4>{{ 'Ausgewählt' | translate }}:</h4>
            </div>
            <div class="dx-field">
              <dx-list
                #list
                [dataSource]="hazmatClassService.selectedClass.precautionaryStatements"
                [selectionMode]="multiple"
                pageLoadMode="scrollBottom"
                noDataText="{{ 'kein Element ausgewählt' | translate }}"
                [searchEnabled]="false"
                [showSelectionControls]="false"
              >
                <div *dxTemplate="let item of 'item'">{{ item }} {{ item | translate }}</div>
              </dx-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-tab-panel>
