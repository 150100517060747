import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CatalogService } from 'app/Services/CatalogManagement/catalog.service';

@Component({
  selector: 'useraction-convert-orderdetails-to-supplierdetails',
  templateUrl: './useraction-convert-orderdetails-to-supplierdetails.component.html',  
  styleUrl: "./useraction-convert-orderdetails-to-supplierdetails.component.css"
})
export class UseractionConvertOrderdetailsToSupplierdetailsComponent implements OnInit {
  @Output() userActionToggleStartButton = new EventEmitter<boolean>();
  noCatalogSupplier: boolean = false;  
  
  constructor(
    private catalogService: CatalogService,) {   
      this.noCatalogSupplier = this.catalogService.catalog.supplier.supplierName == ""; 
      this.userActionToggleStartButton.emit(!this.noCatalogSupplier);
  }

  ngOnInit(): void {    
    this.userActionToggleStartButton.emit(!this.noCatalogSupplier);
  }

}
