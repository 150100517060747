<dx-drop-down-box
  style="width: 450px;"
  class="np-highlight"
  [showClearButton]="true"
  [(value)]="pimField.field"
  [dataSource]="fieldList"
  [displayExpr]="getFieldDisplayExpr"
  valueExpr="key"
  label="{{ 'Feld' | translate }}"
  placeholder="{{ 'Feld auswählen' | translate }}"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="fieldList"
      searchExpr="text"
      [displayExpr]="getFieldDisplayExpr"
      valueExpr="key"
      selectionMode="single"
      (onItemClick)="onFieldItemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<dx-drop-down-box
  class="flex-grow-1 np-highlight"
  [showClearButton]="true"
  [acceptCustomValue]="false"
  [(value)]="pimField.systemKey"
  [dataSource]="validFeatureSystems"
  displayExpr="name"
  valueExpr="id"
  label="{{ 'Featuresystem' | translate }}"
  placeholder="{{ 'FeatureSystem auswählen' | translate }}"
  (onValueChanged)="onValidFeatureSystemChanged($event)"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="validFeatureSystems"
      searchExpr="name"
      displayExpr="name"
      valueExpr="id"
      selectionMode="single"
      (onItemClick)="onValidFeatureSystemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<dx-switch *ngIf="!isFeatureSystemOnly && selectedValidFeatureSystem?.id"
           style="margin-left: 25px; align-self: center;"
           [(value)]="pimField.useSequence"
           (onValueChanged)="update()">
</dx-switch>

<dx-drop-down-box
  *ngIf="!isFeatureSystemOnly && selectedValidFeatureSystem?.id && !pimField.useSequence"
  class="flex-grow-1 np-highlight"
  [showClearButton]="true"
  [acceptCustomValue]="false"
  [(value)]="pimField.elementKey"
  [dataSource]="validFeatures"
  [displayExpr]="getValidFeatureDisplayExpr"
  valueExpr="id"
  label="{{ 'Feature' | translate }}"
  placeholder="{{ 'Features auswählen' | translate }}"
  (onValueChanged)="onValidFeatureChanged($event)"
>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="validFeatures"
      searchExpr="name"
      [displayExpr]="getValidFeatureDisplayExpr"
      valueExpr="id"
      selectionMode="single"
      (onItemClick)="onValidFeatureClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>

<dx-number-box *ngIf="!isFeatureSystemOnly && selectedValidFeatureSystem?.id && pimField.useSequence"
               class="flex-grow-1 np-highlight"
               min="1"
               label="{{ (pimField.useSequence ? 'Reihenfolgennummer' : 'Sortiernummer') | translate }}"
               [(value)]="pimField.elementKey"
               (onValueChanged)="onOrderChanged($event)">
</dx-number-box>
