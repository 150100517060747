
<dx-toolbar>
  
  <dxi-item  location="after" widget="dxButton" [options]="{ type: 'default', icon:'plus',   text:  'Ansprechpartner hinzufügen' | translate , onClick: addContact }"></dxi-item>
</dx-toolbar>
<dx-accordion [dataSource]="contacts"
              [collapsible]="false"
              [multiple]="false"
              [animationDuration]="300"
              [selectedIndex]="[selectedIndex]">
  <div *dxTemplate="let contact of 'title'">
    <div class="header">{{ contact.contactName }}</div>
  </div>
  <div *dxTemplate="let contact of 'item'">
    <div class="d-flex flex-row">
      <div class="flex-fill pr-3 w-50">
        <div class="dx-fieldset" *ngIf="model">
          <div class="dx-fieldset-header">{{ 'Ihr Ansprechpartner' | translate }}</div>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'Ansprechpartner' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="contact.contactName" [showClearButton]="true" (onValueChanged)="updateContact(contact)"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">{{ 'Email' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="contact.contactEmail" [showClearButton]="true" (onValueChanged)="updateContact(contact)">
                <dx-validator>

                  <dxi-validation-rule type="email"
                                       message="{{ 'Bitte geben Sie eine gültige Email-Adresse ein' | translate }}"></dxi-validation-rule>

                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'Telefon' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="contact.contactPhone" [showClearButton]="true" (onValueChanged)="updateContact(contact)"></dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'Domain' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="contact.domain" [showClearButton]="true" (onValueChanged)="updateContact(contact)"></dx-text-box>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-fill pl-3 w-50">
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">{{ 'Ihr Logo' | translate }}</div>
          <div style="height: 150px">
            <span *ngIf="!contact.logoUrl" class="glyphicon glyphicon-picture" style="font-size: 100px;"></span>
            <img *ngIf="contact.logoUrl" src="{{contact.logoUrl}}" style="height: 100%;" />
          </div>
          <div>
            <dx-file-uploader uploadUrl="api/Import/ImportCustomerLogo"
                              [uploadHeaders]="uploadHeaders"
                              [multiple]="false"
                              accept="*"
                              (onUploaded)="onUploaded($event)"
                              (onBeforeSend)="onBeforeSendUpload($event,contact.id)"
                              [(value)]="value"
                              uploadMode="instantly">
            </dx-file-uploader>
            <b>{{ msg }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-accordion>





<div class="d-flex flex-row">
  <div class="flex-fill pr-3 w-50">
    <div class="dx-fieldset" *ngIf="model">

      <div class="dx-field">
        <div class="dx-field-label">{{ 'Datenqualitäts-Set' | translate }}</div>
        <div class="dx-field-value">
          <div class="d-flex flex-row">
            <dx-select-box [items]="shareableValidationMethods"
                           displayExpr="name"
                           valueExpr="id"
                           [(value)]="model.sharedValidationMethodId"
                           (onValueChanged)="update()">
            </dx-select-box>
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">{{ 'Mindestniveau' | translate }}</div>
        <div class="dx-field-value">
          <div class="d-flex flex-row">
            <dx-select-box id="minimumValidationGroup" [items]="shareableValidationGroups"
                           displayExpr="name"
                           valueExpr="id"
                           [(value)]="model.minimumValidationGroup"
                           (onValueChanged)="update()">
            </dx-select-box>
            <dx-tooltip
              target="#minimumValidationGroup"
              showEvent="mouseenter"
              hideEvent="mouseleave"
              [hideOnOutsideClick]="false"
            >
              {{ 'Das ausgewählte Niveau muss von allen Artikeln erfüllt werden, damit ein Katalog an Sie gesendet werden kann.' | translate }}
            </dx-tooltip>
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div id="demoCatalogChooser" class="dx-field-label">{{ 'Demo-Katalog' | translate }}</div>
        <dx-tooltip target="#demoCatalogChooser" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
          <div *dxTemplate="let data = data; of: 'content'">{{ 'dataSharingDemoCatalogTooltip' | translate }}</div>
        </dx-tooltip>
        <div class="dx-field-value">
          <div class="d-flex flex-row">
            <dx-select-box [items]="shareableCatalogs"
                           displayExpr="catalogName"
                           valueExpr="catalogId"
                           [(value)]="model.sharedCatalogId"
                           (onValueChanged)="update()"
                           [searchEnabled]="true">
            </dx-select-box>
          </div>
        </div>
      </div>


      <div class="dx-field">
        <div class="dx-field-label">{{ 'Registrierungslink' | translate }}</div>
        <div class="dx-field-value">
          <div class="d-flex flex-row w-100">
            <dx-text-box style="width: -webkit-fill-available;"
                         [value]="generatedRegistrationLink"
                         [readOnly]="true">
              <dxi-button location="after"
                          name="generateRegistrationLinkButton"
                          [options]="generateRegistrationLinkButtonOptions">
              </dxi-button>
            </dx-text-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





