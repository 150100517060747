import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ModelService } from '../../../Services/model.service';
import { StartAction } from '../../../Model/Dto/StartAction';
import { Message } from '../../../Model/System/Message';
import { TranslateService } from '@ngx-translate/core';
import { UserActionBaseComponent } from './userAction-base.component';
import { Card } from '../../../Model/ui/Card';
import { Macro } from '../../../Model/ui/Macro';
import { USERACTIONVIEWDETAILS } from '../../../Services/userActionView.service';
import { PimLicense } from '../../../Model/User/PimLicense';

@Component({
  selector: 'userAction',
  templateUrl: 'userAction.component.html',
  styleUrls: ['./userAction.component.css']
})
export class UserActionComponent implements OnInit {
  cards: Card[];
  macros: Macro[];

  _macroAccordionSelectedItem: any;

  currentSelectedUserActionComponent: UserActionBaseComponent;

  public macro: number = 0;
  public isLoading: boolean = false;
  public isDelete: boolean = false;
  public isFeatureSystemMakro: boolean = false;
  startMacroButtonEnabled = true;

  constructor(private modelService: ModelService, public translate: TranslateService) {}

  ngOnInit(): void {
    this.onToggleStartMacroButton = this.onToggleStartMacroButton.bind(this);
    this.initRepository();

    this.translateTexts();
    this.setCards();
    this.setMacros();
    this.resetView();
  }

  onToggleStartMacroButton(enable: boolean) {
    this.startMacroButtonEnabled = enable;
  }

  txtKatalog: string = 'Katalog';
  txtKategorie: string;
  txtProdukte: string;
  txtMedien: string;
  txtFeatures: string;
  txtShop: string;
  txtUebersetzung: string;
  txtTroubleShooting: string;
  txtMakroGestarted: string;
  txtDeleteWarning: string;
  txtVarianten: string;
  txtKIText: string;
  txtRemovePathFromMimeSourceShort: string;

  txtRemoveMimeWithoutImagePathShort: string;
  txtReorderMimeShort: string;
  txtRemoveAllFeaturesShort: string;
  txtRemoveAllMimesShort: string;
  txtRemoveAllOrderDetailsShort: string;
  txtRemoveAllPricesShort: string;
  txtRemoveAllUDXShort: string;
  txtRemoveAllLogisticDetailsShort: string;
  txtRemoveEmptyCategoriesShort: string;
  txtResizeImagesShort: string;
  txtCheckCatalogShort: string;
  txtDeleteByListShort: string;
  txtSearchByListShort: string;
  txtUpdateFromCatalogShort: string;
  txtUpdateToCatalogShort: string;
  txtUpdateToCatalogLong: string;
  txtDeleteAllMediaShort: string;
  txtCompareCatalogShort: string;
  txtDeleteCategoryImagesShort: string;
  txtDeleteUnUsedMimesShort: string;
  txtAssignCategoryImagesShort: string;
  txtAddEcommerceFeaturesShort: string;
  txtAddEcommerceFeaturesLong: string;

  txtCacheThumbnailsShort: string;
  txtRemoveMasterShort: string;
  txtFixStatisticsShort: string;
  txtTranslateMimeSourcesShort: string;
  txtTranslateNumbersShort: string;
  txtDetectMimeTypeShort: string;
  txtRebuildThumbsShort: string;
  txtRenameFeatureSystemShort: string;
  txtRenameFeaturesShort: string;
  txtSplitFeatureValuesFromUnitShort: string;
  txtRemoveDuplicateImagesShort: string;
  txtDownloadMimeSourcesShort: string;
  txtDeleteEmptyFeaturesShort: string;
  txtEanSupplierAltPidSwitchShort: string;
  txtAssignMasterImagesShort: string;
  txtRemovePathFromMimeSourcelong: string;

  txtRemoveMimeWithoutImagePathlong: string;
  txtReorderMimelong: string;
  txtRemoveAllFeatureslong: string;
  txtRemoveAllMimeslong: string;
  txtRemoveAllOrderDetailslong: string;
  txtRemoveAllPriceslong: string;
  txtRemoveAllUDXlong: string;
  txtRemoveAllLogisticDetailslong: string;
  txtRemoveEmptyCategorieslong: string;
  txtResizeImageslong: string;
  txtCheckCataloglong: string;
  txtDeleteByListlong: string;
  txtSearchByListlong: string;
  txtUpdateFromCataloglong: string;
  txtCompareCatalogLong: string;
  txtDeleteCategoryImageslong: string;
  txtDeleteUnUsedMimeslong: string;
  txtAssignCategoryImageslong: string;
  txtDeleteAllMedialong: string;
  txtCacheThumbnailslong: string;
  txtRemoveMasterlong: string;
  txtFixStatisticslong: string;
  txtTranslateMimeSourceslong: string;
  txtTranslateNumberslong: string;
  txtDetectMimeTypelong: string;
  txtRebuildThumbslong: string;
  txtRenameFeatureSystemLong: string;
  txtRenameFeaturesLong: string;
  txtSplitFeatureValuesFromUnitLong: string;
  txtRemoveDuplicateImageslong: string;
  txtDownloadMimeSourceslong: string;
  txtDeleteEmptyFeatureslong: string;
  txtEanSupplierAltPidSwitchlong: string;
  txtAssignMasterImageslong: string;
  txtReportMissingMedialong: string;
  txtReportMissingMediaShort: string;
  txtCopyMediaFromOtherCatalogLong: string;
  txtCopyMediaFromOtherCatalogShort: string;
  txtDeleteFeatureByNameShort: string;
  txtDeleteFeatureByNameLong: string;
  txtTruncateMimeDescriptionsShort: string;
  txtTruncateMimeDescriptionsLong: string;
  txtInternationalPidTypEanToGtinShort: string;
  txtInternationalPidTypEanToGtinLong: string;
  txtSetMimePurposeShort: string;
  txtSetMimePurposeLong: string;
  txtSetIsBulkyShort: string;
  txtSetIsBulkyLong: string;
  txtCreateTechnicalDetailsShort: string;
  txtCreateTechnicalDetailsLong: string;
  txtRemoveRootCategoryShort: string;
  txtRemoveRootCategoryLong: string;
  txtSetManufacturerAidFromSupplierPidShort: string;
  txtSetManufacturerAidFromSupplierPidLong: string;
  txtRemoveMimesWithoutMimeSourceShort: string;
  txtRemoveMimesWithoutMimeSourceLong: string;
  txtReorderCategoriesShort: string;
  txtReorderCategoriesLong: string;
  txtMasterToCategoryShort: string;
  txtMasterToCategoryLong: string;

  txtSpreadCatalogShort: string;
  txtSpreadCatalogLong: string;
  txtSetMavisTransferDateShort: string;
  txtSetMavisTransferDateLong: string;

  txtSetBmeCatDefaultsShort: string;
  txtSetBmeCatDefaultsLong: string;
  txtHarmonizeFeatureSystemsShort: string;
  txtHarmonizeFeatureSystemsLong: string;

  txtFixClassificationShort: string;
  txtFixClassificationLong: string;

  txtCreateUniqueGroupIdLong: string;
  txtCreateUniqueGroupIdShort: string;

  txtDeleteDuplicateFeaturesLong: string;
  txtDeleteDuplicateFeaturesShort: string;

  txtDeleteDuplicateMimesLong: string;
  txtDeleteDuplicateMimesShort: string;

  txtExportReportProductDatasheetLong: string;
  txtExportReportProductDatasheetShort: string;

  txtRemoveEmptyUDXLong: string;
  txtRemoveEmptyUDXShort: string;

  txtRemoveDeadReferencesLong: string;
  txtRemoveDeadReferencesShort: string;

  txtRemoveUDXOnProductsLong: string;
  txtRemoveUDXOnProductsShort: string;

  txtCreateLinkedFieldsOnProductsShort: string;
  txtCreateLinkedFieldsOnProductsLong: string;

  txtCreateVariantsFeatureSystemShort: string;
  txtCreateVariantsFeatureSystemLong: string;

  txtDeleteMediaByPurposeShort: string;
  txtDeleteMediaByPurposeLong: string;

  txtConsolidateDubSupplierPidsLong: string;
  txtConsolidateDubSupplierPidsShort: string;

  txtHarmonizeFeaturesByNameLong: string;
  txtHarmonizeFeaturesByNameShort: string;

  txtFlattenCategoryTreeShort: string;
  txtFlattenCategoryTreeLong: string;

  txtSetWawiLinesShort: string;
  txtSetWawiLinesLong: string;

  txtSetWawiSupplierPidsShort: string;
  txtSetWawiSupplierPidsLong: string;

  txtRemoveSpecialCharactersShort: string;
  txtRemoveSpecialCharactersLong: string;

  txtVariantResolveShort: string;
  txtVariantResolveLong: string;

  txtCreateEnventaMimeShort: string;
  txtCreateEnventaMimeLong: string;

  txtGenerateKITextsShort: string;
  txtGenerateKITextsLong: string;

  txtCategoriesToMastersTextsShort: string;
  txtCategoriesToMastersTextsLong: string;

  txtMarkAsChangedTextsShort: string;
  txtMarkAsChangedTextsLong: string;

  txtGenerateSortNumberTextLong: string;
  txtGenerateSortNumberTextShort: string;

  txtGenerateMasterFeaturesFromVariantsTextsShortTextShort: string;
  txtGenerateMasterFeaturesFromVariantsTextsShortTextLong: string;

  txtChangeMimePurposesTextShort: string;
  txtChangeMimePurposesTextLong: string;

  get macroAccordionSelectedItem(): any {
    return this._macroAccordionSelectedItem;
  }

  set macroAccordionSelectedItem(macroAccordionSelectedItem: any) {
    this._macroAccordionSelectedItem = macroAccordionSelectedItem;
  }

  translateTexts() {
    this.txtDeleteWarning = this.translate.instant(
      'ACHTUNG: Diese Makro löscht Daten unwiderruflich. Bitte erstellen Sie ggf. einen Wiederherstellungspunkt.'
    );

    this.txtVarianten = this.translate.instant('Varianten');

    this.txtCreateEnventaMimeShort = this.translate.instant('CreateEnventaMimeShort');

    this.txtCreateEnventaMimeLong = this.translate.instant('CreateEnventaMimeLong');

    this.txtVariantResolveShort = this.translate.instant('variantsResolveShort');

    this.txtVariantResolveLong = this.translate.instant('variantsResolveLong');

    // 74 HarmonizeFeaturesByName
    this.txtHarmonizeFeaturesByNameShort = this.translate.instant('HarmonizeFeaturesByNameShort');
    this.txtHarmonizeFeaturesByNameLong = this.translate.instant('HarmonizeFeaturesByNameLong');

    // 77 SetWawiLines

    this.txtSetWawiLinesShort = this.translate.instant('SetWawiLinesShort');
    this.txtSetWawiLinesLong = this.translate.instant('SetWawiLinesLong');

    // 78 SetWawiSupplierPids

    this.txtSetWawiSupplierPidsShort = this.translate.instant('SetWawiSupplierPidsShort');
    this.txtSetWawiSupplierPidsLong = this.translate.instant('SetWawiSupplierPidsLong');

    //79 RemoveSpecialCharacters

    this.txtRemoveSpecialCharactersShort = this.translate.instant('RemoveSpecialCharactersShort');
    this.txtRemoveSpecialCharactersLong = this.translate.instant('RemoveSpecialCharacters');

    // 73 ConsolidateDubSupplierPids
    this.txtConsolidateDubSupplierPidsShort = this.translate.instant('ConsolidateDubSupplierPidsShort');
    this.txtConsolidateDubSupplierPidsLong = this.translate.instant('ConsolidateDubSupplierPidsLong');

    // 63 DeleteDuplicateFeatures
    this.txtDeleteDuplicateFeaturesShort = this.translate.instant('DeleteDuplicateFeaturesShort');
    this.txtDeleteDuplicateFeaturesLong = this.translate.instant('DeleteDuplicateFeaturesLong');

    // 71 CreateVariantsFeatureSystem
    this.txtCreateVariantsFeatureSystemShort = this.translate.instant('CreateVariantsFeatureSystemShort');
    this.txtCreateVariantsFeatureSystemLong = this.translate.instant('CreateVariantsFeatureSystemLong');

    // 64  DeleteDuplicateMimes
    this.txtDeleteDuplicateMimesShort = this.translate.instant('DeleteDuplicateMimesShort');
    this.txtDeleteDuplicateMimesLong = this.translate.instant('DeleteDuplicateMimesLong');

    // 58
    this.txtHarmonizeFeatureSystemsShort = this.translate.instant('SetHarmonizeFeatureSystemsShort');
    this.txtHarmonizeFeatureSystemsLong = this.translate.instant('SetHarmonizeFeatureSystemsLong');

    // CreateUniqueGroupId 61
    this.txtCreateUniqueGroupIdShort = this.translate.instant('CreateUniqueGroupIdShort');
    this.txtCreateUniqueGroupIdLong = this.translate.instant('CreateUniqueGroupIdLong');

    // 60
    this.txtFixClassificationShort = this.translate.instant('FixClassificationShort');
    this.txtFixClassificationLong = this.translate.instant('FixClassificationLong');

    // 56
    this.txtSetMavisTransferDateShort = this.translate.instant('SetMavisTransferDateShort');
    this.txtSetMavisTransferDateLong = this.translate.instant('SetMavisTransferDateLong');
    // 57
    this.txtSetBmeCatDefaultsShort = this.translate.instant('SetBmeCatDefaultsShort');
    this.txtSetBmeCatDefaultsLong = this.translate.instant('SetBmeCatDefaultsLong');

    this.txtRemoveRootCategoryShort = this.translate.instant('RemoveRootCategoryShort');
    this.txtRemoveRootCategoryLong = this.translate.instant('RemoveRootCategoryLong');

    this.txtSpreadCatalogShort = this.translate.instant('SpreadCatalogShort');
    this.txtSpreadCatalogLong = this.translate.instant('SpreadCatalogLong');

    this.txtCreateTechnicalDetailsShort = this.translate.instant('CreateTechnicalDetailsShort');
    this.txtCreateTechnicalDetailsLong = this.translate.instant('CreateTechnicalDetailsLong');

    this.txtRemoveMimesWithoutMimeSourceShort = this.translate.instant('RemoveMimesWithoutMimeSourceShort');
    this.txtRemoveMimesWithoutMimeSourceLong = this.translate.instant('RemoveMimesWithoutMimeSourceLong');

    this.txtKatalog = this.translate.instant('Katalog');
    this.txtTroubleShooting = this.translate.instant('Troubleshooting');

    this.txtMakroGestarted = this.translate.instant('Das Makro wurde gestartet');
    this.txtKategorie = this.translate.instant('Kategorie');
    this.txtProdukte = this.translate.instant('Produkte');
    this.txtMedien = this.translate.instant('Medien');
    this.txtFeatures = this.translate.instant('Features');
    this.txtShop = this.translate.instant('Shop');
    this.txtUebersetzung = this.translate.instant('Übersetzung');
    this.txtRemovePathFromMimeSourcelong = this.translate.instant('RemovePathFromMimeSourcelong');

    this.txtRemoveMimeWithoutImagePathlong = this.translate.instant('RemoveMimeWithoutImagePathlong');
    this.txtReorderMimelong = this.translate.instant('ReorderMimelong');
    this.txtRemoveAllFeatureslong = this.translate.instant('RemoveAllFeatureslong');
    this.txtRemoveAllMimeslong = this.translate.instant('RemoveAllMimeslong');
    this.txtRemoveAllOrderDetailslong = this.translate.instant('RemoveAllOrderDetailslong');
    this.txtRemoveAllPriceslong = this.translate.instant('RemoveAllPriceslong');
    this.txtRemoveAllUDXlong = this.translate.instant('RemoveAllUDXlong');
    this.txtRemoveAllLogisticDetailslong = this.translate.instant('RemoveAllLogisticDetailslong');
    this.txtRemoveEmptyCategorieslong = this.translate.instant('RemoveEmptyCategorieslong');
    this.txtResizeImageslong = this.translate.instant('ResizeImageslong');
    this.txtCheckCataloglong = this.translate.instant('CheckCataloglong');
    this.txtDeleteByListlong = this.translate.instant('DeleteByListlong');
    this.txtSearchByListlong = this.translate.instant('SearchByListlong');
    this.txtUpdateFromCataloglong = this.translate.instant('UpdateFromCataloglong');
    this.txtUpdateToCatalogLong = this.translate.instant('UpdateToCatalogLong');

    this.txtCompareCatalogLong = this.translate.instant('CompareCatalogLong');
    this.txtDeleteCategoryImageslong = this.translate.instant('DeleteCategoryImageslong');
    this.txtDeleteUnUsedMimeslong = this.translate.instant('DeleteUnUsedMimeslong');
    this.txtAssignCategoryImageslong = this.translate.instant('AssignCategoryImageslong');
    this.txtDeleteAllMedialong = this.translate.instant('DeleteAllMedialong');
    this.txtCacheThumbnailslong = this.translate.instant('CacheThumbnailslong');
    this.txtRemoveMasterlong = this.translate.instant('RemoveMasterlong');
    this.txtFixStatisticslong = this.translate.instant('FixStatisticslong');
    this.txtTranslateMimeSourceslong = this.translate.instant('TranslateMimeSourceslong');
    this.txtTranslateNumberslong = this.translate.instant('TranslateNumberslong');
    this.txtDetectMimeTypelong = this.translate.instant('DetectMimeTypelong');
    this.txtRebuildThumbslong = this.translate.instant('RebuildThumbslong');
    this.txtRenameFeatureSystemLong = this.translate.instant('RenameFeatureSystemLong');
    this.txtRenameFeaturesLong = this.translate.instant('RenameFeaturesLong');
    this.txtSplitFeatureValuesFromUnitLong = this.translate.instant('SplitFeatureValuesFromUnitLong');
    this.txtRemoveDuplicateImageslong = this.translate.instant('RemoveDuplicateImageslong');
    this.txtDownloadMimeSourceslong = this.translate.instant('DownloadMimeSourceslong');
    this.txtDeleteEmptyFeatureslong = this.translate.instant('DeleteEmptyFeatureslong');
    this.txtEanSupplierAltPidSwitchlong = this.translate.instant('EanSupplierAltPidSwitchlong');
    this.txtAssignMasterImageslong = this.translate.instant('AssignMasterImageslong');
    this.txtRemovePathFromMimeSourceShort = this.translate.instant('RemovePathFromMimeSourceShort');

    this.txtRemoveMimeWithoutImagePathShort = this.translate.instant('RemoveMimeWithoutImagePathShort');
    this.txtReorderMimeShort = this.translate.instant('ReorderMimeShort');
    this.txtRemoveAllFeaturesShort = this.translate.instant('RemoveAllFeaturesShort');
    this.txtRemoveAllMimesShort = this.translate.instant('RemoveAllMimesShort');
    this.txtRemoveAllOrderDetailsShort = this.translate.instant('RemoveAllOrderDetailsShort');
    this.txtRemoveAllPricesShort = this.translate.instant('RemoveAllPricesShort');
    this.txtRemoveAllUDXShort = this.translate.instant('RemoveAllUDXShort');
    this.txtRemoveAllLogisticDetailsShort = this.translate.instant('RemoveAllLogisticDetailsShort');
    this.txtRemoveEmptyCategoriesShort = this.translate.instant('RemoveEmptyCategoriesShort');
    this.txtResizeImagesShort = this.translate.instant('ResizeImagesShort');
    this.txtCheckCatalogShort = this.translate.instant('CheckCatalogShort');
    this.txtDeleteByListShort = this.translate.instant('DeleteByListShort');
    this.txtSearchByListShort = this.translate.instant('SearchByListShort');
    this.txtUpdateFromCatalogShort = this.translate.instant('UpdateFromCatalogShort');
    this.txtUpdateToCatalogShort = this.translate.instant('UpdateToCatalogShort');
    this.txtCompareCatalogShort = this.translate.instant('CompareCatalogShort');
    this.txtDeleteCategoryImagesShort = this.translate.instant('DeleteCategoryImagesShort');
    this.txtDeleteUnUsedMimesShort = this.translate.instant('DeleteUnUsedMimesShort');
    this.txtAssignCategoryImagesShort = this.translate.instant('AssignCategoryImagesShort');
    this.txtDeleteAllMediaShort = this.translate.instant('DeleteAllMediaShort');
    this.txtCacheThumbnailsShort = this.translate.instant('CacheThumbnailsShort');
    this.txtRemoveMasterShort = this.translate.instant('RemoveMasterShort');
    this.txtFixStatisticsShort = this.translate.instant('FixStatisticsShort');
    this.txtTranslateMimeSourcesShort = this.translate.instant('TranslateMimeSourcesShort');
    this.txtTranslateNumbersShort = this.translate.instant('TranslateNumbersShort');
    this.txtDetectMimeTypeShort = this.translate.instant('DetectMimeTypeShort');
    this.txtRebuildThumbsShort = this.translate.instant('RebuildThumbsShort');
    this.txtRenameFeatureSystemShort = this.translate.instant('RenameFeatureSystemShort');
    this.txtRenameFeaturesShort = this.translate.instant('RenameFeaturesShort');
    this.txtSplitFeatureValuesFromUnitShort = this.translate.instant('SplitFeatureValuesFromUnitShort');
    this.txtRemoveDuplicateImagesShort = this.translate.instant('RemoveDuplicateImagesShort');
    this.txtDownloadMimeSourcesShort = this.translate.instant('DownloadMimeSourcesShort');
    this.txtDeleteEmptyFeaturesShort = this.translate.instant('DeleteEmptyFeaturesShort');
    this.txtEanSupplierAltPidSwitchShort = this.translate.instant('EanSupplierAltPidSwitchShort');
    this.txtAssignMasterImagesShort = this.translate.instant('AssignMasterImagesShort');
    this.txtReportMissingMedialong = this.translate.instant('ReportMissingMedialong');
    this.txtReportMissingMediaShort = this.translate.instant('ReportMissingMediaShort');
    // 39
    this.txtCopyMediaFromOtherCatalogShort = this.translate.instant('CopyMediaFromOtherCatalogShort');
    this.txtCopyMediaFromOtherCatalogLong = this.translate.instant('CopyMediaFromOtherCatalogLong');

    //40
    this.txtDeleteFeatureByNameShort = this.translate.instant('DeleteFeatureByNameShort');
    this.txtDeleteFeatureByNameLong = this.translate.instant('DeleteFeatureByNameLong');
    //41
    this.txtAddEcommerceFeaturesShort = this.translate.instant('AddEcommerceFeaturesShort');

    this.txtAddEcommerceFeaturesLong = this.translate.instant('AddEcommerceFeaturesLong');
    //42
    this.txtTruncateMimeDescriptionsShort = this.translate.instant('TruncateMimeDescriptionsShort');

    this.txtTruncateMimeDescriptionsLong = this.translate.instant('TruncateMimeDescriptionsLong');

    // 43
    this.txtInternationalPidTypEanToGtinLong = this.translate.instant('InternationalPidTypEanToGtinLong');
    this.txtInternationalPidTypEanToGtinShort = this.translate.instant('InternationalPidTypEanToGtinShort');
    // 44
    this.txtSetMimePurposeShort = this.translate.instant('SetMimePurposeShort');
    this.txtSetMimePurposeLong = this.translate.instant('SetMimePurposeLong');
    // 46

    this.txtSetIsBulkyShort = this.translate.instant('SetIsBulkyShort');
    this.txtSetIsBulkyLong = this.translate.instant('SetIsBulkyLong');

    // 49

    this.txtSetManufacturerAidFromSupplierPidShort = this.translate.instant('SetManufacturerAidFromSupplierPidShort');
    this.txtSetManufacturerAidFromSupplierPidLong = this.translate.instant('SetManufacturerAidFromSupplierPidLong');

    // 52

    this.txtReorderCategoriesShort = this.translate.instant('ReorderCategoriesShort');
    this.txtReorderCategoriesLong = this.translate.instant('ReorderCategoriesLong');

    // 53

    this.txtMasterToCategoryShort = this.translate.instant('MasterToCategoryShort');
    this.txtMasterToCategoryLong = this.translate.instant('MasterToCategoryLong');

    // 62
    this.txtExportReportProductDatasheetShort = this.translate.instant('ReportProductDatasheetShort');
    this.txtExportReportProductDatasheetLong = this.translate.instant('ReportProductDatasheetLong');

    // 65, // Category
    // 66, // Product
    this.txtRemoveEmptyUDXShort = this.translate.instant('RemoveEmptyUDXShort');
    this.txtRemoveEmptyUDXLong = this.translate.instant('RemoveEmptyUDXLong');

    // 67
    this.txtRemoveDeadReferencesShort = this.translate.instant('RemoveDeadReferencesShort');
    this.txtRemoveDeadReferencesLong = this.translate.instant('RemoveDeadReferencesLong');

    // 68
    this.txtRemoveUDXOnProductsShort = this.translate.instant('RemoveUDXOnProductsShort');
    this.txtRemoveUDXOnProductsLong = this.translate.instant('RemoveUDXOnProductsLong');

    // 69
    this.txtCreateLinkedFieldsOnProductsShort = this.translate.instant('CreateLinkedFieldsForCatalogShort');
    this.txtCreateLinkedFieldsOnProductsLong = this.translate.instant('CreateLinkedFieldsForCatalogLong');

    // 71
    this.txtDeleteMediaByPurposeShort = this.translate.instant('DeleteMediaByPurposeShort');
    this.txtDeleteMediaByPurposeLong = this.translate.instant('DeleteMediaByPurposeLong');

    // 76
    this.txtFlattenCategoryTreeShort = this.translate.instant('FlattenCategoryTreeShort');
    this.txtFlattenCategoryTreeLong = this.translate.instant('FlattenCategoryTreeLong');

    this.txtKIText = this.translate.instant('Description Wizard');
    this.txtGenerateKITextsShort = this.translate.instant('GenerateKITextsShort');
    this.txtGenerateKITextsLong = this.translate.instant('GenerateKITextsLong');

    // 86
    this.txtCategoriesToMastersTextsLong = this.translate.instant('CategoriesToMastersTextsLong');
    this.txtCategoriesToMastersTextsShort = this.translate.instant('CategoriesToMastersTextsShort');

    // 87
    this.txtMarkAsChangedTextsShort = this.translate.instant('MarkAsChangedShort');
    this.txtMarkAsChangedTextsLong = this.translate.instant('MarkAsChangedLong');

    // 88
    this.txtGenerateSortNumberTextShort = this.translate.instant('GenerateSortNumberTextsShort');
    this.txtGenerateSortNumberTextLong = this.translate.instant('GenerateSortNumberTextsLong');

    // 89
    this.txtGenerateMasterFeaturesFromVariantsTextsShortTextShort = this.translate.instant(
      'GenerateMasterFeaturesFromVariantsTextsShort'
    );
    this.txtGenerateMasterFeaturesFromVariantsTextsShortTextLong = this.translate.instant(
      'GenerateMasterFeaturesFromVariantsTextsLong'
    );

    // 90:  // changeMimePurposes
    this.txtChangeMimePurposesTextShort = this.translate.instant('ChangeMimePurposesTextShort');
    this.txtChangeMimePurposesTextLong = this.translate.instant('ChangeMimePurposesTextLong');
  }

  getMacroName(id) {
    return this.macros.filter((macro) => macro.id == id).shift().text;
  }

  getMacroIcon(id) {
    return this.macros.filter((macro) => macro.id == id).shift().icon;
  }

  getMacroText(id) {
    return this.macros.filter((macro) => macro.id == id).shift().hint;
  }

  //TODO kein richtiger setter
  setMacros() {
    //Macros
    this.macros = [
      {
        id: USERACTIONVIEWDETAILS[0].id,
        text: USERACTIONVIEWDETAILS[0].text,
        icon: USERACTIONVIEWDETAILS[0].getCssClassForIcon(),
        hint: USERACTIONVIEWDETAILS[0].hint
      },
      {
        id: 30,
        text: this.txtRemoveMimeWithoutImagePathShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtRemoveMimeWithoutImagePathlong
      },
      {
        id: 3,
        text: this.txtReorderMimeShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtReorderMimelong
      },
      {
        id: 4,
        text: this.txtRemoveAllFeaturesShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveAllFeatureslong
      },
      {
        id: 5,
        text: this.txtRemoveAllMimesShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveAllMimeslong
      },
      {
        id: 6,
        text: this.txtRemoveAllOrderDetailsShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveAllOrderDetailslong
      },
      {
        id: 7,
        text: this.txtRemoveAllPricesShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveAllPriceslong
      },
      {
        id: 8,
        text: this.txtRemoveAllUDXShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveAllUDXlong
      },
      {
        id: 9,
        text: this.txtRemoveAllLogisticDetailsShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveAllLogisticDetailslong
      },
      {
        id: 10,
        text: this.txtRemoveEmptyCategoriesShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveEmptyCategorieslong
      },
      {
        id: 11,
        text: this.txtResizeImagesShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtResizeImageslong
      },
      {
        id: 13,
        text: this.translate.instant('SupplierPIDSwitchShort'),
        icon: 'glyphicon glyphicon-random',
        hint: this.translate.instant('SupplierPIDSwitchLong')
      },
      {
        id: 14,
        text: this.txtCheckCatalogShort,
        icon: '	glyphicon glyphicon-check',
        hint: this.txtCheckCataloglong
      },
      {
        id: 15,
        text: this.txtDeleteByListShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtDeleteByListlong
      },
      {
        id: 16,
        text: this.txtSearchByListShort,
        icon: 'glyphicon glyphicon-search',
        hint: this.txtSearchByListlong
      },
      {
        id: 17,
        text: this.txtUpdateFromCatalogShort,
        icon: 'glyphicon glyphicon-refresh',
        hint: this.txtUpdateFromCataloglong
      },
      {
        id: 51,
        text: this.txtUpdateToCatalogShort,
        icon: 'glyphicon glyphicon-refresh',
        hint: this.txtUpdateToCatalogLong
      },
      {
        id: 18,
        text: this.txtCompareCatalogShort,
        icon: 'glyphicon glyphicon-eye-close',
        hint: this.txtCompareCatalogLong
      },
      {
        id: 19,
        text: this.txtDeleteCategoryImagesShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtDeleteCategoryImageslong
      },
      {
        id: 20,
        text: this.txtDeleteUnUsedMimesShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtDeleteUnUsedMimeslong
      },
      {
        id: 21,
        text: this.txtAssignCategoryImagesShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtAssignCategoryImageslong
      },
      {
        id: 22,
        text: this.txtDeleteAllMediaShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtDeleteAllMedialong
      },
      {
        id: 23,
        text: this.txtCacheThumbnailsShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtCacheThumbnailslong
      },
      {
        id: 24,
        text: this.txtRemoveMasterShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRemoveMasterlong
      },
      {
        id: 25,
        text: this.txtFixStatisticsShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtFixStatisticslong
      },
      {
        id: 26,
        text: this.txtTranslateMimeSourcesShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtTranslateMimeSourceslong
      },
      {
        id: 27,
        text: this.txtTranslateNumbersShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtTranslateNumberslong
      },
      {
        id: 28,
        text: this.txtDetectMimeTypeShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtDetectMimeTypelong
      },
      {
        id: 29,
        text: this.txtRebuildThumbsShort,
        icon: 'glyphicon glyphicon-trash',
        hint: this.txtRebuildThumbslong
      },
      {
        id: 31,
        text: this.txtRenameFeatureSystemShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtRenameFeatureSystemLong
      },
      {
        id: 32,
        text: this.txtRemoveDuplicateImagesShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtRemoveDuplicateImageslong
      },
      {
        id: 33,
        text: this.txtDownloadMimeSourcesShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtDownloadMimeSourceslong
      },
      {
        id: 34,
        text: this.txtDeleteEmptyFeaturesShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtDeleteEmptyFeatureslong
      },
      {
        id: 35,
        text: this.txtEanSupplierAltPidSwitchShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtEanSupplierAltPidSwitchlong
      },
      {
        id: 36,
        text: this.txtAssignMasterImagesShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtAssignMasterImageslong
      },
      {
        id: 37,
        text: this.txtReportMissingMediaShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtReportMissingMedialong
      },
      {
        id: 38,
        text: this.translate.instant('DeleteFeatureSystemsShort'),
        icon: 'glyphicon glyphicon-wrench',
        hint: this.translate.instant('DeleteFeatureSystemsLong')
      },
      {
        id: 39,
        text: this.txtCopyMediaFromOtherCatalogShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtCopyMediaFromOtherCatalogLong
      },
      {
        id: 40,
        text: this.txtDeleteFeatureByNameShort,
        icon: 'glyphicon glyphicon-wrench',
        hint: this.txtDeleteFeatureByNameLong
      }
    ];

    this.macros.push({
      id: 41,
      text: this.txtAddEcommerceFeaturesShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtAddEcommerceFeaturesLong
    });
    this.macros.push({
      id: 42,
      text: this.txtTruncateMimeDescriptionsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtTruncateMimeDescriptionsLong
    });
    this.macros.push({
      id: 43,
      text: this.txtInternationalPidTypEanToGtinShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtInternationalPidTypEanToGtinLong
    });
    this.macros.push({
      id: 44,
      text: this.txtSetMimePurposeShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSetMimePurposeLong
    });
    this.macros.push({
      id: 46,
      text: this.txtSetIsBulkyShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSetIsBulkyLong
    });
    this.macros.push({
      id: 47,
      text: this.txtCreateTechnicalDetailsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtCreateTechnicalDetailsLong
    });

    this.macros.push({
      id: 48,
      text: this.txtRemoveRootCategoryShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRemoveRootCategoryLong
    });

    this.macros.push({
      id: 49,
      text: this.txtSetManufacturerAidFromSupplierPidShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSetManufacturerAidFromSupplierPidLong
    });
    this.macros.push({
      id: 50,
      text: this.txtRemoveMimesWithoutMimeSourceShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRemoveMimesWithoutMimeSourceLong
    });
    this.macros.push({
      id: 52,
      text: this.txtReorderCategoriesShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtReorderCategoriesLong
    });
    this.macros.push({
      id: 53,
      text: this.txtMasterToCategoryShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtMasterToCategoryLong
    });
    this.macros.push({
      id: 55,
      text: this.txtSpreadCatalogShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSpreadCatalogLong
    });
    this.macros.push({
      id: 56,
      text: this.txtSetMavisTransferDateShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSetMavisTransferDateLong
    });
    this.macros.push({
      id: 57,
      text: this.txtSetBmeCatDefaultsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSetBmeCatDefaultsLong
    });
    this.macros.push({
      id: 58,
      text: this.txtHarmonizeFeatureSystemsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtHarmonizeFeatureSystemsLong
    });
    this.macros.push({
      id: 60,
      text: this.txtFixClassificationShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtFixClassificationLong
    });
    this.macros.push({
      id: 61,
      text: this.txtCreateUniqueGroupIdShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtCreateUniqueGroupIdLong
    });
    this.macros.push({
      id: 62,
      text: this.txtExportReportProductDatasheetShort,
      icon: 'glyphicon glyphicon-print',
      hint: this.txtExportReportProductDatasheetLong
    });

    this.macros.push({
      id: 63,
      text: this.txtDeleteDuplicateFeaturesShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtDeleteDuplicateFeaturesLong
    });
    this.macros.push({
      id: 64,
      text: this.txtDeleteDuplicateMimesShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtDeleteDuplicateMimesLong
    });
    this.macros.push({
      id: 65, // Category
      text: this.txtRemoveEmptyUDXShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRemoveEmptyUDXLong
    });
    this.macros.push({
      id: 66, // Product
      text: this.txtRemoveEmptyUDXShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRemoveEmptyUDXLong
    });
    this.macros.push({
      id: 67,
      text: this.txtRemoveDeadReferencesShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRemoveDeadReferencesLong
    });
    this.macros.push({
      id: 68,
      text: this.txtRemoveUDXOnProductsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRemoveUDXOnProductsLong
    });
    this.macros.push({
      id: 69,
      text: this.txtCreateLinkedFieldsOnProductsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtCreateLinkedFieldsOnProductsLong
    });

    this.macros.push({
      id: 72,
      text: this.txtCreateVariantsFeatureSystemShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtCreateVariantsFeatureSystemLong
    });

    this.macros.push({
      id: 71,
      text: this.txtDeleteMediaByPurposeShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtDeleteMediaByPurposeLong
    });
    this.macros.push({
      id: 73,
      text: this.txtConsolidateDubSupplierPidsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtConsolidateDubSupplierPidsLong
    });
    this.macros.push({
      id: 74,
      text: this.txtHarmonizeFeaturesByNameShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtHarmonizeFeaturesByNameLong
    });
    this.macros.push({
      id: 76,
      text: this.txtFlattenCategoryTreeShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtFlattenCategoryTreeLong
    });
    this.macros.push({
      id: 77,
      text: this.txtSetWawiLinesShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSetWawiLinesLong
    });
    this.macros.push({
      id: 78,
      text: this.txtSetWawiSupplierPidsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSetWawiSupplierPidsLong
    });
    this.macros.push({
      id: 79,
      text: this.txtRemoveSpecialCharactersShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRemoveSpecialCharactersLong
    });
    this.macros.push({
      id: 81,
      text: this.txtVariantResolveShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtVariantResolveLong
    });
    this.macros.push({
      id: 82,
      text: this.txtCreateEnventaMimeShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtCreateEnventaMimeLong
    });
    this.macros.push({
      id: 83,
      text: this.txtGenerateKITextsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtGenerateKITextsLong
    });
    this.macros.push({
      id: 84,
      text: this.txtRenameFeaturesShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtRenameFeaturesLong
    });
    this.macros.push({
      id: 85,
      text: this.txtSplitFeatureValuesFromUnitShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtSplitFeatureValuesFromUnitLong
    });
    this.macros.push({
      id: 86,
      text: this.txtCategoriesToMastersTextsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtCategoriesToMastersTextsLong
    });
    this.macros.push({
      id: 87,
      text: this.txtMarkAsChangedTextsShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtMarkAsChangedTextsLong
    });

    this.macros.push({
      id: 88,
      text: this.txtGenerateSortNumberTextShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtGenerateSortNumberTextLong
    });

    this.macros.push({
      id: 89,
      text: this.txtGenerateMasterFeaturesFromVariantsTextsShortTextShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtGenerateMasterFeaturesFromVariantsTextsShortTextLong
    });

    this.macros.push({
      id: 90,
      text: this.txtChangeMimePurposesTextShort,
      icon: 'glyphicon glyphicon-wrench',
      hint: this.txtChangeMimePurposesTextLong
    });

    this.macros.push({
      id: 91,
      text: this.translate.instant('txtConvertOrderDetailsToSupplierDetailsTextShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtConvertOrderDetailsToSupplierDetailsTextLong')
    });

    this.macros.push({
      id: 92,
      text: this.translate.instant('txtDeleteEmptySuppliersTextShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtDeleteEmptySuppliersTextLong')
    });
    this.macros.push({
      id: 93,
      text: this.translate.instant('txtListProductsWithoutMediaTextShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtListProductsWithoutMediaTextLong')
    });
    this.macros.push({
      id: 94,
      text: this.translate.instant('txtDeleteAllSuppliersTextShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtDeleteAllSuppliersTextLong')
    });
    this.macros.push({
      id: 95,
      text: this.translate.instant('txtRenameMediaCaseSensitiveShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtRenameMediaCaseSensitiveLong')
    });
    this.macros.push({
      id: 96,
      text: this.translate.instant('txtFindEmptyFeatureSystemsJobShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtFindEmptyFeatureSystemsJobLong')
    });
    this.macros.push({
      id: 97,
      text: this.translate.instant('txtApplyWawiMappingsShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtApplyWawiMappingsLong')
    });
    this.macros.push({
      id: 98,
      text: this.translate.instant('txtFixNumberFieldsJobShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtFixNumberFieldsJobLong')
    });
    this.macros.push({
      id: 99,
      text: this.translate.instant('txtCheckVariantDatailsJobShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtCheckVariantDatailsJobLong')
    });
    this.macros.push({
      id: 100,
      text: this.translate.instant('txtGenerateUniqueManufacturerNumberJobShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtGenerateUniqueManufacturerNumberJobLong')
    });
    this.macros.push({
      id: 101,
      text: this.translate.instant('txtAssignMimeBySupplierPidJobShort'),
      icon: 'glyphicon glyphicon-wrench',
      hint: this.translate.instant('txtAssignMimeBySupplierPidJobLong')
    });
  }

  setCards() {
    this.cards = new Array<Card>();

    let catalogCard = new Card();
    catalogCard.name = this.txtKatalog;
    catalogCard.elements = [14, 16, 17, 51, 18, 15, 87];
    this.cards.push(catalogCard);
    if (this.modelService.loginService.hasAddonMULTI_ACCOUNT) {
      catalogCard.elements.push(55);
    }
    if (this.modelService.loginService.hasAddonMavis) {
      catalogCard.elements.push(56);
    }

    let categoryCard = new Card();
    categoryCard.name = this.txtKategorie;
    categoryCard.elements = [10, 19, 21, 48, 52, 61, 65, 76, 86];
    this.cards.push(categoryCard);

    let productCard = new Card();
    productCard.name = this.txtProdukte;
    productCard.elements = [6, 7, 8, 9, 13, 35, 43, 46, 49, 57, 66, 67, 68, 73, 86, 88, 92, 94, 93, 100, 101];
    if (this.modelService.loginService.hasAddonDEVELOPMENT || this.modelService.loginService.hasAddonSUPPLIERS) {
      productCard.elements.push(91);
    }

    this.cards.push(productCard);

    let mediaCard = new Card();
    mediaCard.name = this.txtMedien;
    mediaCard.elements = [1, 20, 11, 3, 5, 22, 28, 32, 33, 37, 30, 39, 42, 44, 50, 64, 71, 79, 82, 90, 95, 101];
    if (
      this.modelService.loginService.hasAddonREPORTPRODUCTDATASHEET &&
      this.modelService.loginService.currentCustomer.pimLicense != PimLicense.EdeEditon
    ) {
      mediaCard.elements.push(62);
    }
    this.cards.push(mediaCard);

    let featureCard = new Card();
    featureCard.name = this.txtFeatures;
    featureCard.elements = [4, 31, 84, 85, 34, 38, 40, 47, 58, 74, 89];
    this.cards.push(featureCard);

    if (this.modelService.loginService.hasAddonCLASSIFICATION) {
      featureCard.elements.push(60);
    }

    if (this.modelService.loginService.isAdmin) {
      let shopCard = new Card();
      shopCard.name = this.txtShop;
      shopCard.elements = [23];
      this.cards.push(shopCard);
    }

    let translationCard = new Card();
    translationCard.name = this.txtUebersetzung;
    translationCard.elements = [26, 27];
    this.cards.push(translationCard);

    let variantsCard = new Card();
    variantsCard.name = this.txtVarianten;
    variantsCard.elements = [72, 69, 53, 36, 24, 81, 99];
    this.cards.push(variantsCard);

    let troubleShootingCard = new Card();
    troubleShootingCard.name = this.txtTroubleShooting;
    troubleShootingCard.elements = [25, 96, 98];
    if (this.modelService.loginService.isAdmin) {
      troubleShootingCard.elements.push(29);
    }
    this.cards.push(troubleShootingCard);

    if (this.modelService.loginService.hasAddonWawiErp) {
      let wawiCard = new Card();
      wawiCard.name = this.modelService.loginService.wawiSettings.name;
      wawiCard.elements = [77, 78, 82, 97];
      this.cards.push(wawiCard);
    }

    if (this.modelService.loginService.hasAddonKI_TEXTS) {
      let KICard = new Card();
      KICard.name = this.txtKIText;
      KICard.elements = [83];
      this.cards.push(KICard);
    }
  }

  //TODO kein richtiger setter
  setMacro(id: number) {
    this.macro = id;
    this.startMacroButtonEnabled = true;

    if (
      id === 19 ||
      id === 20 ||
      id === 4 ||
      id === 5 ||
      id === 6 ||
      id === 7 ||
      id === 8 ||
      id === 9 ||
      id === 10 ||
      id === 11 ||
      id === 22 ||
      id === 50 ||
      id === 63 ||
      id === 64 ||
      id === 67
    ) {
      this.isDelete = true;
    } else {
      this.isDelete = false;
    }

    this.isFeatureSystemMakro = id === 58 || id === 38 || id === 31;
  }

  async startMacro() {
    const startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    switch (this.macro) {
      case 1:
        startAction.actionId = 'RemovePathFromMimeSource';
        break;
      case 2:
        startAction.actionId = 'RemovePathFromFile';
        break;
      case 3:
        startAction.actionId = 'ReorderMime';
        break;
      case 4:
        startAction.actionId = 'RemoveAllFeatures';
        break;
      case 5:
        startAction.actionId = 'RemoveAllMimes';
        break;
      case 6:
        startAction.actionId = 'RemoveAllOrderDetails';
        break;
      case 7:
        startAction.actionId = 'RemoveAllPrices';
        break;
      case 8:
        startAction.actionId = 'RemoveAllUDX';
        break;
      case 9:
        startAction.actionId = 'RemoveAllLogisticDetails';
        break;
      case 10:
        startAction.actionId = 'RemoveEmptyCategories';
        break;
      case 11:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 13:
        startAction.actionType = 'SupplierPIDSwitch';
        break;
      case 14:
        startAction.actionId = 'CheckCatalog';
        break;
      case 15:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 16:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 17:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 18:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 19:
        startAction.actionId = 'DeleteCategoryImages';
        break;
      case 20:
        startAction.actionId = 'DeleteUnUsedMimes';
        break;
      case 21:
        startAction.actionType = 'AssignCategoryImages';
        break;
      case 22:
        startAction.actionId = 'DeleteAllMedia';
        break;
      case 23:
        startAction.actionId = 'CacheThumbnails';
        break;
      case 24:
        startAction.actionId = 'RemoveMaster';
        break;
      case 25:
        startAction.actionId = 'FixStatistics';
        break;
      case 26:
        startAction.actionId = 'TranslateMimeSources';
        break;
      case 27:
        startAction.actionId = 'TranslateNumbers';
        break;
      case 28:
        startAction.actionId = 'DetectMimeType';
        break;
      case 29:
        startAction.actionId = 'RebuildThumbs';
        break;
      case 30:
        startAction.actionId = 'RemoveMimeWithoutImagePath';
        break;
      case 31:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 32:
        startAction.actionId = 'RemoveDuplicateImages';
        break;
      case 33:
        startAction.actionId = 'DownloadMimeSources';
        break;
      case 34:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 35:
        startAction.actionId = 'EanSupplierAltPidSwitch';
        break;
      case 36:
        startAction.actionType = 'AssignMasterImages';
        break;
      case 37:
        startAction.actionId = 'ReportMissingMedia';
        break;
      case 38:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 39:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 40:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 41:
        startAction.actionId = 'AddEcommerceFeatures';
        break;
      case 42:
        startAction.actionId = 'TruncateMimeDescriptions';
        break;
      case 43:
        startAction.actionId = 'InternationalPidTypEanToGtin';
        break;
      case 44:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 45:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 46:
        startAction.actionId = 'SetIsBulky';
        break;
      case 47:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 48:
        startAction.actionId = 'RemoveRootCategory';
        break;
      case 49:
        startAction.actionId = 'SetManufacturerAidFromSupplierPid';
        break;
      case 50:
        startAction.actionId = 'RemoveMimesWithoutMimeSource';
        break;
      case 51:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 52:
        startAction.actionId = 'ReorderCategories';
        break;
      case 53:
        startAction.actionId = 'MasterToCategory';
        break;
      case 55:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 56:
        startAction.actionId = 'SetMavisTransferDate';
        break;
      case 57:
        startAction.actionId = 'SetBmeCatDefaultValues';
        break;
      case 58:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 60:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 61:
        startAction.actionId = 'CreateUniqueGroupId';
        break;
      case 62:
        //startAction.actionId = "ExportReportProductDatasheet";  // da kriegen wir die parameter nicht 'rein und 'rüber
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 63:
        startAction.actionId = 'DeleteDuplicateFeatures';
        break;
      case 64:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 65:
        startAction.actionId = 'RemoveEmptyUDXOnCategories';
        break;
      case 66:
        startAction.actionId = 'RemoveEmptyUDXOnProducts';
        break;
      case 67:
        startAction.actionId = 'RemoveDeadReferences';
        break;
      case 68:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 69:
        startAction.actionId = 'CreateLinkedFieldsOnProducts';
        break;
      case 71:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 72:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 73:
        startAction.actionId = 'ConsolidateDubSupplierPids';
        break;
      case 74:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 76:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 77:
        startAction.actionId = 'SetWawiLines';
        break;
      case 78:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 79:
        startAction.actionId = 'RemoveSpecialCharacters';
        break;
      case 81:
        startAction.actionId = 'NMVariantsResolve';
        break;
      case 82:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 83:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 84:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 85:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 86:
        startAction.actionId = 'CategoriesToMasters';
        break;
      case 87:
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 88:
        startAction.actionType = 'GenerateSortNumber';
        break;
      case 89:
        startAction.actionType = 'GenerateMasterFeaturesFromVariants';
        break;
      case 90: // changeMimePurposes
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 91: // convertOrderDetailsToSupplierDetailsJob
        startAction.actionType = 'ConvertOrderDetailsToSupplierDetails';
        break;
      case 92:
        startAction.actionType = 'DeleteEmptySuppliers';
        break;
      case 93:
        startAction.actionType = 'ListProductsWithoutMedia';
        break;
      case 94:
        startAction.actionType = 'DeleteAllSuppliers';
        break;
      case 95:
        startAction.actionType = 'RenameMediaCaseSensitive';
        break;
      case 96:
        startAction.actionType = 'FindEmptyFeatureSystems';
        break;
      case 97: // applyWawiMappings
        this.currentSelectedUserActionComponent.startMacro();
        return;
      case 98:
        startAction.actionType = 'FixNumberFields';
        break;
      case 99:
        startAction.actionType = 'CheckValidDetails';
        break;
      case 100:
        startAction.actionType = 'GenerateUniqueManufacturerNumber';
        break;
      case 101:
        this.currentSelectedUserActionComponent.startMacro();
        break;
    }

    this.modelService.catalogService.showUserAction = false;
    this.modelService.jobService.startUserAction(startAction).subscribe((success) => {
      if (success) {
        this.modelService.systemService.notify(new Message(this.txtMakroGestarted));
      }
    });
  }

  initRepository(): void {
    USERACTIONVIEWDETAILS.forEach((item) => {
      this.translate.get(item.hintLookupKey).subscribe((text: string) => {
        item.hint = text;
      });

      this.translate.get(item.textLookupKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  //workflow / behavior control
  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  resetView(): void {
    this.macroAccordionSelectedItem = this.cards[0];
    this.setMacro(0);
  }

  onUserActionStarted(value: any) {
    if (typeof value == 'boolean' && value) {
      this.modelService.systemService.notify(new Message(this.txtMakroGestarted));
      this.modelService.catalogService.showUserAction = false;
    } else {
      //TODO Logging
      console.log(value);
    }

    //wichtig, damit sich die einzelnen Komponenten neu initialisieren
    this.resetView();
  }

  onUserActionComponentInitiated(value: any) {
    if (value instanceof UserActionBaseComponent) {
      this.currentSelectedUserActionComponent = value;
    } else {
      //TODO Logging
      console.log(value);
    }
  }
}
