import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { Message } from '../../../../Model/System/Message';
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { TemplateService } from '../../../../Services/template.service';
import { ExportConfigurationService } from '../../../../Services/exportConfiguration.service';
import { ExportParameter } from '../../../../Model/exportParameter/ExportParameter';
import { CatalogFilter } from '../../../../Model/exportParameter/CatalogFilter';
import { SearchByListParameter } from '../../../../Model/Dto/SearchByListParameter';
import { SystemService } from '../../../../Services/system.service';
import { CatalogDataQualityService } from 'app/Services/Validation/catalogDataQuality.service';
import { CatalogService } from 'app/Services/CatalogManagement/catalog.service';
import { LoginService } from 'app/Services/login.service';
import { CatalogDataQuality } from 'app/Model/validation/CatalogDataQuality';
import { StartAction } from 'app/Model/Dto/StartAction';
import { UserManagementService } from 'app/Services/userManagment.service';
import { JobService } from 'app/Services/job.service';
import { ValidationService } from 'app/Services/Validation/validation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'export',
  templateUrl: 'exportCatalog.component.html',
  styleUrls: ['exportCatalog.component.css']
})
export class ExportCatalogComponent implements OnInit {
  @ViewChild('treeViewRefUdx', { static: false }) treeViewUdx: DxTreeViewComponent;
  @ViewChild('treeViewCategoryFilter', { static: false }) treeViewCategoryFilter: DxTreeViewComponent;

  @Input()
  public set visible(val: boolean) {
    if (this._visible !== val) {
      this._visible = val;
      this.visibleChange.emit(val);
    }
  }
  public get visible() {
    return this._visible;
  }

  @Output() public visibleChange = new EventEmitter<boolean>();

  private _visible: boolean = false;
  public config: ExportParameter;

  configurations: ExportParameter[] = [];
  exportParameterChanged: EventEmitter<object> = new EventEmitter<object>();

  isPopupEditVisible = false;
  isPopupAddVisible = false;

  configName = '';
  configIsDefault = false;

  newConfigName = '';
  newConfigIsDefault = false;

  catalogDataQuality: CatalogDataQuality;

  areGroupsMismatched = false;

  isNotValidDataQuality = true;
  isCatalogDataQualityUpToDate = false;
  notCheckedDataQuality = false;

  calculateDataQualityJobRunning = false;

  constructor(
    public templateService: TemplateService,
    public translate: TranslateService,
    public exportConfigurationService: ExportConfigurationService,
    public catalogService: CatalogService,
    private jobService: JobService,
    private systemService: SystemService,
    private loginService: LoginService,
    private catalogDataQualityService: CatalogDataQualityService,
    private userManagement: UserManagementService,
    private validationService: ValidationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.exportNordwestClicked = this.exportNordwestClicked.bind(this);
    this.export = this.export.bind(this);

    if (catalogService.selectedCatalogId == null) catalogService.selectedCatalogId = catalogService.catalog.id;

    if (loginService.currentUser.customerId == null)
      loginService.currentUser.customerId = loginService.currentCustomer.id;

    this.config = new ExportParameter();
    this.config.customerId = loginService.currentUser.customerId;
    this.config.catalogId = catalogService.selectedCatalogId;
  }

  ngOnInit(): void {
    this.exportConfigurationService.getAll(this.loginService.currentCustomer.id).subscribe((result) => {
      this.configurations = result.filter((x) => !x.isUpdateFilter);
      let defaultConfig = this.configurations.find((x) => x.isDefault);
      if (defaultConfig) {
        this.loadConfig(defaultConfig.id);
      }
    });

    this.validationService.validationResultChanged.subscribe(async (x) => {
      await this.refreshDataQuality();
    });
  }

  onConfigFormatChange() {
    this.refreshDataQuality()
      .then(() => {
        if (this.config?.format === 'SEND_TO_CUSTOMER' && !this.isCatalogDataQualityUpToDate) {
          return custom({
            title: this.translate.instant('Datenqualität'),
            messageHtml: this.translate.instant(
              'Vor dem Export muss die Datenqualität geprüft werden. Drücken Sie dazu das orange Validierungsicon.'
            ),
            buttons: [
              {
                text: this.translate.instant('schließen'),
                onClick: (e) => {
                  return { buttonText: true };
                }
              }
            ]
          }).show();
        }
      })
      .catch(console.error);
  }

  async refreshDataQuality() {
    this.catalogDataQuality = await this.catalogDataQualityService.getCatalogDataQuality(
      this.loginService.currentUser.customerId,
      this.catalogService.selectedCatalogId
    );

    this.isNotValidDataQuality = this.catalogDataQuality?.requiredGroupsMismatchCount > 0;
    if (this.catalogDataQuality.productsCountInGroups?.length > 0) {
      this.isCatalogDataQualityUpToDate = await this.catalogDataQualityService.getCatalogDataQualityUpToDateInfo(
        this.loginService.currentUser.customerId,
        this.catalogService.selectedCatalogId
      );
      this.notCheckedDataQuality = false;
    } else {
      this.isCatalogDataQualityUpToDate = false;
      this.notCheckedDataQuality = true;
    }
  }

  calculateDataQuality() {
    if (this.calculateDataQualityJobRunning) return;

    let timer = Date.now();
    let param = new StartAction();
    param.customerId = this.loginService.currentUser.customerId;
    param.catalogId = this.catalogService.selectedCatalogId;

    const jobFn = (obj: { jobId: any; timeout?: any }) => () => {
      if (obj.timeout >= 0) clearTimeout(obj.timeout);

      const jobSubscription = this.jobService.getJobs(this.loginService.currentUser.customerId).subscribe((jobs) => {
        const job = jobs.find((job) => job.id == obj.jobId);

        if (job.stateText === 'Finished') {
          this.isNotValidDataQuality = false;
          this.isCatalogDataQualityUpToDate = true;
          this.calculateDataQualityJobRunning = false;
          jobSubscription.unsubscribe();
          return;
        }

        if (job.stateText === 'Error' && this.catalogDataQuality?.requiredGroupsMismatchCount > 0) {
          this.isNotValidDataQuality = job.stateText === 'Error';
          this.isCatalogDataQualityUpToDate = true;
          this.calculateDataQualityJobRunning = false;
          custom({
            title: this.translate.instant('Datenqualität'),
            messageHtml: this.translate.instant('Leider erfüllen Artikel nicht die erforderlichen Kriterien.', {
              count: this.catalogDataQuality.requiredGroupsMismatchCount
            }),
            buttons: [
              {
                text: this.translate.instant('schließen'),
                onClick: (e) => {
                  return { buttonText: true };
                }
              }
            ]
          }).show();
        } else {

          if (Date.now() - timer > 60000) {
            this.calculateDataQualityJobRunning = false;
            custom({
              title: this.translate.instant('Datenqualität'),
              messageHtml: this.translate.instant('Die Prüfung der Datenqualität kann länger dauern. Bitte schauen Sie zu gegebener Zeit die Jobliste, ob die Prüfung fertig ist.'),
              buttons: [
                {
                  text: this.translate.instant('schließen'),
                  onClick: (e) => {
                    return { buttonText: true };
                  }
                }
              ]}).show();
          } else {
            obj.timeout = setTimeout(jobFn(obj), 1000);
          }
        }
        jobSubscription.unsubscribe();
      });
    };


    this.calculateDataQualityJobRunning = true;
    this.catalogDataQualityService.CalculateCatalogDataQuality(param).subscribe((jobId) => {
      jobFn({ jobId })();
    });
  }

  goToDataQualityTab() {
    const catalogId = this.route.snapshot.paramMap.get('catalogId');
    const customerId = this.route.snapshot.paramMap.get('customerId');
    this.router.navigate(['catalog', customerId, catalogId, 'dataquality']);
    this.catalogService.exportVisible = false;
  }

  public loadConfig(id) {
    this.exportConfigurationService.get(id, this.loginService.currentCustomer.id).subscribe((result) => {
      this.config = Object.assign(new ExportParameter(), result);
      this.config.catalogId = this.catalogService.catalog?.id;
      this.config.customerId = this.loginService.currentUser.customerId;
      this.setEmptyCatalogFilters();
      this.exportParameterChanged.emit();
    });
  }

  setEmptyCatalogFilters() {
    this.config.categoryFilter ??= new CatalogFilter([]);
    this.config.productStatesFilter ??= new CatalogFilter([]);
    this.config.udxsFilter ??= new CatalogFilter([]);
    this.config.productStatesFilter ??= new CatalogFilter([]);
    this.config.manufacturerFilter ??= new CatalogFilter([]);
    this.config.supplierFilter ??= new CatalogFilter([]);
    this.config.dateFilter ??= new CatalogFilter(new Date());
    this.config.searchByListParameter ??= new CatalogFilter(new SearchByListParameter());
    this.config.featureSystemFilter ??= new CatalogFilter([]);
    this.config.featureSystemContentFilter ??= new CatalogFilter([]);
    this.config.priceListsContentFilter ??= new CatalogFilter([]);
    this.config.udxsContentFilter ??= new CatalogFilter([]);
  }

  showAddConfig() {
    this.newConfigName = '';
    this.newConfigIsDefault = false;
    this.isPopupAddVisible = true;
  }

  showEditConfig() {
    this.isPopupEditVisible = true;
  }

  onConfigChanged(e) {
    if (e.selectedItem && e.selectedItem.id != this.config?.id) this.loadConfig(e.selectedItem.id);
  }

  addConfig() {
    if (this.newConfigName == '') {
      this.systemService.notifyWarning('Konfiguration muss einen Namen haben');
      return;
    }

    this.config.customerId = this.loginService.currentCustomer.id;
    this.config.catalogId = this.catalogService.selectedCatalogId;
    this.config.name = this.newConfigName;
    this.config.isDefault = this.newConfigIsDefault;
    this.config.id = null;
    this.config.includeUdxs = true;
    this.updateConfig();
  }

  resetConfig() {
    var resettedConfig = new ExportParameter();
    resettedConfig.name = this.config.name;
    resettedConfig.id = this.config.id;
    resettedConfig.isDefault = this.config.isDefault;
    resettedConfig.customerId = this.config.customerId;
    resettedConfig.catalogId = this.catalogService.selectedCatalogId;
    this.config = resettedConfig;
  }

  updateConfig() {
    if (this.config.name == '') {
      this.systemService.notifyWarning('Konfiguration muss einen Namen haben');
      return;
    }

    if (this.templateService.template != null) {
      this.config.templateId = this.templateService.template.id;
    } else {
      this.config.templateId = null;
    }

    this.exportConfigurationService.update(this.config, this.loginService.currentCustomer.id).subscribe(
      (exportParameter) => {
        this.config = Object.assign(new ExportParameter(), exportParameter);
        this.isPopupAddVisible = false;
        this.isPopupEditVisible = false;
        this.exportConfigurationService.getAll(this.loginService.currentCustomer.id).subscribe((result) => {
          this.configurations = result.filter((x) => !x.isUpdateFilter);
        });
      },
      (error) => {
        this.systemService.notifyWarning(error);
      }
    );
  }

  deleteConfig() {
    this.exportConfigurationService.delete(this.config.id, this.loginService.currentCustomer.id).subscribe(
      () => {
        this.exportConfigurationService.getAll(this.loginService.currentCustomer.id).subscribe((result) => {
          this.config = new ExportParameter();
          this.configurations = result.filter((x) => !x.isUpdateFilter);
          this.isPopupAddVisible = false;
          this.isPopupEditVisible = false;
        });
      },
      (error) => {
        this.systemService.notifyWarning(error);
      }
    );
  }

  export() {
    this.config.customerId = this.loginService.currentCustomer.id;
    if (this.catalogService.selectedCatalogId) {
      this.config.catalogId = this.catalogService.selectedCatalogId;
    }
    if (this.templateService.template?.isAdvancedTemplate) {
      this.config.useMetadata = this.catalogService.useMetadata;
    }

    if (this.config.version == 'TEMPLATE' || this.config.format == 'TEMPLATE') {
      if (this.templateService.template != null) {
        this.config.templateId = this.templateService.template.id;
      } else {
        this.config.templateId = null;
        this.systemService.notifyInfo(this.translate.instant('Bitte wählen Sie ein Template aus.'));
        return;
      }
    }

    if (this.config.format == 'SEND_TO_CUSTOMER') {
      this.exportToCustomerWithValidation();
      return;
    }

    if (this.config.version == 'NORDWEST') {
      this.exportNordwestClicked();
      return;
    }

    if (this.config.version == 'SMARTSTORE') {
      this.exportSmartStoreClicked();
      return;
    }

    if (this.config.format == 'NEWCATALOG') {
      switch (this.config.version) {
        case 'FIELDLIST':
        case 'TEMPLATE':
          this.exportNewCatalogExcel();
          break;
        case 'BME':
          this.exportNewCatalog();
          break;
      }
      return;
    }

    this.catalogService.exportVisible = false;
    this.catalogService.createExportJob(this.config).subscribe(() => {
      if (this.config.scheduledFor) {
        this.systemService.notify(
          new Message(
            this.translate.instant('Export wird ausgeführt am', {
              date: this.config.scheduledFor.toLocaleString()
            })
          )
        );
      } else {
        this.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
      }
    });
  }

  exportToCustomerWithValidation() {
    this.catalogService.exportVisible = false;
    this.catalogService.createExportToCustomerWithValidationJob(this.config).subscribe(() => {
      this.systemService.notify(
        new Message(this.translate.instant('Die Validierung und der Export wird als Job ausgeführt'))
      );
    });
  }

  exportSmartStoreClicked() {
    this.config.format = 'SMARTSTORE';
    this.catalogService.exportVisible = false;
    this.catalogService.createSmartStoreExportJob(this.config).subscribe(() => {
      this.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
    });
  }

  exportNordwestClicked() {
    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';

    this.translate.get('Ja').subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get('Nein').subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get('Sollen die Daten wirklich zum NORDWEST gesendet werden?').subscribe((text: string) => {
      txtMsg = text;
    });
    if (!this.config.includeMime) {
      this.translate
        .get('Sollen die Daten wirklich OHNE MEDIEN zum NORDWEST gesendet werden?')
        .subscribe((text: string) => {
          txtMsg = text;
        });
    }
    this.translate.get('Wirklich senden?').subscribe((text: string) => {
      txtTitle = text;
    });
    let myDialog: any;
    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => {
            return { buttonText: true };
          }
        },
        {
          text: txtNo,
          onClick: (e) => {
            return { buttonText: false };
          }
        }
      ]
    });
    myDialog.show().then((dialogResult) => {
      if (dialogResult.buttonText == true) {
        this.catalogService.exportVisible = false;
        this.catalogService.createExportJob(this.config).subscribe(() => {
          this.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
        });
      }
    });
  }

  exportNewCatalog() {
    this.catalogService.exportVisible = false;
    this.catalogService.createNewCatalogExportJob(this.config).subscribe(() => {
      this.catalogService.requestCatalogChangingJobs();
      this.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
    });
  }

  exportNewCatalogExcel() {
    this.catalogService.exportVisible = false;

    if (this.config.version == 'TEMPLATE') {
      if (this.templateService.template == null) {
        let message = this.translate.instant('Bitte ein Template auswählen') + '.';
        this.systemService.notify(new Message(message, 'info'), 3000);
        return;
      }
      this.config.templateId = this.templateService.template.id;
    }

    this.catalogService.createNewCatalogExportJob(this.config).subscribe(() => {
      this.catalogService.requestCatalogChangingJobs();
      this.systemService.notify(new Message(this.translate.instant('Der Export wird als Job ausgeführt')));
    });
  }

  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
