import { Injectable } from "@angular/core";
import { FeatureSystem } from '../Model/Catalog/FeatureModels/FeatureSystem';
import { LanguageFlag } from '../Model/Dto/LanguageFlag';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ViewService {

  dropDownMimeDescr: string[] = new Array<string>();

  constructor(public translate: TranslateService) {

    this.initMimeDescriptionDropDown();


  }


  initMimeDescriptionDropDown() {

    this.translate.get("Artikelhauptbild").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Abb. ähnlich").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Sicherheitsdatenblatt").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Deeplink Produktseite").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Seitenansicht").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Deeplink REACH").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Energielabel").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktdatenblatt für Energielabel").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild Detailansicht").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild Vorderseite").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild schräg").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild Ansicht oben").subscribe((text: string) => { this.dropDownMimeDescr.push(text); });

    this.translate.get("Produktbild Ansicht links").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild Ansicht rechts").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild Rückseite").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produktbild Ansicht unten").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Schaltbild").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Maßzeichnung").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });




    this.translate.get("Produktdatenblatt").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Milieubild").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Produkt Icon").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Gebrauchsanleitung").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Langzeitlieferantenerklärung").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Montageanleitung").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Sonstige").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Hersteller Logo").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Kategoriebild").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Anwendung").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });
    this.translate.get("Anwendung Detail").subscribe((text: string) => {
      this.dropDownMimeDescr.push(text);
    });

    this.dropDownMimeDescr.push(this.translate.instant("Explosionszeichnung"));
    this.dropDownMimeDescr.push(this.translate.instant("Ersatzteilliste"));
    this.dropDownMimeDescr.push(this.translate.instant("Zertifikat"));


  }


  public fullScreen: boolean = false;
  public fullScreenBackUp: boolean = false;
  public jobsVisible: boolean = false;
  public catalogJobsVisible: boolean = false;

  static isSingleLine(name: string): boolean {
    switch (name) {
      case "udf_NMMARKETINGCLAIM-1.0":
        return true;
    }
    return false;
  }

  static isSingleValue(name: string): boolean {
    switch (name) {
      case "udf_NMMARKETINGCLAIM-1.0":
      case "udf_NMTOPFEATURES-1.0":
      case "udf_NMBENEFITS-1.0":
      case "udf_NMSCOPEOFSUPPLY-1.0":
      case "udf_NMUSAGE-1.0":
      case "udf_NMUSEFOR-1.0":
        return true;
    }
    return false;
  }

  static isMagicSystem(name: string): boolean {
    switch (name) {
      case "udf_NPECOMMERCE":
      case "udf_NPECOMMERCE_FEATURES":
      case "udf_NPECOMMERCE-1.0":
      case "udf_NPECOMMERCE_FEATURES-1.0":
        return true;
    }
  }

  static hasEcommerceFeatureSystems(featureSystems: FeatureSystem[]): boolean {
    let hasEcommerce = false;
    let hasEcommerceFeatures = false;

    for (let featureSystem of featureSystems) {
      if (featureSystem.referenceFeatureSystemName == "udf_NPECOMMERCE") {
        hasEcommerce = true;
      }
      if (featureSystem.referenceFeatureSystemName == "udf_NPECOMMERCE_FEATURES") {
        hasEcommerceFeatures = true;
      }
    }

    if (hasEcommerce == false)
      return false;

    if (hasEcommerceFeatures == false)
      return false;
    return true;
  }



  //Auswahl
  static dropDownCurrencies = ["EUR", "GBP", "CHF", "PLN", "CZK", "USD"];
  static dropDownTerritories = ["AT", "CH", "CZ", "DE", "NL", "PL", "UK", "US"];
  static dropDownMimeTypes = [
    'image/jpeg', 'image/png', 'image/tif', 'application/pdf', 'url', 'image/gif', 'image/eps', 'video/mp4', 'video/url'
  ];
  static dropDownMimePurposes = ['normal', 'data_sheet', 'detail', 'icon', 'logo', 'safety_data_sheet', 'thumbnail', 'milieu', 'others', 'energie_label', "ENVENTA", "manufacturer_logo", "warranty", "warranty_detail"];

  static dropDownFeatureSystemNames = [
    "udf_NMMARKETINGCLAIM-1.0", "udf_NMOTHERS-1.0", "udf_NMSCOPEOFSUPPLY-1.0",
    "udf_NMTECHNICALDETAILS-1.0", "udf_NMTOPFEATURES-1.0", "udf_NMBENEFITS-1.0", "udf_NMTRADINGDETAILS-1.0", "udf_NMVARIANTS-1.0", "udf_NMPOINTS-1.0",
    "udf_NMUSAGE-1.0", "udf_NMUSEFOR-1.0", "udf_NMFILTER-1.0"
  ];

  static dropDownPriceTypes = ["net_list", "net_customer", "net_customer_exp", "net_net", "gross_list", "gross_customer", "nrp", "on_request"];
  static dropDownTaxs = [0.19, 0.21];
  static dropDownPriceFactors = [1];
  static dropDownLowerBounds = [1, 10, 100];
  static dropDownUnits = ["C62", "BO", "CG", "CT", "BX", "LTR", "PR"];
  static dropDownZero = ["0"];
  static dropDownOne = ["1"];
  static dropDownInternationalPidType = ["gtin", "ean"];

  static referenceTypes = ["accessories", "sparepart", "similar", "followup", "mandatory", "select", "diff_orderunit", "consists_of", "base_product", "others"];

  static idType: string[] = ["buyer_specific", "supplier_specific"];

  static legalWeeeDeviceTypes = [
    'Wärmeüberträger',
    'Bildschirme, Monitore und Geräte, die Bildschirme mit einer Oberfläche von mehr als 100cm2 enthalten',
    'Lampen',
    'Großgeräte',
    'Haushaltsgeräte',
    'Kleingeräte',
    'Geräte der Unterhaltungselektronik',
    'Leuchten',
    'Ton- oder Bildwiedergabegeräte',
    'Musikausrüstung',
    'elektrische und elektronische Werkzeuge',
    'Spielzeug sowie Sport- und Freizeitgeräte',
    'medizinische Geräte',
    'Überwachungs- und Kontrollinstrumente',
    'Ausgabeautomaten',
    'Geräte zur Erzeugung elektrischer Ströme',
    'Kleine IT- und Telekommunikationsgeräte',
  ]

  static getFlag(language) {
    const key = ViewService.languages.findIndex((languageTemplate => languageTemplate.code == language) as any);

    if (key != -1)
      return this.languages[key].flag;

    return "de.gif";
  }

  static languages: LanguageFlag[] = [
    new LanguageFlag("deu", "Deutsch", "de.gif"),
    new LanguageFlag("eng", "Englisch", "gb.gif"),
    new LanguageFlag("fra", "Französisch", "fr.gif"),
    new LanguageFlag("spa", "Spanisch", "es.gif"),
    //new LanguageFlag("ces", "Tschechisch", "cz.gif"),   // DeepL kann aktuell (12.01.2021) kein Tschechisch...
    new LanguageFlag("nld", "Niederländisch", "nl.png"),
    new LanguageFlag("ita", "Italienisch", "ita.png"),
    new LanguageFlag("pol", "Polnisch", "pl.gif"),
    new LanguageFlag("rus", "Russisch", "ru.gif")
  ];

  static frontendLanguages: LanguageFlag[] = [
    new LanguageFlag("de", "Deutsch", "de.gif"),
    new LanguageFlag("en", "Englisch", "gb.gif"),
    new LanguageFlag("nl", "Niederländisch", "nl.png"),
    new LanguageFlag("pl", "Polnisch", "pl.gif")
  ];
}

