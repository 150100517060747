import { Component, OnInit } from "@angular/core";
import { ModelService } from '../../Services/model.service';
import { FtpServer } from "../../Model/FtpServer";
import { Catalog } from "../../Model/Catalog/Catalog";
import CustomStore from "devextreme/data/custom_store";
import { TemplateService } from "../../Services/template.service";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { Template } from "../../Model/Catalog/Template";
import { lastValueFrom } from "rxjs";
import { Message } from "../../Model/System/Message";
import { FtpImportJobService } from "../../Services/ftp/ftpImportJob.service";
import { FtpServerService } from "../../Services/ftp/ftpServer.service";
import { FtpImportJobSettings } from "../../Model/recurringJobs/ftp/ftpImportJobSettings";
import { RowInsertedEvent, RowUpdatedEvent, RowRemovedEvent } from "devextreme/ui/data_grid";
import { FtpExportJobSettings } from "../../Model/recurringJobs/ftp/ftpExportJobSettings";

@Component({
  selector: "ftpImport",
  templateUrl: "ftpImport.component.html"
})
export class FtpImportComponent implements OnInit {

  dataSource: FtpImportJobSettings[] = [];
  catalogs: Catalog[] = [];
  ftpServers: FtpServer[] = [];
  excelTemplates: Template[] = [];

  constructor(public modelService: ModelService, public ftpImportJobService: FtpImportJobService,
    public ftpServerService: FtpServerService, public templateService: TemplateService, public catalogService: CatalogService) {
    this.onFtpServerChanged = this.onFtpServerChanged.bind(this);

    this.ftpServerService.getAllServer().subscribe((ftpServer: FtpServer[]) => {
      this.ftpServers = ftpServer;
    });
    this.templateService.getTemplatesReturn().subscribe((templates: Template[]) => {
      this.excelTemplates = templates;
    });
    this.catalogService.getCatalogs(this.modelService.loginService.currentCustomer.id).subscribe((catalogs: Catalog[]) => {
      this.catalogs = catalogs;
    });

    ftpServerService.onServerChanged.subscribe(() => this.onFtpServerChanged());
    this.run = this.run.bind(this);
  }

  async ngOnInit(): Promise<void> {
    this.dataSource = await this.ftpImportJobService.getAllFtpImportJobs().toPromise()
      .then((data: FtpImportJobSettings[]) => {
        data.forEach((f: FtpImportJobSettings) => {
          f.time = new Date(f.time);
        });
        return data;
      });
  }


  async onRowInserted(event: RowInsertedEvent<FtpExportJobSettings>) {
    await lastValueFrom(this.ftpImportJobService.create(event.data));
  }

  async onRowUpdated(event: RowUpdatedEvent<FtpExportJobSettings>) {
    await lastValueFrom(this.ftpImportJobService.update(event.data));
  }

  async onRowRemoved(event: RowRemovedEvent<FtpExportJobSettings>) {
    await lastValueFrom(this.ftpImportJobService.delete(event.data.id));
  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "MANAGER" || this.modelService.loginService.currentUser.currentClaim == "ADMIN")
      return true;
    return false;
  }

  getTime(rowData): string {
    if (rowData.time instanceof String)
      rowData.time = new Date(rowData.time);

    return rowData.time;
  }

  getLastRun(rowData): string {
    if (rowData.lastRun instanceof String)
      rowData.lastRun = new Date(rowData.lastRun);

    return rowData.lastRun;
  }

  onFtpServerChanged() {
    this.ftpServerService.getAllServer().subscribe((ftpServer: FtpServer[]) => {
      this.ftpServers = ftpServer;
    });
  }

  run(d) {
    this.ftpImportJobService.run(d.data.id).subscribe(() => {
      this.modelService.systemService.notify(new Message("Job wurde manuell gestartet, er entfernt sich automatisch, sollte er keine Datei importieren."));
    }, error => {
      let msg = new Message();
      msg.type = "Error";
      msg.message = "Fehler beim manuellen starten";
      this.modelService.systemService.notify(msg);
    });
  }
}
