<dx-drop-down-box
  [dataSource]="dataSource"
  [(value)]="value"
  valueExpr="value"
  displayExpr="displayValue"
  searchExpr="displayValue"
  placeholder=""
  fieldTemplate="field"
  [readOnly]="readOnly"
  [showClearButton]="!readOnly && !templateView"
  [showDropDownButton]="!readOnly"
  [acceptCustomValue]="true"
  [focusStateEnabled]="false"
  [dropDownOptions]="{
    wrapperAttr: { class: 'np-dropdown' }
  }"
  [(opened)]="opened"
  (onValueChanged)="valueChanged($event)"
  (onCustomItemCreating)="customItemCreating($event)"
>
  <div *dxTemplate="let data of 'field'">
    <div class="custom-item">
      <dx-number-box [(value)]="value" [readOnly]="readOnly" (onValueChanged)="valueChanged($event)">
        <dx-validator
          *ngIf="!templateView"
          [validationGroup]="validationGroup"
          (onInitialized)="validationService.startAutomaticValidation($event)"
        >
          <dxi-validation-rule type="async" [validationCallback]="validationCallback"></dxi-validation-rule>
        </dx-validator>
      </dx-number-box>
    </div>
  </div>
  <div *dxTemplate="let item of 'content'">
    <dx-list
      [dataSource]="dataSource"
      displayExpr="displayValue"
      valueExpr="value"
      [selectionMode]="selectionMode"
      (onItemClick)="itemClick($event)"
    ></dx-list>
  </div>
</dx-drop-down-box>
