import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { TranslateService } from '@ngx-translate/core';
import { NameValue } from "../../../Model/Dto/NameValue";
import { FieldNames } from "../../../Model/FieldNames";
import { ProductSearchOptions } from "../../../Model/User/ProductSearchOptions";
import { SearchFields } from "../../../Model/User/SearchFields";
import { ModelService } from "../../../Services/model.service";
import { UserManagementService } from "../../../Services/userManagment.service";


@Component({
  selector: "productSearchOptions",
  templateUrl: "productSearchOptions.component.html",
  styleUrls: []
})
export class ProductSearchOptionsComponent implements OnInit {


  @Input() isTarget: boolean = false;
  public searchModes: NameValue[];
  public searchFields: NameValue[];

  @Input()
  public selectedSearchFields: string[];

  @Output()
  onValueChanged = new EventEmitter<{ mode: 'Equal' | 'StartsWith' | 'Contains' |'Placeholder'; fields: string[] }>();

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) { }

  ngOnInit(): void {
    this.reset();

  }

  reset() {
    let fieldNames = new FieldNames();
    this.searchModes = new Array<NameValue>();
    let nameValue = new NameValue();
    nameValue.name = this.translate.instant("gleich");
    nameValue.value = "Equal";
    this.searchModes.push(nameValue);

    nameValue = new NameValue();
    nameValue.name = this.translate.instant("beginnt mit");
    nameValue.value = "StartsWith";
    this.searchModes.push(nameValue);

    nameValue = new NameValue();
    nameValue.name = this.translate.instant("enthält");
    nameValue.value = "Contains";
    this.searchModes.push(nameValue);
    this.searchModes.push(new NameValue(this.translate.instant("Placeholder"), "Placeholder"))


    this.searchFields = new Array<NameValue>();
    nameValue = new NameValue();
    nameValue.name = this.translate.instant(fieldNames.supplierPid);
    nameValue.value = fieldNames.supplierPid;
    this.searchFields.push(nameValue);

    nameValue = new NameValue();
    nameValue.name = this.translate.instant(fieldNames.supplierAltPid);
    nameValue.value = fieldNames.supplierAltPid;
    this.searchFields.push(nameValue);

    nameValue = new NameValue();
    nameValue.name = this.translate.instant(fieldNames.internatonalPid);
    nameValue.value = fieldNames.internatonalPid;
    this.searchFields.push(nameValue);

    nameValue = new NameValue();
    nameValue.name = this.translate.instant(fieldNames.descriptionShort);
    nameValue.value = fieldNames.descriptionShort;
    this.searchFields.push(nameValue);

    nameValue = new NameValue();
    nameValue.name = this.translate.instant(fieldNames.manufacturerPID);
    nameValue.value = fieldNames.manufacturerPID;
    this.searchFields.push(nameValue);


    if (this.modelService.loginService.hasAddonSUPPLIERS) {
      nameValue = new NameValue();
      nameValue.name = this.translate.instant(fieldNames.pid);
      nameValue.value = fieldNames.pid;
      this.searchFields.push(nameValue);

      nameValue = new NameValue();
      nameValue.name = this.translate.instant(fieldNames.supplierInternationalId);
      nameValue.value = fieldNames.supplierInternationalId;
      this.searchFields.push(nameValue);
    }
    if (!this.model) {
      this.model = new ProductSearchOptions();
      this.model.searchMode = "Contains";
      this.model.searchStateMode = 'or';
      this.model.searchFields = [
        new SearchFields( fieldNames.supplierPid ),
        new SearchFields( fieldNames.supplierAltPid ),
        new SearchFields( fieldNames.internatonalPid ),
        new SearchFields( fieldNames.descriptionShort ),
      ];
    }
    this.selectedSearchFields = new Array<string>();
    let that = this;
    this.model.searchFields.forEach(function (field) {
      that.selectedSearchFields.push(field.searchField);
    });




  }

  get model(): ProductSearchOptions {
    if (this.isTarget) {
      return this.modelService.loginService.currentUser.productSearchOptionsTarget;
    }
    return this.modelService.loginService.currentUser.productSearchOptions;
  }
  set model(value: ProductSearchOptions) {
    if (this.isTarget) {
      this.modelService.loginService.currentUser.productSearchOptionsTarget = value;
    }
    else {
      this.modelService.loginService.currentUser.productSearchOptions = value;
    }
  }

  onSearchFieldsSelectionChanged() {
    if (!this.isTarget) {
      this.modelService.loginService.updateSearchFields(this.selectedSearchFields, this.model.searchMode, this.modelService.loginService.currentUser.id, false).subscribe(options => {
        this.model = options;
        this.modelService.catalogService.reloadTree();
        this.onValueChanged.emit({ mode: this.model.searchMode as any, fields: this.selectedSearchFields });
      });
    } else {
      this.modelService.loginService.updateSearchFields(this.selectedSearchFields, this.model.searchMode, this.modelService.loginService.currentUser.id, true).subscribe(options => {
        this.model = options;
        this.modelService.catalogService.reloadTreeTarget();
        this.onValueChanged.emit({ mode: this.model.searchMode as any, fields: this.selectedSearchFields });
      });
    }
  }


  onSearchModeSelectionChanged() {

    if (!this.isTarget) {
      this.model.searchMode = this.model.searchMode;
      this.modelService.loginService.updateSearchFields(this.selectedSearchFields, this.model.searchMode, this.modelService.loginService.currentUser.id, false).subscribe(options => {
        this.model = options;
        this.modelService.catalogService.reloadTree();
        this.onValueChanged.emit({ mode: this.model.searchMode as any, fields: this.selectedSearchFields });
      });
    } else {
      this.model.searchMode = this.model.searchMode;
      this.modelService.loginService.updateSearchFields(this.selectedSearchFields, this.model.searchMode, this.modelService.loginService.currentUser.id, true).subscribe(options => {
        this.model = options;
        this.modelService.catalogService.reloadTreeTarget();
        this.onValueChanged.emit({ mode: this.model.searchMode as any, fields: this.selectedSearchFields });
      });
    }
  }

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    this.modelService.catalogService.updateCatalog(e);
  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
