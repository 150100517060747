import { Component, OnInit, ViewChild } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { TestResult } from '../../Model/Dto/TestResult';
import { StartShopWareExport } from '../../Model/Dto/StartShopWareExport';
import { Message } from "../../Model/System/Message";
import { ShopWare6Connection } from '../../Model/Catalog/ShopWareConnection';
import { ShopwareSalesChannel } from '../../Model/Dto/ShopwareSalesChannel';
import { ShopwareCmsPage } from '../../Model/Dto/ShopwareCmsPage';
import { TreeItem as TreeElement } from "../../Model/Dto/TreeItem";
import { DxTreeViewComponent } from 'devextreme-angular';
import { ShopwareLanguage } from "../../Model/Dto/ShopwareLanguage";
import { UserManagementService } from "../../Services/userManagment.service";
import { VariantRepresenations } from "../../Model/Dto/VariantRepresentations";
import ValidationEngine from "devextreme/ui/validation_engine";
import { Feature } from "../../Model/Catalog/FeatureModels/Feature";
import { lastValueFrom } from "rxjs";
import { ValidFeatureService } from "../../Services/validFeature.service";

@Component({
  selector: "catalogShopware6",
  templateUrl: "catalogShopware6.component.html",
  styleUrls: []
})
export class CatalogShopware6Component implements OnInit {
  @ViewChild(DxTreeViewComponent) treeView;
  salesChannel: ShopwareSalesChannel[];
  languages: ShopwareLanguage[];
  shopwareCmsPage: ShopwareCmsPage[];
  shopCategories: TreeElement[];
  variantRepresentations: object[];
  scheduledFor: Date;
  includeHazmatClassesInDescription: boolean = false;
  featureSystemNames: string[] = [];

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService,
    private validFeatureService: ValidFeatureService) {

    this.testShopConnection = this.testShopConnection.bind(this);
    this.sendData2Shop = this.sendData2Shop.bind(this);
    this.variantRepresentations = Object.entries(VariantRepresenations).map(([key, value]) => ({ key, value: translate.instant(value) }));
  }

  async ngOnInit() {
    this.reset();
    var featureSystems = await lastValueFrom(this.validFeatureService.getCatalogSystems(
      this.modelService.catalogService.selectedCatalogId,
      this.modelService.loginService.currentUser.customerId));
    this.featureSystemNames = featureSystems.map(x => x.name);
  }

  reset() {
    if (this.modelService.loginService.hasAddonShopware6 && this.modelService.catalogService.catalog != null) {
      this.testShopConnection();
    }
  }


  syncTreeViewSelection() {
    if (!this.treeView) return;

    if (!this.model.rootCatCategoryId) {
      this.treeView.instance.unselectAll();
    } else {
      this.treeView.instance.selectItem(this.model.rootCatCategoryId);
    }
  }

  treeView_itemSelectionChanged(e) {
    this.model.rootCatCategoryId = e.itemData.id;
    this.modelService.catalogService.updateCatalog();
  }

  get model(): ShopWare6Connection {
    return this.modelService.catalogService.catalog.shopWareConnection;
  }

  get lastSend(): string {
    if (this.model.lastSend)
      return this.model.lastSend.toLocaleString("de-DE");
    return "";
  }

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    this.modelService.catalogService.updateCatalog(e);
  }

  //SHOP

  catalogChangedinclShop(event) {
    this.modelService.catalogService.resetConnection();
    this.catalogChanged(event);
  }

  testShopConnection() {
    if (this.model.baseUri)
      this.modelService.catalogService.testShopWare6Connection();

    this.salesChannel = new Array<ShopwareSalesChannel>();
    this.shopwareCmsPage = new Array<ShopwareCmsPage>();
    this.shopCategories = new Array<TreeElement>();

    if (this.modelService.catalogService.catalog.shopWareConnection.baseUri) {

      this.modelService.catalogService.GetShopwareSalesChannel(
        this.modelService.catalogService.catalog.shopWareConnection.baseUri,
        this.modelService.catalogService.catalog.shopWareConnection.clientId,
        this.modelService.catalogService.catalog.shopWareConnection.clientSecret).subscribe(
          (salesChannel: ShopwareSalesChannel[]) => {
            this.salesChannel = salesChannel;
          });

      this.modelService.catalogService.GetShopwareLanguages(
        this.modelService.catalogService.catalog.shopWareConnection.baseUri,
        this.modelService.catalogService.catalog.shopWareConnection.clientId,
        this.modelService.catalogService.catalog.shopWareConnection.clientSecret).subscribe(
          (languages: ShopwareLanguage[]) => {
            this.languages = languages;
          });

      this.modelService.catalogService.GetShopwareCmsPage(
        this.modelService.catalogService.catalog.shopWareConnection.baseUri,
        this.modelService.catalogService.catalog.shopWareConnection.clientId,
        this.modelService.catalogService.catalog.shopWareConnection.clientSecret).subscribe(
          (cmsPages: ShopwareCmsPage[]) => {
            this.shopwareCmsPage = cmsPages;
            ValidationEngine.validateGroup("shopware6LayoutValidationGroup");
          });

      this.modelService.catalogService.GetShopwareCategories(
        this.modelService.catalogService.catalog.shopWareConnection.baseUri,
        this.modelService.catalogService.catalog.shopWareConnection.clientId,
        this.modelService.catalogService.catalog.shopWareConnection.clientSecret).subscribe(
          (categories: TreeElement[]) => {
            if (categories.length == 0) {
              this.modelService.systemService.notify(new Message(this.translate.instant("NoCategoriesFoundCheckMaxLimit")));
            }
            this.shopCategories = categories;
          });
    }


  }

  sendPrices: boolean = true;
  isSendCatalogPopupVisible = false;

  sendData2Shop() {
    let startShopWareExport = new StartShopWareExport();
    startShopWareExport.catalogId = this.modelService.catalogService.selectedCatalogId;
    startShopWareExport.customerId = this.modelService.loginService.currentUser.customerId;
    startShopWareExport.sendPrices = this.sendPrices;
    startShopWareExport.scheduledFor = this.scheduledFor;
    startShopWareExport.isVersion6 = true;
   
    this.modelService.shopService.startExport(startShopWareExport).subscribe(success => {
      if (success) {
        if (this.scheduledFor) {
          this.modelService.systemService.notify(new Message(this.translate.instant("Übertragung wird ausgeführt am", {
            date: this.scheduledFor.toLocaleString()
          })));
        } else {
          this.modelService.systemService.notify(new Message(this.translate.instant("Die Übertragung wurde gestartet")));
        }
      }
    });
  }

  isDeleteShopPopupVisible = false;

  deleteShop(num = 0) {
    switch (num) {
      case 0:
        this.isDeleteShopPopupVisible = true;
        break;
      case 1:
        this.isDeleteShopPopupVisible = false;
        this.modelService.shopService.deleteShop(this.modelService.catalogService.catalog.shopUrl, this.modelService.catalogService.catalog.shopAuth, "pro").subscribe(
          (result: TestResult) => this.deleteShopResult(result)
        );
        break;
      case 2:
        this.isDeleteShopPopupVisible = false;
        this.modelService.shopService.deleteShop(this.modelService.catalogService.catalog.shopUrl, this.modelService.catalogService.catalog.shopAuth, "cat").subscribe(
          (result: TestResult) => this.deleteShopResult(result)
        );
        break;
      case 3:
        this.isDeleteShopPopupVisible = false;
        this.modelService.shopService.deleteShop(this.modelService.catalogService.catalog.shopUrl, this.modelService.catalogService.catalog.shopAuth, "mime").subscribe(
          (result: TestResult) => this.deleteShopResult(result)
        );
        break;
      default:
        this.isDeleteShopPopupVisible = false;
        break;
    }
  }

  public deleteShopResult(result: TestResult) {
    if (result.success) {
      this.translate.get("Daten erfolgreich gelöscht").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text));
      });
    } else {
      this.translate.get("Daten nicht gelöscht").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text, "error"));
      });
    }

  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
