import { Component, EventEmitter, OnInit } from '@angular/core';
import { CatalogDataQuality } from '../../Model/validation/CatalogDataQuality';
import { ModelService } from '../../Services/model.service';
import { Message } from '../../Model/System/Message';
import { StartAction } from '../../Model/Dto/StartAction';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '../../Services/userManagment.service';
import { ValidationMethod } from '../../Model/validation/ValidationMethod';
import { ValidationService } from '../../Services/Validation/validation.service';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { CatalogDataQualityService } from '../../Services/Validation/catalogDataQuality.service';
import { ExportConfigurationService } from 'app/Services/exportConfiguration.service';
import { ValidationGroup } from 'app/Model/validation/ValidationGroup';
import { ExportFormatWithCustomers } from '../../Model/exportParameter/ExportFormatWithCustomers';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'dataQuality',
  templateUrl: 'dataQuality.component.html',
  styleUrls: []
})
export class DataQualityComponent implements OnInit {
  pages = [];
  chartText: string = '';
  informationenText: string = '';
  exampleText: string = '';
  groupsText: string = '';
  statisticText: string = '';

  model: CatalogDataQuality;
  validationMethods: ValidationMethod[];
  selectedValidationMethod: ValidationMethod;
  minimumValidationGroup: ValidationGroup;
  modelChangedEvent: EventEmitter<CatalogDataQuality> = new EventEmitter<CatalogDataQuality>();

  constructor(
    public modelService: ModelService,
    public translate: TranslateService,
    public catalogService: CatalogService,
    public userManagement: UserManagementService,
    public validationService: ValidationService,
    private catalogDataQualityService: CatalogDataQualityService,
    private exportConfigurationService: ExportConfigurationService
  ) {
    this.calculateQuality = this.calculateQuality.bind(this);
    this.createReport = this.createReport.bind(this);
    this.reload = this.reload.bind(this);
  }

  async ngOnInit() {
    this.chartText = this.translate.instant('Qualität');
    this.groupsText = this.translate.instant('Gruppen');
    this.statisticText = this.translate.instant('Statistik');
    this.informationenText = this.translate.instant('Informationen');
    this.exampleText = this.translate.instant('Beispiele');

    this.pages = [
      { id: 1, title: this.chartText, template: 'chartTemplate' },
      { id: 4, title: this.groupsText, template: 'groupsTemplate' },
      { id: 5, title: this.statisticText, template: 'statisticTemplate' }
    ];

    let validationMethods = await lastValueFrom(this.validationService.getValidationMethodsForCatalog())
    this.validationMethods = validationMethods;
    this.selectedValidationMethod = this.validationMethods.find(
      (x) => x.id == this.modelService.catalogService.catalog.validationMethodId
    );
    let exportFormatWithCustomers = await this.exportConfigurationService.getExportFormatForSendToCustomers(this.modelService.loginService.currentCustomer.id);

    let customer;
    if (exportFormatWithCustomers) {
      [customer] = exportFormatWithCustomers.value;
    }

    if (this.selectedValidationMethod == undefined) {
      this.selectedValidationMethod = this.validationMethods.find((x) => x.isDefault && !x.isGlobal);
      if (this.selectedValidationMethod == undefined) {
        this.selectedValidationMethod = this.validationMethods.find(
          (x) => x.isShared && x.createdByCustomerId != this.modelService.loginService.currentCustomer.id
        );
        if (this.selectedValidationMethod == undefined) {
          this.selectedValidationMethod = this.validationMethods.find((x) => x.isDefault);
        }
      }
    }
    if (customer) {
      if (this.selectedValidationMethod?.groups.length > 0) {
        this.minimumValidationGroup = this.selectedValidationMethod.groups.find((x) => x.id === customer.minimumValidationGroup);
      }
    };
    this.reload();
  }

  calculateQuality() {
    let param = new StartAction();
    param.customerId = this.modelService.loginService.currentUser.customerId;
    param.catalogId = this.modelService.catalogService.selectedCatalogId;

    this.catalogDataQualityService.CalculateCatalogDataQuality(param).subscribe(() => {
      this.modelService.systemService.notify(new Message(this.translate.instant('Berechnung wurde als Job gestartet')));
    });
  }

  createReport() {
    let param = new StartAction();
    param.customerId = this.modelService.loginService.currentUser.customerId;
    param.catalogId = this.modelService.catalogService.selectedCatalogId;

    this.catalogDataQualityService.CreateCatalogDataQualityReport(param).subscribe(() => {
      this.modelService.systemService.notify(new Message(this.translate.instant('Berechnung wurde als Job gestartet')));
    });
  }

  get isQualityCalculated(): boolean {
    return this.model && this.model.catalogDataQualityTable.length > 0;
  }

  async reload() {
    var catalogDataQuality = await this.catalogDataQualityService.getCatalogDataQuality(
      this.modelService.loginService.currentUser.customerId,
      this.modelService.catalogService.selectedCatalogId
    );
    catalogDataQuality.catalog = this.modelService.catalogService.catalog.catalogName;
    this.model = catalogDataQuality;
    this.modelChangedEvent.emit(catalogDataQuality);
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }

  validationMethodChanged() {
    if (this.catalogService.catalog.validationMethodId != this.selectedValidationMethod.id) {
      this.catalogService.catalog.validationMethodId = this.selectedValidationMethod.id;
      this.catalogService.updateCatalog().finally(() => this.reload());
    }
  }
}
