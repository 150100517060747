import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { LoginService } from 'app/Services/login.service';
import { Router } from '@angular/router';
import { HazmatClassService } from 'app/Services/hazmatClass.service';

@Component({
  selector: 'hazmatclass',
  templateUrl: 'hazmatclass.component.html'
})
export class HazmatclassComponent implements OnInit {
  pages: { id: number; title: string; template: string }[];

  private updateSubject = new Subject<void>();
  private isUpdating = false;

  constructor(
    public loginService: LoginService,
    public hazmatClassService: HazmatClassService,
    public router: Router,
    public translate: TranslateService
  ) {}

  pText = '';
  hText = '';
  piktogrammeText = '';

  ngOnInit(): void {
    if (this.loginService.currentUser == null) {
      this.router.navigate(['/']);
      return;
    }

    this.piktogrammeText = this.translate.instant('GHS-Piktogramme');
    this.hText = this.translate.instant('H-Sätze');
    this.pText = this.translate.instant('P-Sätze');

    this.pages = [
      { id: 1, title: this.piktogrammeText, template: 'piktoTemplate' },
      { id: 2, title: this.hText, template: 'hTemplate' },
      { id: 3, title: this.pText, template: 'pTemplate' }
    ];

    this.updateSubject
      .pipe(
        debounceTime(100),
        switchMap(() => {
          if (this.isUpdating) {
            return of(this.hazmatClassService.selectedClass);
          }
          this.isUpdating = true;
          return this.hazmatClassService.updateClass(this.hazmatClassService.selectedClass);
        })
      )
      .subscribe({
        next: () => {
          console.info('DONE');
          this.isUpdating = false;
        },
        error: (error) => {
          console.error('Fehler beim Update:', error);
          this.isUpdating = false;
        }
      });
  }

  update(e): void {
    // Update-Event anstoßen
    this.updateSubject.next();
  }
}
