import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";
import { CatalogDataQuality } from "../../Model/validation/CatalogDataQuality";
import { StartAction } from "../../Model/Dto/StartAction";

@Injectable()
export class CatalogDataQualityService {

  constructor(private http: HttpClient) {  }

  getCatalogDataQuality(customerId: string, catalogId: string): Promise<CatalogDataQuality> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
        .append("customerId", customerId)
        .append("catalogId", catalogId)

    }
    return lastValueFrom(this.http.get<CatalogDataQuality>("api/CatalogDataQuality/GetCatalogDataQuality", options));
  }

  getCatalogDataQualityUpToDateInfo(customerId: string, catalogId: string): Promise<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
        .append("customerId", customerId)
        .append("catalogId", catalogId)

    }
    return lastValueFrom(this.http.get<boolean>("api/CatalogDataQuality/GetCatalogDataQualityUpToDateInfo", options));
  }
  
  getCatalogDataQualityRequiredGroupMismatchCount(customerId: string, catalogId: string): Promise<number> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
        .append("customerId", customerId)
        .append("catalogId", catalogId)

    }
    return lastValueFrom(this.http.get<number>("api/CatalogDataQuality/GetCatalogDataQualityRequiredGroupMismatchCount", options));
  }

  CalculateCatalogDataQuality(startAction: StartAction) {
    return this.http.post("api/CatalogDataQuality/CalculateCatalogDataQuality", startAction);
  }

  CreateCatalogDataQualityReport(startAction: StartAction) {
    return this.http.post("api/CatalogDataQuality/CreateCatalogDataQualityReport", startAction);
  }
}
