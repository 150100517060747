<dx-tab-panel
  [selectedIndex]="0"
  [swipeEnabled]="false"
  [animationEnabled]="true"
  [showNavButtons]="true"
  [loop]="true"
  (onSelectionChanged)="onTitleClick($event)"
>
  <dxi-item title="{{ 'Produkt' | translate }}">
    <div *dxTemplate>
      <infos
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        [massDataChangeMode]="massDataChangeMode"
        [(hasAddonEDENUMBERS)]="loginService.hasAddonEDENUMBERS"
        [(hasAddonPRODUCT_DETAILS)]="loginService.hasAddonPRODUCT_DETAILS"
        [showHTMLSwitch]="false"
        (onValueChanged)="update($event)"
        [(validationService)]="validationService"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
      ></infos>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Medien' | translate }}" [disabled]="massDataChangeMode">
    <div *dxTemplate>
      <mimes
        [(model)]="model.mimes"
        [(product)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        [(exportMode)]="exportMode"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        (elementDeleted)="elementDelete($event)"
      ></mimes>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Features' | translate }}">
    <div *dxTemplate>
      <featuresystems
        [(featureSystems)]="model.featureSystems"
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        [displayMode]="exportMode ? 'export' : 'import'"
        [(hasAddonECOMMERCE)]="loginService.hasAddonECOMMERCE"
        (addEcommerceFeatures)="addEcommerceFeatures($event)"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        (elementDeleted)="elementDelete($event)"
      ></featuresystems>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Bestelldetails' | translate }}">
    <div *dxTemplate>
      <orderDetail
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        [(hasAddonNordwest)]="loginService.hasAddonNordwest"
        [(hasAddonLEGALITY)]="loginService.hasAddonLEGALITY"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
      ></orderDetail>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Preislisten' | translate }}">
    <div *dxTemplate>
      <prices
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        [(hasAddonNordwest)]="loginService.hasAddonNordwest"
        [(hasAddonLEGALITY)]="loginService.hasAddonLEGALITY"
        [(priceLists)]="catalogService.catalog.priceLists"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        (elementDeleted)="elementDelete($event)"
      ></prices>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Logistik' | translate }}">
    <div *dxTemplate>
      <logistic
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
      ></logistic>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Referenzen' | translate }}">
    <div *dxTemplate>
      <np-product-references
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(lastImageUpload)]="catalogService.catalog.lastImageUpload"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        (elementDeleted)="elementDelete($event)"
      ></np-product-references>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Udx' | translate }}" *ngIf="loginService.hasAddonUDX">
    <div *dxTemplate>
      <udx
        [(model)]="model.udxs"
        [(product)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        (elementDeleted)="elementDelete($event)"
      ></udx>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Kategorien' | translate }}">
    <div *dxTemplate>
      <app-template-product-categories
        [(model)]="model"
        [(product)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        [(exportMode)]="exportMode"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        (elementDeleted)="elementDelete($event)"
      ></app-template-product-categories>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'ElectronicSales' | translate }}" *ngIf="loginService.hasAddonElectronicSales">
    <div *dxTemplate>
      <electronicSales
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
      ></electronicSales>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'SEO - SEA' | translate }}" *ngIf="loginService.hasAddonSEO">
    <div *dxTemplate>
      <seo
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
      ></seo>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Wawi' | translate }}" *ngIf="loginService.hasAddonWawiErp">
    <div *dxTemplate>
      <wawi
        [(model)]="model"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
      ></wawi>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Recht' | translate }}" >
    <div *dxTemplate>
      <productLegal
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(warrantyClasses)]="warrantyClassService.warrantyClasses"
        [(hazmatClasses)]="hazmatClassService.hazmatClasses"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        [massDataChangeMode]="massDataChangeMode"
        [showHTMLSwitch]="false"
        (onValueChanged)="update($event)"
        [(validationService)]="validationService"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
      ></productLegal>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Lieferanten' | translate }}" *ngIf="loginService.hasAddonSUPPLIERS">
    <div *dxTemplate>
      <productSuppliers
        [(model)]="model.suppliers"
        [(product)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [templateView]="true"
        [(exportMode)]="exportMode"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        (elementDeleted)="elementDelete($event)"
      ></productSuppliers>
    </div>
  </dxi-item>

  <dxi-item title="{{ 'Einstellungen' | translate }}" *ngIf="!massDataChangeMode">
    <div *dxTemplate>
      <misc
        [(model)]="model"
        [(showToolTips)]="showToolTips"
        [(selectedElements)]="selectedElements"
        [(exportMode)]="exportMode"
        (onValueChanged)="update($event)"
        (elementSelected)="elementSelectFunction($event)"
        [showDragHandle]="showDragHandle"
        [dragFilter]="'> *'"
        (excelElementDropped)="excelElementDrop($event)"
        [excelModel]="excelModel"
      ></misc>
    </div>
  </dxi-item>
</dx-tab-panel>
