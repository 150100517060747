<fullscreen-page>
  <content-column look="dark" helpSwitch="true">
    <div slot="content" class="p-3 flex-fill w-100 mx-auto overflow-hidden">
      <dx-data-grid id="gridContainer"
                    class="h-100"
                    (onToolbarPreparing)="onGridToolbarPreparing($event)"
                    [dataSource]="model"
                    [remoteOperations]="false"
                    [allowColumnReordering]="true"
                    [rowAlternationEnabled]="true"
                    [allowColumnResizing]="true"
                    noDataText="{{ 'keine Kataloge im Account' | translate }}"
                    [showBorders]="true"
                    (onEditingStart)="onEditClicked($event)"
                    (onRowPrepared)="onRowPrepared($event)">

        <div *dxTemplate="let toolbaritem of 'newCatalogButtonTemplate'">
          <dx-button [visible]="canView('newCatalogButton')" stylingMode="text" type="default" text="{{ 'Neuer Katalog' | translate }}" (onClick)="showAddCatalog()" hint="{{ 'Neuen, leeren Katalog anlegen' | translate }}"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'importButtonTemplate'">
          <dx-button [visible]="canView('importButton')" stylingMode="text" type="default" text="{{ 'Importieren' | translate }}" (onClick)="showImportCatalog()" hint="{{ 'Katalog hochladen (Excel, BMEcat)' | translate }}"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'refreshButtonTemplate'">
          <dx-button [visible]="canView('refreshButton')" stylingMode="text" type="default" text="{{ 'Aktualisieren' | translate }}" (onClick)="refresh()" hint="{{ 'Katalogliste aktualisieren' | translate }}"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'minGroupsButtonTemplate'">
          <dx-button stylingMode="text" (onClick)="onCollapseAll()" type="default" icon="chevronright" hint="{{ 'Alle Gruppen schliessen' | translate }}"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'maxGroupsButtonTemplate'">
          <dx-button stylingMode="text" (onClick)="onExpandAll()" type="default" icon="chevrondown" hint="{{ 'Alle Gruppen öffnen' | translate }}"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'pasteCatalogButtonTemplate'">
          <dx-button stylingMode="text" type="default" *ngIf="copyCatalogId!=''" text="Katalog einfügen" (onClick)="pasteCatalog()" hint="{{ 'Katalog einfügen' | translate }}"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'jobdataButtonTemplate'">
          <dx-button stylingMode="text" type="default" text="{{ 'JobFiles' | translate }}" (click)="showJobData()" *ngIf="modelService.loginService.isAdmin" title="JobData"></dx-button>
        </div>
        <div *dxTemplate="let toolbaritem of 'backupButtonTemplate'">
          <dx-button [visible]="canView('backupButton')" stylingMode="text" type="default" text="{{ 'Backups' | translate }}" (click)="showBackUp()" title="BackUps" hint="{{ 'Vorhandene Backups anzeigen' | translate }}"></dx-button>
        </div>
        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="CatalogListStateNewUIV2" savingTimeout="0"></dxo-state-storing>
        <dxo-editing mode="row" [allowDeleting]="canView('catalogDelete')" [allowUpdating]="canView('catalogUpdate')" [useIcons]="true">
          <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie den Katalog wirklich löschen' | translate }}?" confirmDeleteTitle="{{ 'Katalog löschen' | translate }}"></dxo-texts>
        </dxo-editing>
        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Kataloge suchen' | translate }}"></dxo-search-panel>



        <dxi-column dataField="catalogName" caption="{{ 'Katalog' | translate }}" cellTemplate="catalogNameTemplate"></dxi-column>
        <div *dxTemplate="let catalog of 'catalogNameTemplate'">
          <dx-button stylingMode="text" (onClick)="catalogSelected(catalog.data)" text="{{ catalog.data.catalogName}}" type="default" style="min-width: 32px;"></dx-button>
        </div>

        <dxi-column *ngIf="modelService.loginService.hasAddonDEVELOPMENT" dataField="isVirtual" caption="{{ 'Virtuell' | translate }}" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="supplier.supplierName" caption="{{ 'Lieferant' | translate }}"></dxi-column>

        <dxi-column dataField="responsible" caption="{{ 'Verantwortlicher' | translate }}" style="padding-top: 0px;" [allowEditing]="false" cellTemplate="responsibleTemplate"></dxi-column>
        <div *dxTemplate="let catalog of 'responsibleTemplate'">
          <dx-autocomplete class="dataGridAutocomplete"
                           [minSearchLength]="0"
                           [dataSource]="modelService.catalogService.responsibleList"
                           [showClearButton]="true"
                           [showDropDownButton]="true"
                           valueChangeEvent="blur"
                           (onValueChanged)="updateResponsible(catalog)"
                           [(value)]="catalog.data.responsible"
                           [readOnly]="!canEdit('verantwortlicher')">
          </dx-autocomplete>
        </div>

        <dxi-column dataField="note" caption="{{ 'Notiz' | translate }}" cellTemplate="noteTemplate"></dxi-column>
        <div *dxTemplate="let catalog of 'noteTemplate'">
          <div (mouseenter)="showMemo(catalog)" (mouseleave)="hideMemo()" id="memo{{catalog.rowIndex}}">
            <dx-text-box [readOnly]="!canEdit('catalogNote')" [(value)]="catalog.data.note" [showClearButton]="false" (onValueChanged)="update(catalog)"></dx-text-box>
          </div>
        </div>

        <dxi-column dataField="catalogVersion" caption="{{ 'Version' | translate }}" [width]="80"></dxi-column>

        <dxi-column dataField="thumbUri" caption="" [width]="35" [allowFiltering]="false" [allowSorting]="false" cellTemplate="imageCellTemplate2"></dxi-column>
        <div *dxTemplate="let data of 'imageCellTemplate2'" style="overflow:visible;height:25px;">
          <div (mouseenter)="showImage(data.value,data.rowIndex)" (mouseleave)="hideImage()" style="position:relative;top:-10px;left:-7px;">
            <span *ngIf="!data.value" class="glyphicon glyphicon-picture" style="font-size: 35px;"></span>
            <img *ngIf="data.value" src="{{data.value}}{{mimedate()}}" style="height: 35px;" id="link{{data.rowIndex}}" />
          </div>
        </div>


        <dxi-column dataField="language" caption="" title="{{ 'Sprache' | translate }}" width="40" cellTemplate="catalogLanguageTemplate" *ngIf="modelService.loginService.hasAddonTranslate"></dxi-column>
        <div *dxTemplate="let data of 'catalogLanguageTemplate'">
          <img src="images/flags/{{getLanguageFlag(data.data.language)}}" class="flag" />
        </div>

        <dxi-column dataField="date" caption="{{ 'Datum' | translate }}" dataType="date" [format]="{ type: 'dd.MM.yyyy' }" [width]="105"></dxi-column>

        <dxi-column dataField="categoryCount" caption="{{ 'Kategorien' | translate }}" [width]="100"></dxi-column>
        <dxi-column dataField="productCount" caption="{{ 'Produkte' | translate }}" [width]="100"></dxi-column>

        <dxi-column dataField="pimGroup" caption="{{ 'Gruppe' | translate }}" [groupIndex]="0"></dxi-column>

        <dxi-column dataField="lastUpdate" caption="{{ 'Änderung' | translate }}" dataType="date" [format]="{ type: 'dd.MM.yyyy' }" [width]="105"></dxi-column>

        <dxi-column dataField="isPublic" caption="{{ 'Öffentlich' | translate }}" [width]="100" *ngIf="modelService.loginService.hasAddonSEARCH || modelService.loginService.hasAddonShopWare" cellTemplate="isPublicTemplate"></dxi-column>
        <div *dxTemplate="let catalog of 'isPublicTemplate'">
          <div *ngIf="!catalog.data.isVirtual">
            <dx-switch [readOnly]="!canEdit('isPublic')" (onValueChanged)="update(catalog)" [(value)]="catalog.data.isPublic" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
          </div>
        </div>

        <dxi-column type="buttons">
          <dxi-button text="{{ 'Katalog duplizieren' | translate }}"
                      icon="copy"
                      [onClick]="duplicateCatalog">
          </dxi-button>
          <dxi-button text="{{ 'Katalog kopieren' | translate }}"
                      icon="description"
                      [onClick]="copyCatalog">
          </dxi-button>
          <dxi-button text="{{ 'Wiederherstellungspunkt erstellen' | translate }}"
                      icon="clock"
                      [onClick]="createRestorePoint">
          </dxi-button>
          <dxi-button name="edit"></dxi-button>
          <dxi-button name="delete" [onClick]="showCustomDeleteDialog"></dxi-button>
        </dxi-column>     

      </dx-data-grid>

      <dx-load-panel #loadPanelChecKVirtualCatalogs
                   shadingColor="rgba(0,0,0,0.4)"
                   [position]="{ offset: { x: 0, y: 50 } }"
                   [(visible)]="loading"
                   [showIndicator]="true"
                   [showPane]="true"
                   [shading]="false"
                   [hideOnOutsideClick]="false">
    </dx-load-panel>

      <dx-popover [(target)]="memoTarget"
                  position="bottom"
                  [width]="300"
                  [showTitle]="true"
                  title="Notiz"
                  [(visible)]="memoVisable">
        <div *dxTemplate="let data = model of 'content'">
          <span [innerHTML]="memoText"></span>
        </div>
      </dx-popover>

    </div>
  </content-column>
  <help-column class="{{modelService.systemService.showHelp ? '' : 'd-none'}}"></help-column>
</fullscreen-page>

<dx-popup class="popup"
          [width]="600"
          [height]="400"
          [showTitle]="true"
          title="{{ 'Neuer Katalog' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="addCatalogVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <newCatalog></newCatalog>
  </div>
</dx-popup>

<dx-popup class="popup"
          [width]="800"
          minHeight="550"
          [height]="'auto'"
          [showTitle]="true"
          title="{{ 'Katalog importieren' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="importCatalogVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <importCatalog></importCatalog>
  </div>
</dx-popup>

<dx-popup title="{{ 'Datensicherungen' | translate }}"
          [width]="1000"
          [height]="800"
          [dragEnabled]="false"
          [showCloseButton]="true"
          [hideOnOutsideClick]="true"
          [(visible)]="backUpVisible"
          (onHiding)="hideBackUp()"
          contentTemplate="popupContent">
  <dxi-toolbar-item toolbar="top" location="after" widget="dxDropDownButton">
    <np-help-button>
      <np-help-option text="YouTube" icon="images/YouTube.svg" link="https://www.youtube.com/watch?v=cElY9pFqz2I"></np-help-option>
    </np-help-button>
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">
    <backup></backup>
  </div>
</dx-popup>

<jobdata [(visible)]="jobDataVisible"></jobdata>

<dx-popover [(target)]="target"
            position="bottom"
            [width]="300"
            [showTitle]="false"
            [(visible)]="imageVisable">
  <div *dxTemplate="let data = model of 'content'">
    <img src="{{source}}{{mimedate()}}" style="height: 244px;" />
  </div>
</dx-popover>

<dx-popup class="popup"
          [width]="600"
          [height]="400"
          [showTitle]="true"
          title="{{ 'Willkommen' | translate }}"
          [dragEnabled]="false"
          [showCloseButton]="true"
          [(visible)]="welcomePopupVisible"
          contentTemplate="popupContent">
  <dxi-toolbar-item toolbar="top" location="after" widget="dxDropDownButton">
    <np-help-button>
      <np-help-option text="Wiki" icon="images/Wiki.svg" link="https://knowledge.nextpim.de/?utm_source=app.nextpim.de&utm_medium=affiliate&utm_campaign=wikilink"></np-help-option>
      <np-help-option text="YouTube" icon="images/YouTube.svg" link="https://www.youtube.com/watch?v=gHtxkwiks9k"></np-help-option>
    </np-help-button>
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">

    <div *ngIf="!isGastro()" [innerHTML]="'Welcome-Message' | translate"></div>
    <div *ngIf="isGastro()" [innerHTML]="'Welcome-MessageGastro' | translate"></div>
  </div>
</dx-popup>
