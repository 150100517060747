import { Component, OnInit } from '@angular/core';
import { DataSourcesService } from '../../Services/DataProcess/datasources.service';
import { LoginService } from '../../Services/login.service';
import { ProductDataSource } from '../../Model/dataProcess/productDataSource';
import { RowInsertedEvent, RowRemovedEvent, RowUpdatedEvent, SavedEvent } from 'devextreme/ui/data_grid';
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { ViewService } from 'app/Services/view.service';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-datasources',
  templateUrl: './datasources.component.html',
  styleUrls: ['./datasources.component.css']
})
export class DatasourcesComponent implements OnInit {
  productDataSources: ProductDataSource[] = [];
  changes = [];

  types: string[] = ['Manufacturer', 'Supplier'];
  countryCodes: string[] = ViewService.dropDownTerritories;

  constructor(
    public dataSourcesService: DataSourcesService,
    public loginService: LoginService,
    public translate: TranslateService
  ) {}

  async ngOnInit() {
    this.productDataSources = await this.dataSourcesService.getAll(this.loginService.currentCustomer.id);
  }

  async onRowInserted(event: RowInsertedEvent) {
    console.log('insert');
    let newModel = event.data;
    await this.dataSourcesService.create(newModel, this.loginService.currentCustomer.id);
    this.productDataSources = await this.dataSourcesService.getAll(this.loginService.currentCustomer.id);
  }

  async onRowUpdated(event: RowUpdatedEvent) {
    console.log('updated');
    let newModel = event.data;
    await this.dataSourcesService.update(newModel, this.loginService.currentCustomer.id);
    this.productDataSources = await this.dataSourcesService.getAll(this.loginService.currentCustomer.id);
  }

  async onSaved(event: SavedEvent<ProductDataSource, ProductDataSource>) {
    var updatedDataSources: { oldDataSource: ProductDataSource; newDataSource: ProductDataSource }[] = [];
    for (var x of event.changes) {
      switch (x.type) {
        case 'insert':
          await this.dataSourcesService.create(x.key as ProductDataSource, this.loginService.currentCustomer.id);
          break;
        case 'update':
          let original = await this.dataSourcesService.get(x.key.id, this.loginService.currentCustomer.id);
          let updated = await this.dataSourcesService.update(
            x.key as ProductDataSource,
            this.loginService.currentCustomer.id
          );
          updatedDataSources.push({ oldDataSource: original, newDataSource: updated });
          break;
        case 'remove':
          await this.dataSourcesService.delete(x.key.id, this.loginService.currentCustomer.id);
          break;
      }
    }
    this.productDataSources = await this.dataSourcesService.getAll(this.loginService.currentCustomer.id);
    if (updatedDataSources && updatedDataSources.length > 0) this.confirmStartMakroDialog(updatedDataSources);
  }

  onValueChanged(event: ValueChangedEvent, cellInfo: DxDataGridTypes.ColumnCellTemplateData<ProductDataSource>) {
    cellInfo.component.cellValue(cellInfo.rowIndex, cellInfo.columnIndex, event.value);
  }

  previewUrl: string;

  onFileChanged(event: ValueChangedEvent) {
    const file: File = event.value[0];
    this.previewUrl = URL.createObjectURL(file);
    console.log(this.previewUrl);
  }

  confirmStartMakroDialog(
    updatedDataSources: { oldDataSource: ProductDataSource; newDataSource: ProductDataSource }[]
  ) {
    let saveChangesDialog = custom({
      title: this.translate.instant('Makro Starten'),
      messageHtml: this.translate.instant(
        'Wollen Sie ein Makro zum ändern der Informationen starten ?',
        updatedDataSources.length
      ),
      buttons: [
        {
          text: this.translate.instant('Starten'),
          onClick: (e) => {
            return true;
          }
        },
        {
          text: this.translate.instant('Verwerfen'),
          onClick: (e) => {
            return false;
          }
        }
      ]
    });

    saveChangesDialog.show().then(async (dialogResult) => {
      if (dialogResult) {
        await this.startApplyChnagesMakro(updatedDataSources);
      }
    });
    return false;
  }

  async startApplyChnagesMakro(
    oldToNewDataSources: { oldDataSource: ProductDataSource; newDataSource: ProductDataSource }[]
  ) {
    await this.dataSourcesService.startApplyChangesToAccountMakro(
      { oldToNewDataSources: oldToNewDataSources },
      this.loginService.currentCustomer.id
    );
  }

  async onRowRemoved(event: RowRemovedEvent) {
    console.log('remove');
    let key = event.key;
    await this.dataSourcesService.delete(key, this.loginService.currentCustomer.id);
    this.productDataSources = await this.dataSourcesService.getAll(this.loginService.currentCustomer.id);
  }

  logEvent(eventName) {
    console.log(eventName);
  }
}
