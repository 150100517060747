<div class="np-form-control dx-fieldset">
  <dx-validation-group id="LEGAL">
    <dx-box class="gap-4 mb-4" direction="row" crossAlign="stretch" width="100%">
      <dxi-item [ratio]="1">
        <div class="dx-card mb-4">
          <div class="dx-fieldset d-flex flex-column gap-4">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [suppressPlaceLabel]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Biozidbehandeltes Produkt' | translate }}"
                             field="IS_BIOCIDAL_PRODUCT"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup [(value)]="model.legalCharacteristic.isBiocidalProduct"
                                      [templateView]="templateView"
                                      [readOnly]="readOnlyForLinkedFields('IS_BIOCIDAL_PRODUCT') || readOnly('legalBiocidalProduct')"
                                      [fieldName]="validationService.fieldNames.isBiocidalProduct"
                                      (onValueChanged)="update($event, 'IS_BIOCIDAL_PRODUCT')"
                                      validatorValidationGroup="LEGAL"></np-booleanRadioGroup>
              </div>
            </np-inputControl>

            <hr *ngIf="model?.legalCharacteristic?.isBiocidalProduct" />

            <np-inputControl *ngIf="model?.legalCharacteristic?.isBiocidalProduct"
                             [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.legalCharacteristic?.biocidalFeature?.length > 0"
                             [suppressLinkButton]="false"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Biozide Eigenschaft' | translate }}"
                             field="BIOCIDAL_FEATURE"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.legalCharacteristic.biocidalFeature"
                             [readOnly]="readOnlyForLinkedFields('BIOCIDAL_FEATURE') || readOnly('legalBiocidalProduct')"
                             [showClearButton]="true"
                             (onValueChanged)="update($event, 'BIOCIDAL_FEATURE')">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('BIOCIDAL_FEATURE')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>

            <np-inputControl *ngIf="model?.legalCharacteristic?.isBiocidalProduct"
                             [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.legalCharacteristic?.biocidalIngredient?.length > 0"
                             [suppressLinkButton]="false"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Wirkstoff' | translate }}"
                             field="BIOCIDAL_INGREDIENT"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.legalCharacteristic.biocidalIngredient"
                             [readOnly]="readOnlyForLinkedFields('BIOCIDAL_INGREDIENT') || readOnly('legalBiocidalProduct')"
                             [showClearButton]="true"
                             (onValueChanged)="update($event, 'BIOCIDAL_INGREDIENT')">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('BIOCIDAL_INGREDIENT')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
        <div class="dx-card mb-4">
          <div class="dx-fieldset d-flex flex-column gap-4">
            <np-inputControl [(model)]="model"
                             [direction]="templateView ? 'column' : 'row'"
                             [showLinkButtons]="showLinkButtons"
                             [suppressPlaceLabel]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'WEEE ROHS EAR relevant' | translate }}"
                             field="IS_WEEE_ROHS_EAR"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup [(value)]="model.legalCharacteristic.isWeeeRohsEar"
                                      [templateView]="templateView"
                                      [readOnly]="readOnlyForLinkedFields('IS_WEEE_ROHS_EAR') || readOnly('legalWEEROHSEAR')"
                                      [fieldName]="validationService.fieldNames.isWeeeRohsEar"
                                      (onValueChanged)="update($event, 'IS_WEEE_ROHS_EAR')"
                                      validatorValidationGroup="LEGAL"></np-booleanRadioGroup>
              </div>
            </np-inputControl>

            <hr *ngIf="model?.legalCharacteristic?.isWeeeRohsEar" />

            <np-inputControl *ngIf="model?.legalCharacteristic?.isWeeeRohsEar"
                             [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.legalCharacteristic?.weeeDeviceType?.length > 0"
                             [suppressLinkButton]="false"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Geräteart' | translate }}"
                             field="WEEE_DEVICE_TYPE"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot">
                <np-dropdown-control fieldName="WEEE_DEVICE_TYPE"
                                     [readOnly]="readOnlyForLinkedFields('WEEE_DEVICE_TYPE') || readOnly('weeeDeviceType')"
                                     [templateView]="templateView"
                                     validationGroup="LEGAL"
                                     [validationCallback]="validationService.validateField('WEEE_DEVICE_TYPE')"
                                     [items]="weeeDeviceTypes"
                                     [(model)]="model.legalCharacteristic"
                                     modelProp="weeeDeviceType"
                                     (onValueChanged)="update($event, 'WEEE_DEVICE_TYPE')"
                                     (onCustomItemCreating)="onCustomItemCreating($event, 'weeeDeviceType')"></np-dropdown-control>
              </div>
            </np-inputControl>

            <np-inputControl *ngIf="model?.legalCharacteristic?.isWeeeRohsEar"
                             [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.legalCharacteristic?.weeeNumber?.length > 0"
                             [suppressLinkButton]="false"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'WEEE-Nummer' | translate }}"
                             field="WEEE_NUMBER"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.legalCharacteristic.weeeNumber"
                             [readOnly]="readOnlyForLinkedFields('WEEE_NUMBER') || readOnly('weeeNumber')"
                             [showClearButton]="true"
                             (onValueChanged)="update($event, 'WEEE_NUMBER')">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('WEEE_NUMBER')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      <div class="dx-card mb-4">

        <div class="dx-fieldset d-flex flex-column gap-4">
          <div class="dx-fieldset-header">{{ 'Produktsicherheitsverordnung (GPSR)' | translate }}</div>
          <np-inputControl *ngIf="loginService.hasAddonDataSources"
                           [(model)]="model"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.legalCharacteristic?.manufacturer?.length > 0"
                           [suppressLinkButton]="false"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Hersteller' | translate }}"
                           field="PRODUCT_SAFETY_MANUFACTURER"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">
            <div slot="content" class="contentSlot d-flex gap-4 align-items-center">
              <np-dropdown-control class="flex-grow-1"
                                   fieldName="PRODUCT_SAFETY_MANUFACTURER"
                                   [readOnly]="readOnlyForLinkedFields('PRODUCT_SAFETY_MANUFACTURER') || readOnly('manufacturer')"
                                   [templateView]="templateView"
                                   validationGroup="LEGAL"
                                   [validationCallback]="validationService.validateField('PRODUCT_SAFETY_MANUFACTURER')"
                                   [items]="manufacturerNames"
                                   [(model)]="model.legalCharacteristic"
                                   modelProp="manufacturer"
                                   (onValueChanged)="update($event, 'PRODUCT_SAFETY_MANUFACTURER')"></np-dropdown-control>
              <img *ngIf="productDataSource?.logoFile" src="{{ productDataSource.logoFile }}" [ngStyle]="{ height: '49px', maxWidth: '120px' }" />
            </div>
          </np-inputControl>

          <div class="dx-field" *ngIf="loginService.hasAddonDataSources">
            <div class="dx-field-label"></div>
            <div class="dx-field-value dx-field-value-np d-flex flex-row w-100">
              <dx-button *ngIf="!templateView" stylingMode="contained" (onClick)="fillContactField($event)">
                <i class="dx-icon-plus"></i>
                {{ 'Ansprechpartner vorbefüllen' | translate }}
              </dx-button>
            </div>
          </div>

          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.legalCharacteristic?.contact?.length > 0"
                           [suppressTranslation]="false"
                           [suppressLinkButton]="false"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Ansprechpartner' | translate }}"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)"
                           [isPrint]="isPrint"
                           [showHTMLSwitch]="showHTMLSwitch"
                           [(showHTMLEditor)]="showHTMLEditor"
                           (showHTMLEditorChange)="showHTMLEditorChanged()">
            <div slot="content" class="contentSlot">
              <div *ngIf="showHTMLEditor && !isPrint" class="w-100">
                <dx-html-editor class="w-100"
                                [multiline]="false"
                                [height]="250"
                                [(value)]="model.legalCharacteristic.contact"
                                placeholder="PRODUCT_SAFETY_CONTACT"
                                [readOnly]="readOnlyForLinkedFields('PRODUCT_SAFETY_CONTACT') || readOnly('contact')"
                                (onFocusOut)="update($event, 'PRODUCT_SAFETY_CONTACT')"
                                allowSoftLineBreak="true">
                  <dxo-toolbar>
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="link"></dxi-item>
                  </dxo-toolbar>
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('PRODUCT_SAFETY_CONTACT')"></dxi-validation-rule>
                  </dx-validator>
                </dx-html-editor>
              </div>

              <div *ngIf="!showHTMLEditor || isPrint">
                <label class="placeLabel {{ model?.contact?.length > 0 ? 'placeLabelSmall' : '' }}">CONTACT</label>
                <dx-text-area [(value)]="model.legalCharacteristic.contact"
                              [height]="250"
                              [readOnly]="readOnlyForLinkedFields('PRODUCT_SAFETY_CONTACT') || readOnly('contact')"
                              (onValueChanged)="update($event, 'PRODUCT_SAFETY_CONTACT')">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('PRODUCT_SAFETY_CONTACT')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-area>
              </div>
            </div>

            <div *ngIf="hasTranslation" slot="translation" class="translationSlot">
              <div *ngIf="showHTMLEditor">
                <dx-html-editor [(value)]="productTranslation.contact"
                                placeholder="PRODUCT_SAFETY_CONTACT"
                                (onValueChanged)="translationUpdate(model.legalCharacteristic.contact, $event.value)"
                                [readOnly]="readOnly('contact')">
                  <dxo-toolbar>
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="link"></dxi-item>
                  </dxo-toolbar>
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="custom"
                                         [validationCallback]="validationService.validateField('PRODUCT_SAFETY_CONTACT')"></dxi-validation-rule>
                  </dx-validator>
                </dx-html-editor>
              </div>

              <div *ngIf="!showHTMLEditor">
                <label class="placeLabel {{ productTranslation?.contact?.length > 0 ? 'placeLabelSmall' : '' }}">
                  CONTACT
                </label>
                <dx-text-area [(value)]="productTranslation.contact"
                              [height]="130"
                              (onValueChanged)="translationUpdate(model.legalCharacteristic.contact, $event.value)"
                              [readOnly]="readOnly('contact')">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('PRODUCT_SAFETY_CONTACT')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-area>
              </div>
            </div>
          </np-inputControl>
        </div>
      </div>
        <div class="dx-card">
          <div class="dx-fieldset d-flex flex-column gap-4">
            <np-inputControl [(model)]="model"
                             direction="row"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.productLogistic?.hazardousMaterialClassId?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Gefahrenstoffklasse' | translate }}"
                             field="HAZMAT_ClASS"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot">
                <dx-select-box [items]="hazmatClasses"
                               placeholder=""
                               [showClearButton]="showClearButton()"
                               [(value)]="model.productLogistic.hazardousMaterialClassId"
                               [readOnly]="readOnlyForLinkedFields('HAZMAT_ClASS') || readOnly('legalHazmatClass')"
                               displayExpr="name"
                               [searchEnabled]="true"
                               valueExpr="id"
                               noDataText="{{ 'Keine Gefahrenstoffklassen angelegt.' | translate }}"
                               (onValueChanged)="update($event, 'HAZMAT_ClASS')">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('HAZMAT_ClASS')"></dxi-validation-rule>
                  </dx-validator>
                </dx-select-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </dxi-item>
      <dxi-item [ratio]="1">
        <div class="dx-card">
          <div class="dx-fieldset d-flex flex-column gap-4">
            <np-inputControl [(model)]="model"
                             [direction]="templateView ? 'column' : 'row'"
                             [showLinkButtons]="showLinkButtons"
                             [suppressPlaceLabel]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'CE GS relevantes Produkt' | translate }}"
                             field="IS_CE_GS"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup [(value)]="model.legalCharacteristic.isCeGs"
                                      [templateView]="templateView"
                                      [readOnly]="readOnlyForLinkedFields('IS_CE_GS') || readOnly('legalCeGs')"
                                      [fieldName]="validationService.fieldNames.isCeGs"
                                      (onValueChanged)="update($event, 'IS_CE_GS')"
                                      validatorValidationGroup="LEGAL"></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl [(model)]="model"
                             [direction]="templateView ? 'column' : 'row'"
                             [showLinkButtons]="showLinkButtons"
                             [suppressPlaceLabel]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'REACH relevant' | translate }}"
                             field="IS_REACH"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup [(value)]="model.legalCharacteristic.isReach"
                                      [templateView]="templateView"
                                      [readOnly]="readOnlyForLinkedFields('IS_REACH') || readOnly('legalReach')"
                                      [fieldName]="validationService.fieldNames.isReach"
                                      (onValueChanged)="update($event, 'IS_REACH')"
                                      validatorValidationGroup="LEGAL"></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl [(model)]="model"
                             [direction]="templateView ? 'column' : 'row'"
                             [showLinkButtons]="showLinkButtons"
                             [suppressPlaceLabel]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Sicherheitsdatenblatt erforderlich' | translate }}"
                             field="IS_SECURITY_DATASHEET_NEEDED"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup [(value)]="model.legalCharacteristic.isSecurityDatasheetNeeded"
                                      [templateView]="templateView"
                                      [readOnly]="
                    readOnlyForLinkedFields('IS_SECURITY_DATASHEET_NEEDED') || readOnly('legalSecurityDatasheetNeeded')
                  "
                                      [fieldName]="validationService.fieldNames.isSecurityDatasheetNeeded"
                                      (onValueChanged)="update($event, 'IS_SECURITY_DATASHEET_NEEDED')"
                                      validatorValidationGroup="LEGAL"></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl [(model)]="model"
                             [direction]="templateView ? 'column' : 'row'"
                             [showLinkButtons]="showLinkButtons"
                             [suppressPlaceLabel]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'BattG relevant' | translate }}"
                             field="IS_BATTERIE_V"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot np-others">
                <np-booleanRadioGroup [(value)]="model.legalCharacteristic.isBatterieV"
                                      [templateView]="templateView"
                                      [readOnly]="readOnlyForLinkedFields('IS_BATTERIE_V') || readOnly('legalBatterieV')"
                                      [fieldName]="validationService.fieldNames.isBatterieV"
                                      (onValueChanged)="update($event, 'IS_BATTERIE_V')"
                                      validatorValidationGroup="LEGAL"></np-booleanRadioGroup>
              </div>
            </np-inputControl>
            <np-inputControl [(model)]="model"
                             direction="row"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.productLogistic?.warrantyClassId?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Garantieklasse' | translate }}"
                             field="WARRANTY_ClASS"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">
              <div slot="content" class="contentSlot">
                <dx-select-box [items]="warrantyClasses"
                               placeholder=""
                               [showClearButton]="showClearButton()"
                               displayExpr="name"
                               valueExpr="id"
                               [searchEnabled]="true"
                               [(value)]="model.productLogistic.warrantyClassId"
                               [readOnly]="readOnlyForLinkedFields('WARRANTY_ClASS') || readOnly('legalWarrantyClass')"
                               noDataText="{{ 'Keine Garantieklassen angelegt.' | translate }}"
                               (onValueChanged)="update($event, 'WARRANTY_ClASS')">
                  class="hint">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="LEGAL"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule type="async"
                                         [validationCallback]="validationService.validateField('WARRANTY_ClASS')"></dxi-validation-rule>
                  </dx-validator>
                </dx-select-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </dxi-item>
    </dx-box>
  </dx-validation-group>
</div>
